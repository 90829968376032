import React from 'react'
import {FiDownload} from 'react-icons/fi'

type Onclick = {
    setHistory: React.Dispatch<React.SetStateAction<boolean>>
}
const StopTimeItem = ({setHistory}: Onclick) => {
  return (
    <div className="history__content">
            <div className="stop__time">
                <span className='text-6'>Stops</span>
                <div className="stop__time_desc">
                    <span className='marker__icon'></span>
                    Minutes
                </div>
                <div className="stop__time_desc">
                    <span className='marker__icon'></span>
                    Hours
                </div>
            </div>
            <div className="stop__input">
                <input  type='time' className='stop__input'/>
            </div>
            <div className="stop__time_road">
                    <span className='marker__icon'></span>
                    Snap to Road
            </div>
            <div className="show__ctr">
                <div className="show__download">
                    <FiDownload />
                </div>
                <div className="show__history" onClick={()=>setHistory(prev=> !prev)}>
                    show history
                </div>
            </div>
        </div>
  )
}

export default StopTimeItem