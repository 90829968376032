import { useState, useEffect } from 'react';
import { Error, ValueProps } from './validateLogin';
import { useDispatch } from 'react-redux';
import { LoginData } from '../../utils/apiCalls';
import { AppDispatch } from '../../reducers/store';
import { useLoginState } from '../../components/selectRef/hook/useGeofenceContext';



type Validate = (data: ValueProps)=> Error 
type Callback = (data: LoginData, dispatch: AppDispatch, setState:React.Dispatch<React.SetStateAction<string>>)=> void
const useLogin = (validate: Validate, callback: Callback) => {
  const dispatch = useDispatch<AppDispatch>();
  const [errors, setErrors] = useState<Error>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {setState} = useLoginState();
  const [values, setValues] = useState({
    email: "",
    password: ""
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };
      
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  console.log("values", values)
  useEffect(
    () => {

      if(Object.keys(errors).length === 0 && (Object.keys(values).length === 2 && isSubmitting)){
          setState("loading")
          setIsSubmitting(false)
          setTimeout(()=>{
            console.log('once')
            callback(values, dispatch, setState)
            // console.log(values)
          }, 500)
        
      }else{
        setIsSubmitting(false)
      }
    },
    [errors, isSubmitting, values, callback, dispatch, setState]
  );
  
  return { handleChange, handleSubmit, errors, isSubmitting};

}

export default useLogin