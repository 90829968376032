import React, {useEffect, useState} from 'react'
import Body, { BodyItems } from './Body'
import Header from './Header'
import { StyledTable, TBody, THead } from './tableStyled'
import { useQueryClient } from 'react-query'



type TableProps = {
  loading: boolean,
  posts: any[]
}

const headers = [
  {
    id: 1,
    title: 'Device Type',
    icon: true
  },
  {
    id: 2,
    title: 'Device Name',
    icon: true
  },
  {
    id: 3,
    title: 'IMEI'
  },
  {
    id: 4,
    title: 'Sim Iccdi'
  },
  {
    id: 1,
    title: 'Phone No'
  },
  {
    id: 1,
    title: 'Last Connected'
  }
]
const Table = ({loading, posts}: TableProps) => {

  //That data item is to be implemented
  return (
    <>
    {
      loading 
      ?<div>Loading...</div> 
      : (
          <StyledTable>
            <THead>
              <Header headers={headers}/>
            </THead>
            
            <TBody>
              {
                posts?.map((item:any, index:number)=> (<Body 
                                          status={"I"}
                                          rowNumber={index + 1}
                                          deviceType={"Electronics"}
                                          deviceName={item.deviceName}
                                          imei={item.imei}
                                          simIccd={item.simiccid}
                                          phone={item.phone}
                                          lastConnected={"10th Feb 2022"}
                                          key={index+ 1}
                                        />))
              }
            </TBody>
          </StyledTable>
      )
    }
    </>
  )
}

export default Table