import {useEffect, useState} from 'react'
import { client } from '../utils/axios-instance';
import {getToken} from './getToken';
const useMultipleClients = (firstUrl: string, secondUrl: string) => {

    const [firstCall, setFirstCall] = useState<any>();
    const [err, setErr] = useState('')
    const [secondCall, setSecondCall] = useState<any>();
    const [data, setData] = useState('loading')
   
   
      console.log("first", firstCall)
      console.log("second", secondCall)
  
     //Get the devices, and plan addons from the server
    useEffect(()=>{
      let  token = getToken()
      let request = client(token)
      const fetchData = async ()=>{
        try{
          const [firstData, secondData] = await Promise.all([
            request.get(process.env.REACT_APP_ENTERPRISE_URL + firstUrl),
            request.get(process.env.REACT_APP_ENTERPRISE_URL + secondUrl),
          ])
          setFirstCall(firstData?.data);
          setSecondCall(secondData?.data);
          setData('success');
        }catch(e: any){
          setErr(e.message);
          setData("failed")
        }
       
      }
  
      fetchData();
    }, [firstUrl, secondUrl]);
  
  
    return {firstCall, secondCall, data, err}
}

export default useMultipleClients