export function updateDeviceData(oldData:any, newData:any) {
    let arr = []
    const idSet = new Set(oldData?.map((item:any) => item._id)); 
    if((newData !== "undefined") && (oldData !== "undefined")){
        newData.forEach((item:any) => {
            if (!idSet.has(item._id)) { // if the item's id is not already in arr1
              oldData.push(item); // add the item to arr1
              idSet.add(item._id); // add the item's id to the set
            }
        });
        console.log("inner func")
        arr = [...oldData]
        return arr;
    }else{
        return oldData
    }
    // create a set of existing ids in arr
}

export function isIterable(input: Array<any>) {  
    if (input === null || input === undefined) {
      return false
    }
  
    return typeof input[Symbol.iterator] === 'function'
}

export function mergeData(a:any[], b:any[], prop: string) {
  var reduced = a?.filter(aitem => !b.find(bitem => aitem[prop] === bitem[prop]))
  return reduced && reduced.concat(b);
}

export function reducedDevice(a:any[], b:any[], prop: string){
  let reduced;
  if(b && b.length > 0){
    reduced = a?.filter(aitem => !b.find(bitem => aitem[prop] === bitem[prop]))
    return reduced;
  }
  reduced = a
  return reduced
}