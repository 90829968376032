import React from 'react'
import { TD, TR } from './tableStyled'
import { HiDotsVertical } from 'react-icons/hi'

export type BodyItems = {
    status: string,
    rowNumber: number,
    deviceType: string,
    deviceName: string,
    imei: number,
    simIccd: number,
    phone: string,
    lastConnected: string
}
const Body = ({
    status,
    rowNumber,
    deviceType,
    deviceName,
    imei,
    simIccd,
    phone,
    lastConnected

}: BodyItems) => {
  return (
   
    <TR bg key={rowNumber} >
        <TD>
            <div className="table__body">
                <input type="checkbox" />
            </div>
        </TD>
        <TD>
            <div  className="table__body status">
                <span>{rowNumber}</span>
            </div>
        </TD>
        <TD>
            <div  className="table__body">
               {deviceType}
            </div>
        </TD>
        <TD>
            <div  className="table__body">
              {deviceName}
            </div>
        </TD>
        <TD>
            <div  className="table__body">
               {imei}
            </div>
        </TD>
        <TD>
            <div  className="table__body">
               {simIccd}
            </div>
        </TD>
        <TD>
            <div  className="table__body">
               {phone}
            </div>
        </TD>
        <TD>
            <div  className="table__body">
                {lastConnected}
            </div>
        </TD>
        <TD>
            <div  className="table__body icon">
                <HiDotsVertical/>
            </div>
        </TD>
    </TR>
  )
}

export default Body