import React,{useState} from 'react'
import { Select, Option } from '../selectRef/select'
import { HistoryContent } from './history.styled'
import SelectDateItem from './SelectDateItem'
import ShowHistory from './ShowHistory'
import StopTimeItem from './StopTimeItem'

const History = () => {
    const [advanced, setAdvanced] = useState(false)
    const [history, setHistory] = useState(false)
  return (
   <>
    <HistoryContent>
        {/*Select devices*/}
        <Select>
            <Option value="cool">Device</Option>
            <Option value="cool">Device</Option>
            <Option value="cool">Device</Option>
            <Option value="cool">Device</Option>
            <Option value="cool">Device</Option>
            <Option value="cool">Device</Option>
        </Select>
    </HistoryContent>
    <HistoryContent>
        {/* Select date */}
         <SelectDateItem setAdvanced={setAdvanced} advanced={advanced}/>

        {/* Stop date */}
            {
                advanced ?  <StopTimeItem setHistory={setHistory}/> : null
            }
        
        {/* Show history */}
         {
            advanced && (
                history ? <ShowHistory/> : null
            )
         }
    </HistoryContent>
   </>
  )
}

export default History