import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background: #fff;
  height: 11.8rem;
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  &::-webkit-scrollbar{
      background: #fff;
      height: 8px;
      //width: 5px;
    }
   
  &::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 10px;
  }


  .bottom-item{
    //flex: 1;
  }

  .bottom-header{
    background: #CFF4D2;
    display: flex;
    justify-content: space-between;
    height: 24px;
    /* border-top: none; */
    /* gap: 9rem; */
  }

  

  .bottom-header-item{
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 5px 0; */
    padding-left: 10px;
    border: 1px solid #E9EDF5;
    border-top: none;
    border-left: none;
  }

  .btt-left-item{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 0.65rem;
    line-height: 10px;
    letter-spacing: var(--letter-spacing);
    letter-spacing: 0.02em;
    color: #000000;
  }

  .align{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

 .pr-2{
  padding-right: 10px;
 }

  .btt-header-icon{
    font-size: 1.5rem;
  }

  .btt-icon-1{
    font-size: 1rem;
  }

  .btt-status{
    display: inline-flex;
    font-size: 0.875rem;
    background: var(--item-bg);
    border-radius: 50px;
    width: 1rem;
    height: 1rem;
    display: grid;
    place-items: center;
    color: #fff;
    font-weight: 500;
  }

  .bottom-close-icon{
    display: grid;
    place-items: center;
    background: rgba(236, 28, 36, 0.15);
    aspect-ratio: 1;
    width: 6%;
  }

  .bottom-content{
    padding-left: 10px;
    display: flex;
    justify-content: space-between;
  }

  .bot-cont-detail{
    padding-top: 6px;
    padding-left: 5px;
    //flex: 1;
    border-right: 1px solid #D9D9D9;
    border-top: none;
    border-left: none;
    width: 380px;
    max-height: 160px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
   // gap:0;
  }

  /* .bot-cont-detail.service{
    width: 200px;
  } */

  .bottom-detail{
    //border-bottom: 1px solid #E9EDF5;
    display: flex;
    align-items: center;
    //gap: 6px;
    padding-top: 5px;
  }

  .bottom-detail.service{
    border-bottom: none;
    padding-left: .8rem;
  }

  .bottom-detail-left{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .bottom-detail-left .text-2.network{
    display: inline-flex;
    align-items: center;
    gap: 5px;
  }

  .text-2.network span{
    border: 1px solid #505050;
    border-radius: 10px;
    padding: 0 8px;
    font-size: 0.6rem;
  }

  .bottom-detail-left .text-2{
    font-size: 0.65rem;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.2px;
  }

  .text-2.locate > span{
    margin-right: 10px;
  }

  .bottom-detail-left .indicator{
    color: #4D9200;
  }

  .bottom-detail-left .space{
    width: 16px;
  }

  .bottom-detail-left .imei{
    background: #CFF4D2;
    border-radius: 5px;
    padding: 3px;
  }

  .bottom-detail-left.service{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E9EDF5;
    opacity: 0.5;
    border-radius: 5px;
    padding: 8px;
  }

  .btt-detail-icon{
      font-size: .875rem;
      color: #505050;
  }
`