import _ from 'lodash'

const isNotArrayOrUndefined = (val:any)=>{
    return !Array.isArray(val) && typeof val !== 'undefined'
}
export const compareData = async (data: any, oldData:any)=>{

  console.log("new", data)
  console.log("old", oldData)
    const newData = Object.keys(data).reduce((obj:any, val:any)=>{
      if(isNotArrayOrUndefined(data[val])){
        if(data[val] !== oldData[val]){
            obj[val] = data[val]
        }
      }else{
        if(!(_.isEmpty(data[val]))){
            obj[val] = data[val]
        }
      }

        return obj
    }, {})
    
   return newData 
}


/**
 * The function compares two objects and returns a new object with the updated values.
 * @param data - A record (object) containing new data to be compared with old data.
 * @param oldData - The old data object that we want to compare with the new data object.
 * @returns a new object `newData` that contains the keys and values from the `data` object that are
 * different from the corresponding keys and values in the `oldData` object.
 */
export function compareObjData(data: Record<string, unknown>, oldData: Record<string, unknown>): Record<string, unknown> {
  const newData: Record<string, unknown> = {};

  for (const key in data) {
    const value = data[key];
    const oldValue = oldData[key];

    if (Array.isArray(value) || value === undefined) {
      continue;
    }

    if (value !== oldValue) {
      newData[key] = value;
    }
  }

  return newData;
}



