import React, { ReactElement, useState } from "react"
import { HeaderWrapper } from "../sidebar/sidebar.styled"
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TabTitle from "./TabTitle"
import useToggleClass from "../../features/changeclass";
import Geofence from "./Geofence/Geofence";
import { useGeofence, useNewGeoUser, } from "../selectRef/hook/useGeofenceContext";
import TopRightMapItem from "./TopRightMapItem";

type Props = {
  children: ReactElement[],
  toggle : React.MouseEventHandler<HTMLDivElement>
}

const Tabs: React.FC<Props> = ({ children, toggle }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  //const {dispatch, ...state} = useGeoUser()
  const {...showItems} = useNewGeoUser()
  const {toggleState, changeClass}  = useToggleClass('side-list active', 'side-list');
  let tabindex = selectedTab !== null ? children[selectedTab] : null
  
  //console.log(state.showGeo)
  return (
    <div>
        <HeaderWrapper>
            <ul>
                {children.map((item, index) => (
                <TabTitle
                    key={index}
                    title={item.props.title}
                    index={index}
                    setSelectedTab={setSelectedTab}
                    icon={item.props.icon}
                    changeClass ={changeClass}
                    toggleState={toggleState}
                />
                ))}
            </ul>
            <div className='sidebar-close' onClick={toggle}>
                <CloseOutlinedIcon className='side-close-icon'/>
            </div>
        </HeaderWrapper>
        { showItems.showItem ? <TopRightMapItem/> : tabindex}
    
    </div>
  )
}

export default Tabs