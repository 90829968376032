import React, {useState} from 'react'
import { BiBriefcaseAlt } from 'react-icons/bi'
import useToggleClass from '../../../features/changeclass'
import {ProfileContainer, SwitchProfile, UserProfileForm } from '../modalForm.styled'

import BusinessProfile from './BusinessProfile'
import UserProfile from './UserProfile'
import { RiUserSettingsLine } from 'react-icons/ri'

const Profiles = () => {

    const [profile, setProfile] = useState("")
    const {toggleState, changeClass} = useToggleClass("active", "user__profile");
    const handleTabs = (val: string, num: number)=>{
        setProfile(val);
        toggleState(num);
    }
    
  return (
    <ProfileContainer>
        <SwitchProfile>
            <div className={`user__profile ${changeClass(0)}`} onClick={()=>handleTabs("", 0)}>
                <span className="user_item">
                    <RiUserSettingsLine className='user_icon'/>
                </span>
                <span className="user_item">
                    Personal Profile
                </span>
            </div>
            <div className={`user__profile ${changeClass(1)}`} onClick={()=>handleTabs("biz", 1)}>
                <span className="user_item">
                    <BiBriefcaseAlt className='user_icon'/>
                </span>
                <span className="user_item">
                    Business Profile
                </span>
            </div>
        </SwitchProfile>
        <UserProfileForm>
            {
                profile === "biz" 
                ? <BusinessProfile/> 
                :<UserProfile/>
            }
          
        </UserProfileForm>
       
    </ProfileContainer>
  )
}

export default Profiles