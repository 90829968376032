import React,{useState, useRef, useEffect} from 'react'

const useAccordion = () => {
    const [active, setActive] = useState(false)
    const [innerActive, setInnerActive] = useState(false);
    const [isCheckedAll, setIsCheckedAll] = useState<boolean | undefined>(false);
    const [checkOuter, setCheckOuter] = useState(false);
    const [checkInner, setCheckInner] = useState(false)
    const [pop, setPop] = useState<number | undefined>(undefined)

    const ulRef = useRef<HTMLUListElement>(null);
    const outer = useRef<HTMLInputElement | HTMLInputElement[]>([]);
    const inner = useRef<HTMLInputElement | HTMLInputElement[]>([]);

    const handleCheckAll = (e: React.ChangeEvent<HTMLInputElement>)=>{
        if(e.target.checked){
            setIsCheckedAll(true)
        }else{
            setIsCheckedAll(undefined)
        }
    }

    const handleCheckOuter = (e: React.ChangeEvent<HTMLInputElement>)=>{ 
        setCheckOuter(prev=> !prev)
    }
    const handleCheckInner = (e: React.ChangeEvent<HTMLInputElement>)=>{ 
        setCheckInner(prev=> !prev)
    }
    const toggleItem = (e: React.MouseEvent<HTMLDivElement | HTMLSpanElement>)=>{
        e.preventDefault();
        setActive(prev => !prev)
        console.log('outer')
    }
    const toggleInner = (e: React.MouseEvent<HTMLSpanElement>)=>{
        e.preventDefault();
        setInnerActive(prev => !prev)
        console.log('inner')
    }

    const handlePopIndex = (id: number)=>{
        if(Array.isArray(inner.current)){
            if(inner.current[id]){
                setPop(id)
            }
        }else{
            setPop(id)
        }
    }

    useEffect(()=>{
        if(isCheckedAll){
            setCheckOuter(true)
            setCheckInner(true)
        }else{
            setCheckOuter(false)
            setCheckInner(false)
        }

    }, [isCheckedAll])

    return {
        handleCheckAll, 
        handleCheckInner, 
        handleCheckOuter, 
        toggleInner, 
        toggleItem,
        handlePopIndex, 
        isCheckedAll,
        inner, 
        outer, 
        ulRef, 
        checkInner, 
        checkOuter, 
        pop, 
        setPop, 
        innerActive, 
        active
    }
}

export default useAccordion