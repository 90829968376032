import React from 'react'
import { Toggle } from './DeviceLabel'
import { TbUpload } from 'react-icons/tb'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const NewLabelBotton = ({toggleOpen,  title, icon}:Toggle) => {
  return (
    <div className='search-item-btn' onClick={toggleOpen}>
        {
            icon 
            ? <TbUpload className='search-icon'/> 
            : <AddOutlinedIcon className='search-icon'/>
        }
        
        <span className='search-desc'>
            {title ? title : 'Add Device'}
        </span>
    </div>
  )
}

export default NewLabelBotton