import React,{useState, useEffect} from 'react'
import { client } from '../utils/axios-instance';
import { getToken } from './getToken';
export type CallData = {
    data: any
}
const useRequestModel = (endpoint: string, id?: string) => {
    const [model, setInfo] = useState<CallData>();
   
    const [data, setData] = useState('loading');
    
    console.log(endpoint)
    console.log("device mode", model)
    console.log("idr", id)
  
     //Get the devices, and plan addons from the server
    useEffect(()=>{
      let token = getToken()
      let request = client(token)
      const fetchData = async ()=>{
        try{
          const res = await request.get(process.env.REACT_APP_ENTERPRISE_URL + endpoint + "/" + id)
          console.log("inside model")
          setInfo(res?.data);
          setData("success");
         
        }catch(e: any){
          console.error(e.message)
          setData("failed");
        }
       
      }
  
      if(id){
        fetchData();
      }
     
    }, [endpoint, id]);

    return {model}
  
}

export default useRequestModel