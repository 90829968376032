import styled from "styled-components/macro";
import { DeviceContainer } from "../dashboard/dashboard.styled";


export const CanvasContainer = styled(DeviceContainer)`
    height: 84vh;
    /* overflow-y: hidden; */
`

export const CreateCanvas = styled(DeviceContainer)`
    height: 84vh;
`
//Upload canvas styled
export const UploadImage = styled.div`
    background: #fff;
    height: 85%;
    display: flex;
    flex-direction: column;
    position: relative;
    
    .drag__drop{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .canvas__form.btn_group{
        position: absolute;
        bottom: 1rem;
        right: 8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
    }

    .canvas__form.btn_group button{
        padding: 6px 15px;
        border: 1px solid #B5B5B5;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
    }

    .canvas__form.btn_group .canvas__cancel{
        color: #505050;
        background: #fff;
    }

    .canvas__form.btn_group .canvas__save{
        color: #fff;
        background: #4D9200;
    }

    /* position: relative; */
`

//Arrange Items styled: Inherited from the createCanvas styled
export const ItemPositionContainer = styled(CreateCanvas)``
export const ArrangeItem = styled(UploadImage)`
    .drag__drop{
        display: block;
        width: 94%;
        height: 20rem;
        max-height: 20rem;
        margin-left: 2.5rem;
        overflow-y: scroll;
        position: relative;
    }

    .drag__drop::-webkit-scrollbar{
        background: #fff;
        width: 8px;
    }

    .drag__drop::-webkit-scrollbar-thumb {
        background: #d9d9d9;
        border-radius: 15px;
    }

    .image__canvas{
        position: relative;
        width: 100%;
        height: 58rem;
        background-color: #fff;
        background-image: url(${process.env.PUBLIC_URL+"/assets/images/canvas-image.png"});
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: 100%;
    }
    .draggable__icon{
        position: relative;
        display: grid;
        place-items: center;
        font-size: 1rem;
        color: #fff;
        width: 40px;
        height: 40px;
        border: 2px solid #4D9200;
        border-radius: 50%;
        background: rgba(77, 146, 0, 0.3);
    }

    .draggable__icon .device__value{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        color: initial;
        top: -20px;
        font-size: 14px;
    }

    .draggable__icon .span__icon{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #4D9200;
    }
`