import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import AuthHeader from '../../components/header/AuthHeader'
import { useAppSelector } from '../../reducers/disPatchSelector'
import { LayoutContainer} from '../dashboard/dashboard.styled'

//Display the top layers such as dashboard, maps etc.
const DashboardContents = () => {
  const auth = useAppSelector(state=> state.userReducer)
  const verifyUser  = useAppSelector(state=> state.userReducer.currentUser?.body?.data?.verifications)

  if ((!auth.isAuth) && (!verifyUser)){
    return <Navigate to="/sign-in" replace/>
  }

  
  return (
    <LayoutContainer>
        <AuthHeader/>
        <Outlet/>
    </LayoutContainer>
  )
}

export default DashboardContents