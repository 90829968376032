import styled from 'styled-components'

export const InputContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;

    &:focus-within label {
        transform: translate(0, 12px) scale(0.8);
        color: var(--item-bg);
    }

    .filled {
        transform: translate(0, 12px) scale(0.8);
    }

    label {
        position: absolute;
        pointer-events: none;
        transform: translate(0, 23px) scale(1);
        transform-origin: top left;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        color: #838191;
        font-size: 1rem;
        line-height: 1;
        left: 16px;
        top: -8px;
    }

    .float__input {
        height: 45px;
        border-radius: 6px;
        border: 1px solid #E9EDF5;
        background: #F3F4F6;
        padding: 24px 16px 8px 16px;
        font-size: 1rem;
        line-height: 1;
        outline: none;
        box-shadow: none;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        color: #838191;
    }

    .float__input:focus {
        box-shadow: 0 0 0 2px var(--item-bg);
    }
`