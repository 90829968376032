import React from 'react'
import './spinner.css'

type Spin={
  spin?: string,
}
const Spinner = ({spin}: Spin) => {
  
const spinner = spin !== undefined ? 'spinner__spin' : 'spinner'
  return (
    <div className={spin !== undefined ? 'spinner__spin' : 'spinner'}></div>
  )
}

export default Spinner