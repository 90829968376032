import React, { useState } from 'react'
import { Form } from '../../components/modalForm/modalForm.styled'
import { NewReportForm } from './report.styled'
import Divider from '../../components/divider/Divider'
import TextInput from '../../components/floatInput/FloatInput'
import { BsDownload } from 'react-icons/bs'
import moment, { getTime } from './getTime'
import { timeOptions } from './selectOptionsTime'
import useReport from './useReport'
import MultiSensorPage from '../../components/multiDeviceSelect/MultiSensorPage'
import { DevicesData, devicesData } from '../../components/selectRef/hook/useGeofenceContext'
import SensorSelect from './SensorSelect'



const NewReport = () => {
    const     [item, setItem]  = useState<DevicesData>(devicesData)
    const {
        dateFrom, 
        dateTo, 
        day,
        device,
        reportItem, 
        selectDate,  
        handleDate,
        handleReport,
        handleSubmit
    } = useReport();

    console.log("report",  reportItem)

  return (
    <NewReportForm>
        <Divider title='Report Details' width={50} mt={1}/>
        <div className="form-content">
            {/* <div className="form-item">
                <TextInput 
                    label="Title" 
                    name="title" 
                    val={reportItem.title}
                    onChange={handleReport}
                />
            </div> */}
            <div className="form-item">
                <select name="title" id="title"  onChange={handleReport}>
                <option value="" hidden>Title</option>
                    <option value="General Report">General Report</option>
                </select>
            </div>
            <div className="form-item">
                <select name="imei" id="imei"  onChange={handleReport}>
                <option value="" hidden>Select Device</option>
                    {
                        device?.map((d:any)=><option value={d?.imei} key={d?._id}>{d?.deviceName}</option>)
                    }
                </select>
            </div>
            <div className="form-item">
                <select name="format" id="format"  onChange={handleReport}>
                <option value="" hidden>Format</option>
                    <option value="pdf">PDF</option>
                    <option value="csv">CSV</option>
                    <option value="html">HTML</option>
                </select>
            </div>
            <div className="form-item">
                <SensorSelect/>
            </div>
        </div>

        <Divider title='Period' width={54} mt={1}/>
        <div className="form-content">
            <div className="form-item radio-item report">
                <div className="radio-btn">
                    <input type='radio' id='today' name='sim' value="1" onChange={selectDate} defaultChecked={day === "1"}/>
                    <span className="radio-desc">Today</span>
                </div>
                <div className="radio-btn">
                    <input type='radio' id='yesterday' name='sim' value="-1" onChange={selectDate}/>
                    <span className="radio-desc">Yesterday</span>
                </div>
                <div className="radio-btn">
                    <input type='radio' id='2days' name='sim' value="-2" onChange={selectDate}/>
                    <span className="radio-desc">2 days ago</span>
                </div>
                <div className="radio-btn">
                    <input type='radio' id='3days' name='sim' value="-3" onChange={selectDate}/>
                    <span className="radio-desc">3 days ago</span>
                </div>
                <div className="radio-btn">
                    <input type='radio' id='thisWeek' name='sim' value="7" onChange={selectDate}/>
                    <span className="radio-desc">This Week</span>
                </div>
                <div className="radio-btn">
                    <input type='radio' id='lastWeek' name='sim' value="-7" onChange={selectDate}/>
                    <span className="radio-desc">Last Week</span>
                </div>
                <div className="radio-btn">
                    <input type='radio' id='thisMonthr' name='sim' value="29" onChange={selectDate}/>
                    <span className="radio-desc">This Month</span>
                </div>
                <div className="radio-btn">
                    <input type='radio' id='lastMonth' name='sim' value="-29" onChange={selectDate}/>
                    <span className="radio-desc">Last Month</span>
                </div>
            </div>

           <div className="form-item">
                <TextInput 
                    type='text'
                    label="Date from" 
                    name="dateFrom" 
                    val={typeof dateFrom === "string" ? dateFrom.split("T")[0]: undefined}
                    onChange={handleDate}
                />
            </div>
            <div className="form-item">
                <TextInput 
                    type='text'
                    label="Date To" 
                    name="dateTo" 
                    val={typeof dateTo === "string" ? dateTo.split("T")[0]: undefined}
                    onChange={handleDate}
                />
            </div>
            <div className="form-item">
                <select name="startTime" id="starttime" onChange={handleReport}>
                    <option value="" hidden>Time From</option>
                    {
                        timeOptions.map(time=> <option value={time} key={time}>{time+"hrs"}</option>)
                    }
                </select>
            </div>
            <div className="form-item">
                <select name="endTime" id="endtime" onChange={handleReport}>
                    <option value="" hidden>Time To</option>
                    {
                        timeOptions.map(time=> <option value={time} key={time}>{time+"hrs"}</option>)
                    }
                </select>
            </div>
        </div>

        <Divider title='Schedule' width={53} mt={1}/>
        <div className="form-content auto">
                  <input type="checkbox" name="auto" id="auto" 
                  //onChange={toggleConfig} 
                  />
                  <span className="radio-desc">Auto-Configure</span>
        </div>
        <div className="form-content">
            <div className="form-item">
                <TextInput 
                    label="Email" 
                    name="Email" 
                />
            </div>
            <div className="form-item radio-item schedule">
                <div className="radio-btn">
                    <input type='radio' id='gentro' name='sim' value='gentro'/>
                    <span className="radio-desc">Daily</span>
                </div>
                <div className="radio-btn">
                    <input type='radio' id='gentro' name='sim' value='gentro'/>
                    <span className="radio-desc">Weekly</span>
                </div>
                <div className="radio-btn">
                    <input type='radio' id='gentro' name='sim' value='gentro'/>
                    <span className="radio-desc">Monthly</span>
                </div>
            </div>
            <div className="form-item radio-item">
                <div className="radio-btn">
                    <input type='checkbox' id='gentro' name='sim' value='gentro'/>
                    <span className="radio-desc">Skip blank  report</span>
                </div>
            </div>
            <div className="form-item radio-item time">
                <div className="radio-btn tick">
                    00:00
                </div>
                <div className="radio-btn tick">
                   00:00
                </div>
            </div>
        </div>

        <div className="form-content btn">
            <button className="save-btn download-btn" onClick={handleSubmit}>
                <BsDownload/>
            </button>
            <button className='save-btn cancel'> Cancel</button>
            <button className='save-btn'>Save</button>
        </div>

    </NewReportForm>
  )
}

export default NewReport