import React from 'react'
type Onclick = {
    setAdvanced: React.Dispatch<React.SetStateAction<boolean>>,
    advanced: boolean
}
const SelectDateItem = ({setAdvanced, advanced}: Onclick) => {
  return (
    <div className="history__content">
        <div className="history__content_item">
            <span className='text-6'>From</span>
            <div className="input__item">
                <input type='date'/>
                <input type='time'/>
            </div>
        </div>
        <div className="history__content_item">
            <span className='text-6'>From</span>
            <div className="input__item">
                <input type='date'/>
                <input type='time'/>
            </div>
        </div>
        <span 
            className={advanced ? 'history__option change text-7 pl-5' : 'history__option text-7 pl-5'} 
            onClick={()=> setAdvanced(prev => !prev)}
            >
                Advanced option
        </span>
    </div>
  )
}

export default SelectDateItem