import { useEffect } from 'react'
import Card from '../../components/card/Card'
import Layout from '../../components/layout/Layout'
import  Header from '../../components/header/Header'
import {MainSection, Left, Right } from './loginStyled'
import LoginForm from './LoginForm'
import useLang from '../../language/useLang'
import { useAppSelector } from '../../reducers/disPatchSelector'
import { Navigate } from 'react-router-dom'
import LeftItem from './LeftItem'
import { useQueryClient } from 'react-query'


const Login = () => {
  const auth = useAppSelector(state=> state.userReducer)
  const verifyUser:boolean  = useAppSelector(state=> state.userReducer.currentUser?.body?.data?.verifications)
  console.log("v-user", verifyUser)
  const queryClient = useQueryClient()
  
  //const {}
  
  //Change the language settings
  const {t, handleLanguage} = useLang();


  useEffect(()=>{
    queryClient.clear();
  },[queryClient])

  if(auth.isAuth && !auth.error && verifyUser) {

    return <Navigate to='/dashboard' replace/> ;
  }

  
  return (
    <Layout>
       <Header handleLanguage={handleLanguage}/>
       <MainSection>
         <Left>
          <LeftItem/>
         </Left>
         <Right>
          <Card>
            <div  className='image'>
              <img 
                src={process.env.PUBLIC_URL + "/assets/images/gentro.png"} 
                alt="gentro Logo"
              />
            </div>
            <LoginForm t={t} verifyUser={verifyUser}/>
          </Card>
         </Right>
       </MainSection>
    </Layout>
  )
}

export default Login