import {useEffect, useState} from 'react'

type StateProps = {
    activeIndex: {id: number} | null,
    item: {id: number}[]
}
const useToggleClass = (activeClass: string, className: string) => {
    const [activeState, setActiveState] = useState<StateProps>({
        activeIndex: null,
        item: [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}]
    });
    
    const toggleState = (index: number) =>{ 
        setActiveState({...activeState, activeIndex: activeState.item[index]});
    }

    
    //Check for  activeState
    useEffect(()=>{
        if(activeState.activeIndex === null){
            setActiveState({...activeState, activeIndex: activeState.item[0]})
        }
    }, [activeState])
    
    let changeClass = (index: number) =>{
        if(activeState.item[index]=== activeState.activeIndex){
            return activeClass;
        } else {
            return className;
        }
    }

    return{
        toggleState,
        changeClass
    }
}

export default useToggleClass