import React from 'react'
import Body, { BodyItems } from './Body'
import Header from '../Header'
import { StyledTable, TBody, THead } from '../tableStyled'



type TableProps = {
  loading: boolean,
  posts: BodyItems[]
}

const headers = [
  {
    id: 1,
    title: 'Name of 2D Canvas',
    icon: true
  },
  {
    id: 2,
    title: 'Number of Device'
  },
  {
    id: 3,
    title: 'Last Modified'
  },
 
]
const Table = ({loading, posts}: TableProps) => {

  //That data item is to be implemented
  return (
    <>
    {
      loading 
      ?<div>Loading...</div> 
      : (
          <StyledTable>
            <THead>
              <Header headers={headers}/>
            </THead>
            
            <TBody>
              {
                posts.map(item=> (<Body 
                                      rowNumber={item.rowNumber}
                                      deviceName={item.deviceName}
                                      deviceNo={item.deviceNo}
                                      modified={item.modified}
                                      key={item.rowNumber}
                                  />))
              }
            </TBody>
          </StyledTable>
      )
    }
    </>
  )
}

export default Table