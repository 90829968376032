import React, {useState, useEffect} from 'react'
import { useQueryClient } from 'react-query'
import Divider from '../../divider/Divider'
import { NewGroupContainer, NewGroupForm } from '../modalForm.styled'
import TextInput from '../../floatInput/FloatInput'
import SelectPage from '../../multiselectAction/SelectPage'
import { Data } from '../../multiselectAction/typed'
import MultiSelectPage from '../../multiselect/MultiSelectPage'
import { GroupData } from './data.typed'
import useClient from '../../../hooks/useClient'
import useFormSubmission from '../../selectRef/hook/useFormSubmission'
import { Toggle } from '../../modal/typeProps'
import useMonitorClient from '../../../hooks/useMonitorClient'
import MultiGroupDevicePage from '../../multiSelectGroupDevice/MultiGroupDevicePage'
import Success from '../../success/Success'
import NewGroupItem from './NewGroupItem'
import { reducedDevice } from '../../device/getUpdateData'
import EditGroupItem from './EditGroupItem'



type NewGroupProps = {
    toggleModal: ()=> void
    item?: any
    groupKind: string
}
const data = [
    {
        id: "1",
        title: "Temperature"
    },
    {
        id: "2",
        title: "Speed "
    },
]

const devices = [
    {
        id: "1",
        title: "Vehicle"
    },
    {
        id: "2",
        title: "Electronics "
    },
]





const url = process.env.REACT_APP_MONITOR_URL + "groups"

const NewGroup = ({toggleModal, item, groupKind}: NewGroupProps) => {
     const groups: GroupData = {
        name: "" || item?.name,
        type: "general" || item?.type,
        devices: [],
        sensors: []
    }
    const [options, setOption] = useState<Data[]>(data)
    const [groupDevice, setGroupDevice] = useState<any>()
    const [groupData, setGroupData] = useState<GroupData>(groups)
    const {resp} = useClient("device-types")
    const queryClient = useQueryClient()
    const {handleSubmit, state:formState} = useFormSubmission<GroupData>(groupData, url, toggleModal,{}, groupKind, item?._id, item)
    const {clientData:parentGroup} = useMonitorClient("groups/parents")
    // console.log("first", firstCall)
    console.log("parent", parentGroup)

    console.log("cache", groupDevice)

    useEffect(()=>{
        const getData = async ()=>{
            let gData:any = queryClient.getQueryData("devices") 
            let reduced = reducedDevice(gData?.data?.data, item?.devices,"_id")
            setGroupDevice(reduced)
        }
        getData();
    }, [queryClient, item?.devices])

    
    if(
        formState === "loading" 
        ||  formState === "failed" 
        || formState === "success"
        ){
        return <Success 
                state={formState} 
                load=" Configuring Device ...."
                success="Device Successfully Configured"
                failed="Device Configuration failed, try again"
              />
      }

    const handleGroup = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>)=>{
        e.preventDefault();
        const {name, value} = e.target
        setGroupData(prev=>{
            return {
                ...prev,
                [name]: value
            }
        })
    }

    console.log("group",groupData)
  return (
    <NewGroupContainer>
        {
            groupKind === "newgroup" &&  <NewGroupItem
                                            groupData={groupData}
                                            handleGroup={handleGroup}
                                            parentGroup={parentGroup}
                                            handleSubmit={handleSubmit}
                                            groupDevice={groupDevice}
                                            setGroupData={setGroupData}
                                            options={options}
                                         />
        }

        {
            groupKind === "editgroup" && <EditGroupItem
                                            item={groupData}
                                            handleGroup={handleGroup} 
                                            parentGroup={parentGroup} 
                                            handleSubmit={handleSubmit} 
                                            setGroupData={setGroupData}
                                            groupDevice={groupDevice}
                                            options={options}
                                        />
        }
      
    </NewGroupContainer>
  )
}

export default NewGroup