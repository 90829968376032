import styled from 'styled-components/macro'

type Width ={
    width?: number;
}
export const OtpWrapper = styled.div<Width>`
    display: flex;
    width: 100%;
    max-width:${({width})=> width ? width: 260}px;
    column-gap: 10px;

    .otp-input {
        width: 60px;
        height: 50px;
        border: 1px solid #D3D9D2;
        box-shadow: 0px 1px 0px 1px #D3D9D2;
        border-radius: 10px;
        text-align: center;
        font-size: 1rem;
        font-weight: bold;
        line-height: 0.5;
    }

    .otp-input:focus{
        outline: 1px solid #4D9200;
    }
`