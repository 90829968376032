import styled from 'styled-components/macro'

export const TooltipWrapper = styled.div`
    display: inline-block;
    position: relative;
    top: 10rem;
    left: 6rem;

    .Tooltip-Tip {
        position: absolute;
        border-radius: 8px;
        left: 50%;
        transform: translateX(-50%);
        padding: 6px;
        color: var(--tooltip-text-color);
        background: var(--tooltip-background-color);
        font-size: 14px;
        font-family: sans-serif;
        line-height: 1;
        z-index: 100;
        white-space: nowrap;
    }


    /* CSS border triangles */
    .Tooltip-Tip::before {
        content: " ";
        left: 50%;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-width: var(--tooltip-arrow-size);
        margin-left: calc(var(--tooltip-arrow-size) * -1);
    }

    /* Absolute positioning */
    .Tooltip-Tip.top {
        /* top: calc(var(--tooltip-margin) * -1); */
        top: -3.4rem;
    }
    
    /* CSS border triangles */
    .Tooltip-Tip.top::before {
        top: 100%;
        border-top-color: var(--tooltip-background-color);
    }

    /* Absolute positioning */
    .Tooltip-Tip.right {
        left: calc(100% + var(--tooltip-margin));
        top: 50%;
        transform: translateX(0) translateY(-50%);
    }
    /* CSS border triangles */
    .Tooltip-Tip.right::before {
        left: calc(var(--tooltip-arrow-size) * -1);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-right-color: var(--tooltip-background-color);
    }

    /* Absolute positioning */
    .Tooltip-Tip.bottom {
        bottom: calc(var(--tooltip-margin) * -1);
    }
    /* CSS border triangles */
    .Tooltip-Tip.bottom::before {
        bottom: 100%;
        border-bottom-color: var(--tooltip-background-color);
    }

/* Absolute positioning */
    .Tooltip-Tip.left {
        left: auto;
        right: calc(100% + var(--tooltip-margin));
        top: 50%;
        transform: translateX(0) translateY(-50%);
    }
    /* CSS border triangles */
    .Tooltip-Tip.left::before {
        left: auto;
        right: calc(var(--tooltip-arrow-size) * -2);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-left-color: var(--tooltip-background-color);
    }
`
/*Inherit Tooltip styled */

export const DeviceSummaryWrapper = styled(TooltipWrapper)`
    /*Exchange background color to text color */
    .Tooltip-Tip {
        padding: 0;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08), 
                    0px 15px 35px -5px rgba(17, 24, 38, 0.25), 
                    0px 0px 4px 1px rgba(152, 161, 178, 0.1);
        color: var(--tooltip-background-color);
        background: var(--tooltip-text-color);
    }

    /* Absolute positioning */
    .Tooltip-Tip.top {
        /* top: calc(var(--tooltip-margin) * -1); */
        top: -9rem;
    }
    
    /* CSS border triangles */
    .Tooltip-Tip.top::before {
        border-top-color: var(--tooltip-text-color);
    }

   
    /* CSS border triangles */
    .Tooltip-Tip.right::before {
        border-right-color: var(--tooltip-text-color);
    }

    /* CSS border triangles */
    .Tooltip-Tip.bottom::before {
        border-bottom-color: var(--tooltip-text-color);
    }

    /* CSS border triangles */
    .Tooltip-Tip.left::before {
        border-left-color: var(--tooltip-text-color);
    }

    .Tooltip-Tip .Device-Header{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }

    .Device-Header .Device-Title{
       display: flex;
       align-items: center;
       gap: 20px;
       padding: 6px;
    }

    .Device-Header span{
        display: grid;
        place-items: center;
    }

    .Device-Title .Title{
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 20px;
    }

    .Device-Title .Item-Icon{
        width: 16px;
        height: 16px;
        border-radius: 50%;
        font-size: 9px;
        background: #4d9200;
        color: #fff;
    }

    .Device-Header .Close{
        width: 35px;
        height: 35px;
        font-size: 1.3rem;
        background: rgba(236, 28, 36, 0.15);
    }

    .Device-Desc {
        padding: 0 6px;
        padding-bottom: 6px;
        display: flex;
        flex-direction: column;
    }

    .Device-Desc .Details {
        display: flex;
        align-items: center;
        gap: 40px;
    }

    .Details span{
        font-family: 'Roboto';
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #000;
    }

    .Details .desc{
        width: 50px;
        font-weight: 400;
        font-size: 12px;
    }

    .Details .val{
        /* width: 100px; */
        font-weight: 500;
        font-size: 13px;
    }
`