import React from 'react'
import { TD, TR } from '../tableStyled'
import { HiDotsVertical } from 'react-icons/hi'

export type BodyItems = {
    rowNumber: number,
    deviceName: string,
    deviceNo: number,
    modified: string
}
const Body = ({
    rowNumber,
    deviceName,
    deviceNo,
    modified

}: BodyItems) => {
  return (
   
    <TR bg key={rowNumber} >
        <TD>
            <div className="table__body">
                <input type="checkbox" />
            </div>
        </TD>
        <TD>
            <div  className="table__body geo">
                <span>{rowNumber}</span>
            </div>
        </TD>
        <TD>
            <div  className="table__body">
               {deviceName}
            </div>
        </TD>
        <TD>
            <div  className="table__body">
               {deviceNo}
            </div>
        </TD>
        <TD>
            <div  className="table__body">
              {modified}
            </div>
        </TD>
        <TD>
            <div  className="table__body icon">
                <HiDotsVertical/>
            </div>
        </TD>
    </TR>
  )
}

export default Body