import React, {useState} from 'react'
import { PositionItem } from '../header/headerStyled'
import { SideWrapper } from './sidebar.styled'
import SidebarContent from './SidebarContent'
import SidebarSearch, { ModalOpen } from './SidebarSearch'
//import SideHeader from './SideHeader'
import useToggle from '../../features/toggle'
import FormatIndentIncreaseOutlinedIcon from '@mui/icons-material/FormatIndentIncreaseOutlined';
//import Modal from '../modal/Modal'
import {Tab, Tabs} from '../tab/index'
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import SidebarGroup from './SidebarGroup'
import SidebarHistory from './SidebarHistory'



const Sidebar = () => {
  const {open, toggle} = useToggle()
  const [show, setShow] = useState(false)
  const [coll, setcoll] = useState(true)
  const toggleModal = (event: React.MouseEvent<HTMLDivElement>)=>{
    event.preventDefault();
    setShow((prev)=> !prev);
  }
  
  return (
    <PositionItem 
      top={11} 
      left={0}
      wid="22%" 
      > 
        {
          open ? (
                  <div onClick={toggle}>
                    <FormatIndentIncreaseOutlinedIcon className="toggle-icon"/>
                  </div>
                  )
                
                :(
                  <SideWrapper>
                      <Tabs toggle={toggle}>
                        {/* Device */}
                        <Tab title="Device" icon={<FormatIndentIncreaseOutlinedIcon className='side-header-icon'/>}>
                          <SidebarSearch btnTitle='Add device' openModal={setShow} icon/>
                          <SidebarContent open={show} toggle={toggleModal} setOpen={setShow} title="newUser"/>
                        </Tab>

                        {/* Group */}
                        <Tab title="Group" icon={<PeopleOutlinedIcon className='side-header-icon'/>}>
                         <SidebarSearch btnTitle='New Group' openModal={setShow} icon/>
                         <SidebarGroup open={show} toggle={toggleModal} setOpen={setShow}/>
                        </Tab>

                        {/* History */}
                        <Tab title="History" icon={<PeopleOutlinedIcon className='side-header-icon'/>}>
                           <SidebarSearch btnTitle='History' openModal={setShow}/>
                           <SidebarHistory/>
                        </Tab>
                      </Tabs>
                  </SideWrapper>
                )
        }
       
    </PositionItem>
  )
}

export default Sidebar