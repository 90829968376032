import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CalendarViewMonthOutlinedIcon from '@mui/icons-material/CalendarViewMonthOutlined'
import {TbUpload} from 'react-icons/tb'

const SearchInput = () => {
  return (
    <div className='search-item-input'>
        <div className="calender__icon">
            <CalendarViewMonthOutlinedIcon className="icon1"/>
        </div>
        <div className="search-group">
            <div className='search-left'>
                <span className='search-top'>
                    <FilterAltIcon className="search-icon-top"/>
                </span>
                <select>
                    <option value="">All</option>
                </select>
            </div>
            <div className='search-right'>
                <span className='search-bottom'>
                    <SearchIcon className="search-icon-bottom"/>
                </span>
                <input type='text' placeholder='Search'/>
            </div>
        </div>
    
    </div>
  )
}

export default SearchInput