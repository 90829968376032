import { AxiosResponse } from 'axios';
import { useLoginState } from '../components/selectRef/hook/useGeofenceContext';
import { useAppSelector } from '../reducers/disPatchSelector';
import { 
    loginStart, 
    loginSuccess, 
    loginFailure, 
    logOutStart, 
    logOutSuccess, 
    logOutFailure 
} from '../reducers/login/loginSlice';

import { AppDispatch } from '../reducers/store';

import {publicRequest } from './apiConfig'
import { client, TokenHeader } from './axios-instance';


export type LoginData = {
    email: string,
    password: string
}

//const refresh = () => window.location.reload()

//type LoginAction = {}

/**
 * It's a function that takes in a result and a dispatch function, and then dispatches a loginStart
 * action, and then makes a post request to the server, and then dispatches a loginSuccess action with
 * the response data, and if there's an error, it dispatches a loginFailure action.
 * @param result - {email: 'email', password: 'password'}
 * @param dispatch - The dispatch function is used to dispatch actions to the store.
 */
export const loginUser = async (data: LoginData, dispatch: AppDispatch, setState:React.Dispatch<React.SetStateAction<string>>) =>{
    dispatch(loginStart());
    console.log('login...')
    try{
        const res = await publicRequest.post('/users/auth', data)
        localStorage.setItem("access_token", JSON.stringify(res.data?.body.token));
        setState("success")
        console.log(res.data)
        setTimeout(()=>{
            dispatch(loginSuccess(res.data));
        }, 1000)
      
    }catch(err){
        setState("failed")
        dispatch(loginFailure());
    }
}


export const logOutUser = async (dispatch: AppDispatch, setState:React.Dispatch<React.SetStateAction<string>> )=>{
    const token = JSON.parse(localStorage.getItem("access_token")!)
    
    let request = client(token)
    dispatch(logOutStart())
    try{
        const res = await request.get(process.env.REACT_APP_ENTERPRISE_URL + 'sign-out')
        console.log(res.data)
        if(res.data !== null){
            setState("idle")
            dispatch(logOutSuccess());
            localStorage.removeItem("access_token");
            window.location.reload();
        } 
    }catch(err){
        console.log(err)
        dispatch(logOutFailure())
    }
}