import React,{useMemo} from 'react'
import { RE_DIGIT } from './RE_DIGIT';
import { OtpWrapper } from './otp.styled';
export type Props = {
    value: string;
    valueLength: number;
    onChange: (value: string) => void;
    classStyle?: string;
    width?: number
};
const OtpInput = ({ value, valueLength=4, classStyle, width, onChange }: Props) => {
    const valueItems = useMemo(() => {
        const valueArray = value.split('');
        const items: Array<string> = [];
    
        for (let i = 0; i < valueLength; i++) {
          const char = valueArray[i];
    
          if (RE_DIGIT.test(char)) {
            items.push(char);
          } else {
            items.push('');
          }
        }
    
        return items;
    }, [value, valueLength]);

    const focusToNextInput = (target: HTMLElement) => {
        const nextElementSibling = target.nextElementSibling as HTMLInputElement | null;
    
        if (nextElementSibling) {
          nextElementSibling.focus();
        }
    };

    const focusToPrevInput = (target: HTMLElement) => {
        const previousElementSibling = target.previousElementSibling as HTMLInputElement | null;

        if (previousElementSibling) {
            previousElementSibling.focus();
        }
    };

    const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        const target = e.target;
        let targetValue = target.value;

        const isTargetValueDigit = RE_DIGIT.test(targetValue);
    
        if (!isTargetValueDigit && targetValue !== '') {
          return;
        }
    
        targetValue = isTargetValueDigit ? targetValue : ' ';
    
    
        if (!isTargetValueDigit) {
          return;
        }

        const newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1);
        onChange(newValue);
       

        focusToNextInput(target);
    
        const nextElementSibling = target.nextElementSibling as HTMLInputElement | null;
    
        if (nextElementSibling) {
          nextElementSibling.focus();
        }

       
    };

    const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        const { target } = e;
    
        target.setSelectionRange(0, target.value.length);
    };
    const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const {key} = e
        const target = e.target as HTMLInputElement;

        if (key === 'ArrowRight' || key === 'ArrowDown') {
            e.preventDefault();
            return focusToNextInput(target);
        }
      
        if (key === 'ArrowLeft' || key === 'ArrowUp') {
            e.preventDefault();
            return focusToPrevInput(target);
        }

        const targetValue = target.value

        // keep the selection range position
        // if the same digit was typed
        target.setSelectionRange(0, targetValue.length);
    
        if (e.key !== 'Backspace' || targetValue !== '') {
            return;
        }

        focusToPrevInput(target);
    
        const previousElementSibling = target.previousElementSibling as HTMLInputElement | null;
    
        if (previousElementSibling) {
          previousElementSibling.focus();
        }
    };

    return (
        <OtpWrapper width={width}>
          {valueItems.map((digit, idx) => (
            <input
              key={idx}
              type="text"
              inputMode="numeric"
              autoComplete="one-time-code"
              pattern="\d{1}"
              maxLength={valueLength}
              className={classStyle ? classStyle : "otp-input"}
              value={digit}
              onChange={(e)=>inputOnChange(e, idx)}
              onKeyDown={inputOnKeyDown}
              onFocus={inputOnFocus}
            />
          ))}
        </OtpWrapper>
      );
}

export default OtpInput