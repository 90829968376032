import styled from 'styled-components/macro'
// export const LoginContainer = styled.div`
//   width: 100vw;
//   height: 100vh;
//   padding: 20px 50px;
//   display: flex;
//   flex-direction: column;
// `
export const MainSection = styled.div`
  /* height: 100%; */
  background: #fff;
  padding-top: 1rem;
  padding-left: 1.5rem;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 990px){
    justify-content: space-evenly;
  }
  /* gap: 20px; */
`
export const Left = styled.div`
  /* max-width: 100%; */
  /* display: grid; */
  
  h1{
    padding: 0;
    padding-top: 10px;
    width: calc(444px / var(--size-divisor));
    height: 55px;
    font-size: calc(40px / var(--size-divisor));
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.2px;
    margin: 0;
  }

  @media screen and (max-width: 990px){
    h1{
     width: 300px;
    }
  }

  p{
    padding: 0;
    font-family: 'Roboto' sans-serif;
    width: calc(400px / var(--size-divisor));
    height: calc(82px / var(--size-divisor));
    font-size: 20px;
    font-weight: 300;
    line-height: 1.8rem;
    letter-spacing: var(--letter-spacing);
    padding-top: 8px;
  }

  .image{
    width:  calc(25.75rem / var(--size-divisor));
    height: calc(25.75rem / var(--size-divisor));
    margin-top: 2rem;
  }

  img{
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }

  @media screen and (max-width:990px){
    img{
      margin-top: 4rem;
    }
  }
`
export const Right = styled.div`
  /* height: 100vh; */
  max-width: max-content;
  margin-left: 0;
  padding: 50px;
  display: grid;
  place-items: center;
`

export const Form = styled.form`
  padding: calc(50px / var(--size-divisor));
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;

  h4{
    color: var(--item-bg);
    font-weight: 700;
    width: calc(247px / var(--size-divisor));
    height: calc(32px / var(--size-divisor));
    line-height: calc(32px / var(--size-divisor));
    letter-spacing: var(--letter-spacing);
    font-size: calc(1.5rem / var(--size-divisor));
  }

  .err_msg,
  .verify_user{
    font-size: .8rem;
    font-weight: 400;
    color: red;
  }

  .verify_user{
    color: #505050;
  }
  .verify_user >  span{
    cursor: pointer;
  }
  
  div{
    display: flex;
    justify-content: space-between;
  }

  span{
    color: var(--item-bg);
  }

  .forgot_pass{
    color: #4d9200;
    text-decoration: none;
  }

  .button{
    display: grid;
    place-items: center;
    width: calc(30rem / var(--size-divisor));
    height: 3rem;
    padding: 15px, 60px;
    border-radius: 8px;
    border: none;
    font-size: 1rem;
    color: var(--primary-bg);
    background: var(--item-bg);
    cursor: pointer;
  }

  .login__signup > span{
    color: #505050;
  }

  .login__signup span > span {
    cursor: pointer;
    padding-left: 0.5rem;
  }



  @media screen and (max-width: 990px){
    .button{
      border-radius: 5px;
      padding: 0;
      width: 27rem;
    }
  }

  @media screen and (min-width: 570px){
    .button{
      border-radius: 5px;
      padding: 0;
      /* width: 20rem; */
    }
  }
`

export const RegisterForm = styled(Form)``

export const VerificationForm = styled(Form)`
  .verify__mail{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  .verify__mail span{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width:  300px;
    color: #737973;
  }

  .verify_img{
    height: 150px;
  }

  .check_title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    color: #737973;
    line-height: 20px;
    text-align: center;
    text-transform: capitalize;
  }

  .check_desc{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 0.85rem;
    line-height: 18px;
    //text-align: center;
  }

  .code_resend{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .code_resend span{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 0.85rem;
    line-height: 19px;
    /* identical to box height */
    text-align: center;
    /* dark gray */
    color: #737973;

  }

  .code_resend span >span{
    color: #4D9200;
    cursor: pointer;
  }
`

/*******************************EmailSent Page styled******************************************* */
export const Content = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 25px;

  p{
   font-size: 1rem;
   font-weight: 300;
   width: calc(368px / var(--size-divisor));
   height: calc(47px / var(--size-divisor));
   line-height: calc(18.75px / var(--size-divisor));
   letter-spacing: var(--letter-spacing);
  }
 h4{
   color: var(--item-bg);
   font-weight: 700;
   width: calc(247px / var(--size-divisor));
   height: calc(32px / var(--size-divisor));
   line-height: calc(32px / var(--size-divisor));
   letter-spacing: var(--letter-spacing);
 }
 

 .button{
   width: calc(179px / var(--size-divisor));
   height: calc(3rem / var(--size-divisor));
   padding: 15px, 60px;
   border-radius: 8px;
   border: none;
   font-size: 1rem;
   color: var(--primary-bg);
   background: var(--item-bg);
   cursor: pointer;
   /* margin-top: rem; */
 }
`