import React from 'react'

const ShowHistory = () => {
  return (
    <div className="history__content pt-0 pb-0">
        <div className='show__history__item border-b pb-1'>
            <span className='rounded'>
                D
            </span>
            <span className='text-8'>
                1hr 21mins
            </span>
            <span className='text-3'>
                29/04/2022, 1:45pm
            </span>
        </div>
        <div className='show__history__item border-b pb-1'>
            <span className='rounded-dark'>
                P
            </span>
            <span className='text-8'>
                1hr 21mins
            </span>
            <span className='text-3'>
                29/04/2022, 1:45pm
            </span>
        </div>
        <div className='show__history__item border-b pb-1'>
            <span className='rounded'>
                D
            </span>
            <span className='text-8'>
                1hr 21mins
            </span>
            <span className='text-3'>
                29/04/2022, 1:45pm
            </span>
        </div>
    </div>
  )
}

export default ShowHistory