
import { Language } from "../header/headerStyled"
import { LangProps } from "../header/Header"
import { useLocation } from "react-router-dom"
const Lang = ({handleLanguage}:LangProps) => {
 const locate = useLocation().pathname
 console.log(locate)
  return (
    <Language>
        <label>
          {/* {
          locate === '/dashboard/tools' 
          || locate === '/dashboard/map-items/map' 
          || locate === '/dashboard' 
          ? " ": "Change Language"
          } */}
        </label>
        <select 
            name="lang" 
            id="" 
            value={localStorage.getItem("i18nextLng")!} 
            onChange={(e)=>handleLanguage(e.target.value)}
        >
            <option value="en">English</option>
            <option value="fr">French</option>
        </select>
    </Language>
  )
}

export default Lang