import React from 'react'
import { DeviceContainer, Form } from '../modalForm.styled'
import Divider from '../../divider/Divider'
import TextInput from '../../floatInput/FloatInput'
import MultiSensorPage, { MultiSensorEditPage } from '../../multiDeviceSelect/MultiSensorPage'
import { EditDeviceType } from './EditDevice'
import Success from '../../success/Success'

type ItemProps = {
    item: any,
    handleEdit: React.ChangeEventHandler,
    handleSubmit: React.MouseEventHandler,
    setEditDevice: React.Dispatch<React.SetStateAction<EditDeviceType | undefined>>,
    handleSelection: React.ChangeEventHandler,
    simType: string
    sensors: any[],
    submitting?: string,
    formState: string
}


const EditDeviceItem = ({
    item, 
    handleEdit, 
    handleSubmit, 
    setEditDevice, 
    sensors,
    handleSelection,
    simType,
    formState
}: ItemProps) => {

    console.log("edit item", item) 

    if(formState === "loading" ||  formState === "failed" || formState === "success" ){
        return <Success 
                state={formState} 
                load=" Updating Device ...."
                success="Device Successfully Updated"
                failed="Device Update failed, try again"
              />
    }

    
  return (
    <DeviceContainer>
        <Form action="">

            {/* Set New Device Type Section */}
            <Divider title='Edit Device' width={52} mt={1}/>
            <div className="form-content">
                <div className="form-item radio-item">
                    <div className="radio-btn">
                        <input type='radio' id='gentro' name='sim' value='gentro' onChange={handleSelection} checked={simType === "gentro"}/>
                        <span className="radio-desc">Gentro Sim</span>
                    </div>
                    <div className="radio-btn">
                        <input type='radio' id='regular' name='sim' value='regular' onChange={handleSelection}/>
                        <span className="radio-desc">Regular Sim</span>
                    </div>
                    <div className="radio-btn">
                        <input type='radio' id='other' name='sim' value='other' onChange={handleSelection}/>
                        <span className="radio-desc">Other</span>
                    </div>
                    <div className="radio-btn">
                        <input type='radio' id='lora' name='sim' value='lora' onChange={handleSelection}/>
                        <span className="radio-desc">LORA</span>
                    </div>
                </div>
                <div className='form-item'>
                    {/* Spances */}
                </div>
                <div className="form-item">
                    <TextInput 
                        label="Device Name" 
                        name="deviceName" 
                        val={item?.deviceName}
                        onChange={handleEdit}
                    />
                </div>
                <div className="form-item">
                    <TextInput 
                        label="Device Type" 
                        name="deviceType" 
                        val={item?.deviceType}
                        onChange={handleEdit}
                        read={true}
                    />
                </div>
                <div className="form-item">
                    <TextInput 
                        label="IMEI" 
                        name="imei" 
                        val={item?.imei}
                        onChange={handleEdit}
                        read={true}
                    />
                </div>
                <div className="form-item">
                    <TextInput 
                        label="Device Model" 
                        name="deviceModel" 
                        val={item?.deviceModel}
                        onChange={handleEdit}
                        read={true}
                    />
                </div>
                <div className="form-item">
                    <TextInput 
                        label="Simiccid" 
                        name="simiccid" 
                        val={item?.simiccid}
                        onChange={handleEdit}
                    />
                </div>
                <div className="form-item">
                    <TextInput 
                        label="Phone" 
                        name="phone" 
                        val={item?.phone}
                        onChange={handleEdit}
                    />
                </div>
                <div className="form-item">
                    <MultiSensorEditPage
                    options={sensors} 
                    setOption={setEditDevice} 
                    label="Sensors Enabled"
                    />
                </div>
            </div>
            <button className='save-device' onClick={handleSubmit}>Save</button>
        </Form>
    </DeviceContainer>

  )
}

export default EditDeviceItem