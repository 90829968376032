import InputField from '../../components/input/input'
import Button from "../../components/button/Button";
import { Link } from 'react-router-dom'
import Spinner from "../../components/spinner/Spinner";
import { FiCheckCircle } from 'react-icons/fi'


import { useLoginState } from "../../components/selectRef/hook/useGeofenceContext";
import { RegisterForm } from "../login/loginStyled";
import useSignUp from './useSignUp';

export type LangProps = {
    t: (value: string)=> typeof value;
}

const url = process.env.REACT_APP_ENTERPRISE_URL + 'users/sign-up'
const SignUpForm = ({t}: LangProps) => {

  const   {state} = useLoginState();
  const {userState, values, errors,serverErr, handleChange,handleSubmit} = useSignUp(url)

  console.log("state", state)

  return (
    <RegisterForm action="" onSubmit={handleSubmit}>
        <h4>{t('signup')}</h4>
        {
         userState === "failed" && <span className="err_msg">{serverErr}</span>
        }
        <InputField 
            text="text" 
            placeText="First Name" 
            name="firstName" 
            id="lastName"
            val={values.firstName}
            styles={{width: "481px", height: "50px"}}
            handleChange={handleChange}
        />
        {
          errors.firstName && <span className="err_msg">{errors?.firstName}</span>
        }
         <InputField 
            text="text" 
            placeText="Last Name" 
            name="lastName" 
            id="firstName"
            val={values.lastName}
            styles={{width: "481px", height: "50px"}}
            handleChange={handleChange}
        />
        {
          errors.lastName && <span className="err_msg">{errors?.lastName}</span>
        }
        <InputField 
          text="email" 
          placeText="Email" 
          name="email" 
          id="email"
          val={values.email}
          styles={{width: "481px", height: "50px"}}
          handleChange={handleChange}
        />
        {
          errors.email && <span className="err_msg">{errors?.email}</span>
        }
        <InputField 
            text="text" 
            placeText="Phone Number" 
            name="phone" 
            id="phone"
            val={values.phone}
            styles={{width: "481px", height: "50px"}}
            handleChange={handleChange}
        />
        {
          errors.phone && <span className="err_msg">{errors?.phone}</span>
        }
         <InputField 
          text="text" 
          placeText="Password" 
          name="password" 
          id="password"
          val={values.password}
          icon={true}
          styles={{width: "481px", height: "50px"}}
          handleChange={handleChange}
        />
        {
          errors.password && <span className="err_msg">{errors?.password}</span>
        }
    
        {/* <Button className="button">{t("login")}</Button> */}
        <Button className="button" disabled={userState === "loading"}>
          {
            (userState === "idle" ||  userState === "failed") && "Sign-up"
          }
          {
            userState === "loading" && <Spinner/>
          }
          {
            userState === "success" && <FiCheckCircle/>
          }
        </Button>
        <div className="login__signup">
          <span>
            {t('already')}

            <span>
                <Link to={"/sign-in"} className="forgot_pass">
                    {t('login')}
                </Link>
            </span>
          </span>
        </div>
       
    </RegisterForm>
  )
}

export default SignUpForm