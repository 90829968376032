import React, {useState} from 'react'
import Divider from '../../divider/Divider'
import {BsQuestionCircle} from 'react-icons/bs'
import SetDevice from './SetDevice'
import SetGPS from './SetGPS'
import DeviceConfiguration from './DeviceConfiguration'
import { DeviceContainer, Form } from '../modalForm.styled'

import useDeviceForm from './useDeviceForm'
//import { DevicesData } from './deviceTyped'
import { devicesData } from './deviceData'
import useRequestDevice from '../../../hooks/useRequestDevice'
import useMultipleClients from '../../../hooks/useMultipleClient'
import { getId, getmodelId } from '../../../features/getId'
import useRequestModel from '../../../hooks/useRequestModel'
import useValidateField from '../../../hooks/useValidateField'
import { DevicesData, useSetDevice } from '../../selectRef/hook/useGeofenceContext'
import validateSetDevice from './validateSetDeviceFields'
import Success from '../../success/Success'

export type NewDeviceProp = {
  handleToggle: ()=> void
}

const  url = process.env.REACT_APP_ENTERPRISE_URL + "users/devices"

const NewDevice = ({handleToggle}:NewDeviceProp) => {
  const [autoConfig, setAutoConfig] = useState(false)
  //const [devices, setDevice] = useState<DevicesData>(devicesData)
  const [id, setId] = useState(undefined)
  const [modelId, setModelId] = useState(undefined)
  const [simType, setSimType] = useState("gentro")
  //const {handleSubmit} = useDeviceForm(devices, handleToggle)

  
  const {devices, setDevices} = useSetDevice()
  const {val, simState}=  useValidateField("sims", devices.simiccid, "sim")
  const { simState:imeiState}=  useValidateField("devices/exists", devices.imei, "imei")
  const   {handleSubmit,fieldErr, state:formState} = useDeviceForm<DevicesData>(devices, url, handleToggle, simType, simState, imeiState)
  const {firstCall, secondCall} = useMultipleClients("device-types", "device-models")
  const {info}= useRequestDevice("device-types", id)
  const {model} = useRequestModel("device-models", modelId)
 

  //const {} = useOnDemandRequest("device-models", modelId)

  // console.log("devices", devices)
  // console.log("sim", val?.statusCode)
  //console.log("Err", errors)
  //console.log()
  const handleSelection = (e:React.ChangeEvent<HTMLInputElement>)=>{
    const {value} = e.target
    setSimType(value)
  }
  const handleDevice = async(e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>)=>{
    const {name, value} = e.target
    
    let deviceId, modId;
    if(name ==="deviceType"){
      deviceId = await getId(value, firstCall?.data)
      setId(deviceId)
    }

    if(name ==="deviceModel"){
      modId = await getmodelId(value, secondCall?.data)
      setModelId(modId)
    }

    setDevices({
      ...devices,
      [name]: value,
    
    })
  }

  //console.log("id", id)


  
  //const {isSubmitting} = useDeviceForm()
  const toggleConfig = (e: React.ChangeEvent<HTMLInputElement>)=>{
    setAutoConfig(e.target.checked)
  }

  if(
    formState === "loading" 
    ||  formState === "failed" 
    || formState === "success"
    ){
    return <Success 
            state={formState} 
            load=" Configuring Device ...."
            success="Device Successfully Configured"
            failed="Device Configuration failed, try again"
          />
  }

  // if(){
  //   return <Success state={formState}/>
  // }

  //console.log(autoConfig)
  return (
    
        <DeviceContainer>
          <Form action="">

              {/* Set New Device Type Section */}
              <Divider title='Add New Device' width={48.5} mt={1}/>
              <SetDevice 
                devices={devices} 
                handleDevice={handleDevice} 
                deviceType={firstCall?.data}
                models={info?.data?.devicemodels}
                sensors={model?.data.sensors}
                checkSim={simState}
                checkImei={imeiState}
                errors={fieldErr}
                handleSelection={handleSelection}
                simType={simType}
              />
            
              {/* Set Auto Configuration button Section */}
              <div className="form-content auto">
                  <input type="checkbox" name="auto" id="auto" 
                  //onChange={toggleConfig} 
                  />
                  <span className="radio-desc">Auto-Configure</span>
                  <BsQuestionCircle/>
              </div>

              {/* Set GPS Section */}
              {
                  autoConfig && (
                      <>
                          <Divider title='GPS Settings' width={50} mt={1}/>
                          <SetGPS/>

                          {/* Set DEvice Configuration Section */}
                       
                          <Divider title='Device Configuration' width={46} mt={1}/>
                          <DeviceConfiguration/>
                      </>
                  )
              }
            
              <button className='save-device' onClick={handleSubmit}>Save</button>
          </Form>
      </DeviceContainer>
  
    
  )
}

export default NewDevice