import React, {useState} from 'react'
import { EditDeviceType } from '../modalForm/editDevice/EditDevice';
import { GroupData } from '../modalForm/newGroup/data.typed';
import { MultipleSelectProps } from '../multiselect/multiTyped';
import { SelectProvider } from '../multiselect/useSelectContext';
import { DevicesData } from '../selectRef/hook/useGeofenceContext';
import MultiSensorSelect from './MultiSensorSelect';


const MultiSensorPage = ({options, setOption, label}: MultipleSelectProps<DevicesData>) => {
    
    return (
        <SelectProvider>
            <MultiSensorSelect options={options} setOption={setOption} label={label}/>
        </SelectProvider>
    )
}

export const MultiSensorEditPage = ({options, setOption, label}: MultipleSelectProps<EditDeviceType | undefined>) => {
    
    return (
        <SelectProvider>
            <MultiSensorSelect options={options} setOption={setOption} label={label}/>
        </SelectProvider>
    )
}

export default MultiSensorPage