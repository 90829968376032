import React from 'react'
import Divider from '../../divider/Divider'
import { NewGroupContainer, NewGroupForm } from '../modalForm.styled'
import TextInput from '../../floatInput/FloatInput'
import SelectPage from '../../multiselectAction/SelectPage'
import { Data } from '../../multiselectAction/typed'

import MultiSelectPage from '../../multiselect/MultiSelectPage'
import MultiGroupDevicePage from '../../multiSelectGroupDevice/MultiGroupDevicePage'
import { GroupData } from './data.typed'


type NewGroupProps = {
    groupData:GroupData
    handleGroup: React.ChangeEventHandler
    parentGroup: any
    handleSubmit: React.FormEventHandler
    groupDevice: any
    setGroupData: React.Dispatch<React.SetStateAction<GroupData>>
    options: Data[]
}
const NewGroupItem = ({
    groupData, 
    handleGroup, 
    handleSubmit, 
    parentGroup, 
    groupDevice, 
    setGroupData, 
    options}:NewGroupProps) => {
        
  return (
    <NewGroupForm>
            <Divider title="Create New Group" width={47} mt={2}/>
            <div className='form-content group'>
               
                <div className="form-item">
                    <TextInput 
                        label="Group Name" 
                        name="name"
                        val={groupData?.name} 
                        onChange={handleGroup}
                    />
                </div>
                {
                    parentGroup?.data ? (
                        <div className="form-item">
                            <select name='parent' onChange={handleGroup} value={groupData?.parent ? groupData.parent : "se"}>
                            <option value="se" disabled>Select Parent</option>
                            {
                                parentGroup?.data?.groups.map((d:any)=> <option value={d?._id} key={d?._id}>{d?.name}</option>)
                            }
                            </select>
                        </div>
                    ):null
                }
               
                <div className="form-item">
                   <select name='type' onChange={handleGroup} value={groupData?.type ? groupData?.type: "se"}>
                        <option value="se" disabled>Select Group Type</option>
                        <option value="general">General</option>
                        <option value="combined" disabled>Combined</option>
                   </select>
                </div>
                <div className="form-item">
                    {/* <MultiSelectPage 
                        options={resp?.data} 
                        setOption={setGroupData} 
                        label="Select Device Type"
                    /> */}
                    <MultiGroupDevicePage
                        options={groupDevice} 
                        setOption={setGroupData} 
                        label="Select devices to be group"
                    />
                </div>
                <div className="form-item">
                    {
                        groupData.type !== "general" && (
                            <SelectPage 
                                options={options} 
                                setOption={setGroupData} 
                                label="Select Sensors"
                            />
                        )
                    }
                  
                </div>
                <div className="group__btn">
                    <button className='modal__form__btn' onClick={handleSubmit}>Save</button>
                </div>
               
            </div>
            {/* <button className='save-device group'>Save</button> */}
        </NewGroupForm>
  )
}

export default NewGroupItem