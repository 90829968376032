import React,{useState} from 'react'
import { FaUserEdit } from 'react-icons/fa'
import { IoMdClose } from 'react-icons/io'
import { RiLogoutBoxRLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { useAppSelector } from '../../reducers/disPatchSelector'
import { logOutUser } from '../../utils/apiCalls'
import Modal from '../modal/Modal'
import Profiles from '../modalForm/editProfile/Profiles'
import { useLoginState } from '../selectRef/hook/useGeofenceContext'

type UserProps = {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const UserSetting = ({setOpen, open}: UserProps) => {

    const [openModal, setOpenModal] = useState(false);
    const user:any = useAppSelector(state=> state.userReducer.currentUser)
    console.log(user?.body.data.name.slice(0,2))
    const  {setState} = useLoginState()
    const dispatch = useDispatch();
    const toggleModal = ()=>{
        setOpenModal(!openModal);
    }
    
      
  return (
    <div className='setting__container' onMouseLeave={()=>setOpen(!open)}>
        <div className="user__details">
            <div className="user_header">
                <span className="name">
                   {user?.body.data.name.slice(0,2).toUpperCase()}
                </span>
                <span className="close" onClick={()=>setOpen(!open)}>
                    <IoMdClose/>
                </span>
            </div>
            <span className="user_name">
               {user?.body.data.name}
            </span>
            <span className="user_email">
             {user?.body.data.email}
            </span>
        </div>
        <div className="user__role">
            <span className="role_title">
                Role: 
            </span>
            <span className="role_name">
                User
            </span>
        </div>
        <div className="edit__user">
            <div className="edit_profile" onClick={toggleModal}>
                <span className="edit_item">
                    <FaUserEdit className='edit_icon'/>
                </span>
                <span className="edit_title">
                    Edit Profile
                </span>
            </div>
            <div className="edit_profile" onClick={()=>logOutUser(dispatch, setState)}>
                <span className="logout_item" >
                    <RiLogoutBoxRLine className='logout_icon'/>
                </span>
                <span className="logout_title">
                    LogOut
                </span>
            </div>
        </div>

        <Modal 
            open={openModal} 
            setOpen={setOpenModal} 
            toggle={toggleModal} 
            title="Edit Profile" 
            hideScroll="false"
        >
            <Profiles/>
        </Modal>
    </div>
  )
}

export default UserSetting