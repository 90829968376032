import React, { Dispatch, SetStateAction } from 'react'
import DashboardCard from '../../components/dashboardCard/DashboardCard'
import { Chart } from "react-google-charts"
import MapContent from './Map'
import Alert from './Alert'
import useDashboard from './useDashboard'

const cardDetails = [
{
    id: 1,
    detail: "Total Devices",
    bg: '#106437',
    shadow: 'rgba(17, 24, 38, 0.15)',
    total: 1290,
    devices:"totalDevices"
     
  },
  {
    id: 2,
    detail: "Online Devices",
    bg: '#4D9200',
    shadow: 'rgba(77, 146, 0, 0.15)',
    total: 900,
    devices:"activeDevices"
  },
  {
    id: 3,
    detail: "Idle Devices",
    bg: '#7DE495',
    shadow: 'rgba(125, 228, 149, 0.15)',
    total: 110,
    devices:"idleDevices"
  },
  {
    id: 4,
    detail: "Inactive Devices",
    bg: '#838191',
    shadow: 'rgba(131, 129, 145, 0.15)',
    total: 130,
    devices:"inactiveDevices"
  },
  {
    id: 5,
    detail: "Offline Device",
    bg: '#EC1C24',
    shadow: 'rgba(236, 28, 36, 0.15)',
    total: 50,
    devices:"offlineDevices"
  },
  {
    id: 6,
    detail: "All Users",
    bg: '#0A73D4',
    shadow: 'rgba(10, 115, 212, 0.15)',
    total: 1120,
    devices:"allUsers"
  },
]

const geoData = [
  ["Country", "Popularity"],
  ["Germany", 200],
  ["United States", 300],
  ["Brazil", 400],
  ["Canada", 500],
  ["France", 600],
  ["Nigeria", 700],
]

export const data = [
  ["Task", "Hours per Day"],
  ["Active", 11],
  ["Idle", 2],
  ["Offline", 2],
  ["Inactive", 2],
];
export const options = {
  title: "Device Analysis",
  colors: ['#4D9200', '#7DE495', '#EC1C24', '#E9EDF5']
};


const DashboardSummary= () => {

  const  {dashboardDetails:devices} =useDashboard()

  console.log(devices)

  return (
    <div className="dashboard__cards">
        <div className="card__items">   
          {
            cardDetails.map(item=> <DashboardCard 
                                      detail={item.detail} 
                                      bg={item.bg} 
                                      shadow={item.shadow} 
                                      total={devices[item.devices]}
                                      key={item.id}
                                    />
                            )
          }
        </div>
      <div className="map__chart">
        <div className="react_chart">
                <Chart
                  chartEvents={[
                    {
                      eventName: "select",
                      callback: ({ chartWrapper }) => {
                        const chart = chartWrapper.getChart();
                        const selection = chart.getSelection();
                        if (selection.length === 0) return;
                        const region = geoData[selection[0].row + 1];
                        console.log("Selected : " + region);
                      },
                    },
                  ]}
                  chartType="GeoChart"
                  width="100%"
                  height="400px"
                  data={geoData}
                  // options={dataOptions}
                />
        </div>
        <div className="pie_chart">
          <Chart
                chartType="PieChart"
                data={data}
                options={options}
                width={"90%"}
                height={"400px"}
                className="analysis"
          />
        </div>
      </div>
      <div className="alert__map">
        <div className="alert__item">
            <Alert/>
        </div>
        <div className="map__item">
          <MapContent/>
        </div>
      </div>
    </div>
   
  )
}

export default DashboardSummary