import React from 'react'
import {BsFillCheckCircleFill} from 'react-icons/bs'
import {MdCancel} from 'react-icons/md'
import { MoonLoader,ClipLoader } from 'react-spinners'

type CheckProp = {
    isValid: string
}


const CheckIcon = ({isValid}: CheckProp) => {
  return (
    <>
        {
            isValid ===  "success" &&  <span className='check_icon'>
                                        <BsFillCheckCircleFill/>
                                    </span>
        }

        {
            isValid ===  "failed" &&  <span className='check_icon invalid'>
                                        <MdCancel/>
                                       </span>
        }
        {
            isValid ===  "idle" &&  <span className='check_icon'></span>
        }

        {
            isValid === "loading"  &&   <span className='check_icon'>
                                            <ClipLoader
                                                color={"#4D9200"}
                                                speedMultiplier={1}
                                                className="bar__loader"
                                                //loading={loading}
                                                //cssOverride={override}
                                                size={25}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            />
                                        </span>
        }
    </>
    
  )
}

export default CheckIcon