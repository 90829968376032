import { LeftPositionItem, PositionItem } from '../header/headerStyled'
import { TopWrapper } from '../mapleft/styled';
import TopLeftContent from './TopLeftContent';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import useToggleClass from '../../features/changeclass';


//Left-top data items
const data = [
  {
      id: 0,
      icon: <MapOutlinedIcon className='top-left-icon'/>,
      desc: 'Map',
      link: 'map'
  },
  {
      id: 1,
      icon: <MapOutlinedIcon className='top-left-icon'/>,
      desc: '2D Canvas',
      link: 'canvas2d'
  },
  {
      id: 2,
      icon: <MapOutlinedIcon className='top-left-icon'/>,
      desc: '2D Object',
      link: 'object2d'
  },
  {
      id: 3,
      icon: <BarChartOutlinedIcon className='top-left-icon'/>,
      desc: 'Chart',
      link: 'chart'
  }
]

//Set the state props Type
const TopLeft = () => {
  const {toggleState, changeClass} = useToggleClass('top-item active', 'top-item')
  
  // console.log('outer')
  return (
    <LeftPositionItem 
        top={6} 
        left={2.5}
    >
        <TopWrapper>
          {
            data.map(item=> (
              <TopLeftContent 
                icon={item.icon} 
                desc={item.desc} 
                changeClass={changeClass}
                toggleState={toggleState}
                id={item.id}
                link={item.link}
                key={item.id}
              />
            ))
          }
        </TopWrapper>
    </LeftPositionItem >
  )
}

export default TopLeft