import { Link } from "react-router-dom"

type LeftProps = {
    id: number,
    link: string,
    icon: React.ReactNode,
    desc: string,
    changeClass: (id: number)=> string
    toggleState: (id: number)=> void
}
const TopLeftContent = ({icon, desc, changeClass, id, toggleState, link}: LeftProps) => {
    // console.log('inner')
  return (
    <>
      <Link to={link} className={changeClass(id)} onClick={()=> toggleState(id)}>
       
              <span>
                  {icon}
              </span>
              <p>{desc}</p>
      
      </Link> 
    </>
  )
}

export default TopLeftContent