import { memo, useState, useRef, useEffect } from 'react';
import {RiArrowDownSLine} from 'react-icons/ri'
import ActionPage from './ActionPage';
import { MultiSelectContainer } from './multiStyled';
import { ActionPageType, Data, MultiselectProps } from './typed';
import { useSelectOption } from './useSelectContext';


const data = [
    {
        id: 1,
        title: "Temperature"
    },
    {
        id: 2,
        title: "Speed "
    },
]

const actions = {
    title: "",
    action: "",
    interval: 0,
}

const MultiSelect = ({ options, setOption, label}: MultiselectProps) => {
    const [open, setOpen] = useState(false);
    const {selected, toggleOption} = useSelectOption()
    const  [actionItem, setActionItem] = useState<ActionPageType>(actions)
    const [item, setItem] = useState<number | undefined>(undefined)
    const itemRef = useRef<HTMLInputElement[]>([])
    console.log("outside", actionItem)
    const getTitle = (item: string)=>{
        setActionItem( prev=>{
            let newObj: ActionPageType = {...prev}
            let titleObj = {title: item}
            delete newObj["title"]
            console.log("inside", newObj)
            return {
                ...newObj,
                ...titleObj
            }
        })
    }
   

    const handleAction = (e: React.ChangeEvent<HTMLSelectElement>)=>{
        e.preventDefault()
        const {name, value} = e.target
        setActionItem(prev=>{
            return {
                ...prev,
                [name]: value
            }
        })
    }
    const handleToggleData = ({id, title}: Data)=>{
        toggleOption({title: title })
        setOption(prev=>{
            return {
                ...prev,
                sensors: prev.sensors.filter(d=> d.title !== title)
            }
        })
    } 

    console.log("in the app")

    useEffect(()=>{
        if(actionItem.title !== ""){
            setOption(prev=>{
                let newObj = prev.sensors.filter(d=> d.title !== actionItem.title)
                return  {
                    ...prev,
                    sensors: [...newObj, actionItem]
                }
            })
        }
    },[actionItem.title, setOption, actionItem])
    console.log(selected)
    return (
        <MultiSelectContainer>
            <div className="c-multi-select-dropdown__selected" onClick={()=>setOpen(!open)}>
                <div className="selected__options">
                    {
                        selected.length !== 0 
                        ? selected?.map((d: string) =><span key={d}>{d}</span>) 
                        : <span>{label ? label : "Add a label to the element"}</span>
                    }
                </div>
                <span>
                    <RiArrowDownSLine/>
                </span>
            </div>
            {
                open ? 
                (
                    <ul className="c-multi-select-dropdown__options">
                        {options?.map((option, index )=> {
                            const isSelected = selected.includes(option.title);
                            const handleListItem = (id:string, index: number)=>{
                                if(itemRef.current[index].id === id){
                                    setItem(index)
                                }else{
                                    setItem(undefined)
                                }
                            }
                            const handleShowPage = ()=>{
                                setItem(undefined)
                            }
                            
                            return (
                                <li 
                                    className="c-multi-select-dropdown__option" 
                                    key={index}
                                >
                                    <input 
                                        type="checkbox" 
                                        checked={isSelected} 
                                        className="c-multi-select-dropdown__option-checkbox"   
                                        ref={(ele: HTMLInputElement)=> itemRef.current[index] = ele}
                                        id={option.title}
                                        onClick={
                                            ()=>isSelected 
                                            ? handleToggleData({id: option.id, title: option.title})
                                            : handleListItem(option.title, index)
                                        } 
                                        readOnly
                                    />
                                    <span>{option.title}</span>
                                    {
                                        item === index
                                        ? <ActionPage 
                                            option={option} 
                                            handleItem={handleToggleData}
                                            setActionPage={handleShowPage}
                                            setActionGroup={setOption}
                                            getTitle={getTitle}
                                            handleAction={handleAction}
                                            sensor={actionItem}
                                            key={option.id}
                                            /> 
                                        : null
                                    }
                                </li>
                            )
                        })}
                    </ul>
                )
                : null
            }
            
        </MultiSelectContainer>
    )
}

export default memo(MultiSelect)