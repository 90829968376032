import React from 'react'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import SensorsIcon from '@mui/icons-material/Sensors';
import {HiDotsVertical} from 'react-icons/hi'
import moment from 'moment';

import { SENSORVALUE } from './sensorValue';
import PopUp from '../popItems/PopUp';
import Modal from '../modal/Modal';
import Delete from '../delete/Delete';
import EditDevice from '../modalForm/editDevice/EditDevice';
import PuffLoader from '../loaders/PuffLoader';
import { CenterLoadIndicator, DeviceContainer, MainContent, MainContentContainer } from './device.styled';
import useDeviceContent from './useDeviceContent';



const popItems = [
    {
        desc: "Edit device",
        col: '#464F60'
    },
    {
        desc: "Delete device",
        col: '#D1293D'
    }
]
const DeviceContent = () => {
   

    const { 
        toggleModal, 
        removePop, 
        handleBgColor, 
        handleCheckAll, 
        getItemId, 
        handleTrackingDevice, 
        handlePop, 
        handlePopModal, 
        toggleModalPop, 
        setShow, 
        modalPop, 
        isLoading, 
        error, 
        show, 
        popRef, 
        pop, 
        mainRef, 
        allDevices, 
        botSidebar, 
        popItem, 
        checkItem, 
        itemId } = useDeviceContent();

    if(isLoading){

        return (
            <CenterLoadIndicator>
                <PuffLoader cssClass='edit__loader'/>
            </CenterLoadIndicator>
        )
    }

    if(error){
        return <div>Error loading devices, refresh</div>
    }
    return (
        <>
            <DeviceContainer className="header-content">
                <div className='header-content-items'>
                    <input type='checkbox' onChange={handleCheckAll} value="all"  name="all"/>
                    <span className='inset'>S</span>
                </div>
                <div className='header-content-items'>
                    <div className='header-inner'>
                        <MoreHorizOutlinedIcon/>
                        <span>Name</span>
                    </div>
                </div>
                <div className='header-content-items sensor'>
                    <div className='header-inner'>
                        <SensorsIcon/>
                        <span>Sensor</span>
                    </div>
                </div>
            </DeviceContainer>
            <MainContentContainer>
                {
                    allDevices !== "undefined"
                    ? (
                        allDevices?.map((d:any, index:number) =>{
                            let IconSelect = d?.mainSensorName ? SENSORVALUE[d?.mainSensorName].icon : HiDotsVertical;
                            return (
                                <MainContent 
                                    ref={(ele:HTMLDivElement)=> mainRef.current[index] = ele}
                                    darkTrans={popItem === index ? botSidebar : ""} 
                                    key={d?._id} 
                                    id={d?._id}
                                    onClick={()=>getItemId(index)}
                                    data-item ={index}
                                >
                                    <div className='main-content-inner'>
                                        <input type='checkbox' value={d?._id} name="device" checked={checkItem} onChange={handleTrackingDevice}/>
                                        <span className="inset"></span>
                                    </div>
    
                                    <div className='main-content-inner col2'  onClick={()=> handleBgColor(index, d?._id)}>
                                        <span>{d?.deviceName}</span>
                                        <span>{d?.sensors ? moment(d?.sensorTime).format("DD/MM/YYYY,  HH:MM"): "No data"}</span>
                                    </div>
    
                                    <div className='main-content-inner'>
                                        {
                                            d?.sensors 
                                            ? (
                                                <>
                                                
                                                    <IconSelect className='inner-icon'/>
                                                    <span className='dev__unit'>
                                                        {d?.mainSensorValue}{ d?.mainSensor? SENSORVALUE[d?.mainSensor].unit : "N/A"}
                                                    </span>
                                                </>
                                            ):(
                                                <>
                                                
                                                    <IconSelect className='inner-icon'/>
                                                    <span className='dev__unit'>
                                                      N/A
                                                    </span>
                                                </>
                                            )
                                        }
                                        
                                    </div>
                                    <div 
                                        ref={(ele: HTMLDivElement)=>popRef.current[index] = ele} //Do not mutate this element with push method
                                        className='main-content-inner' 
                                        onClick={(e:React.MouseEvent<HTMLDivElement>)=>handlePop(index, d?._id)}
                                        //onMouseLeave={removePop} 
                                        id={d?._id}
                                    >
                                        {
                                            pop === index 
                                            ? <PopUp items={popItems} setPop={removePop} pop={pop}/> 
                                            : <HiDotsVertical className='main-icon'/>
                                        } 
                                    </div>   
                                </MainContent>
                            )
                        })
                    )
                    :<div>No device is registered yet</div>
                    
                }

                {
                    modalPop !== "" && 
                        <Modal 
                            open={show} 
                            toggle={toggleModalPop} 
                            setOpen={setShow} 
                            title={modalPop} 
                            hideScroll='false'
                            //newBg={true}
                        >
                            {
                                modalPop === "Delete device" && <Delete 
                                                                    item={allDevices[itemId]} 
                                                                    toggleBack={()=> setShow(prev=>!prev)}  
                                                                    delType='devices'
                                                                />
                            }
                            {
                                modalPop ==="Edit device" && <EditDevice item={allDevices[itemId]} toggleModal={()=> setShow(prev=>!prev)}/>
                            }
    
                        </Modal>
                            
                }
            </MainContentContainer>
        </>
        
    )
}

export default DeviceContent