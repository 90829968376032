import React from 'react'
import JSXMarker from './CustomMarker'
import { MdOutlineThermostat } from 'react-icons/md'
import { posix } from 'path'
import { usePOIcon } from '../selectRef/hook/useGeofenceContext'
import { useMapEvents } from 'react-leaflet'
type LatLng = {
    pos: {
        lat: number,
        lng: number
    },

    toolTip?: boolean,
    showPop?: boolean 
}
const POIMarker = ({pos, toolTip, showPop}:LatLng) => {
    //const latlng = pos !== null ? pos : [0, 0];
    const {icn, setIcon}= usePOIcon()
    const IconSelect = icn?.icon

    console.log("insidepoi",pos)
  return (

    <JSXMarker
        position={pos}
        toolTip={toolTip}
        showPop={showPop}
        iconOptions={{
        className: "jsx__marker",
        iconSize: [60, 60],
        iconAnchor: [50, 50]
        }} 
    >
        {
            IconSelect
            ?  <span className="span_icon"><IconSelect/></span>
            : <span className="span_icon"><MdOutlineThermostat/></span>
        }
    </JSXMarker>
  )
}

export default POIMarker