import React,{useState, useEffect} from 'react'
import validateSignUp, { UserError } from './validateSignUp';
import { client } from '../../utils/axios-instance';
import { useNavigate } from 'react-router-dom';


const useSignUp = (url:string) => {
   
    const [errors, setErrors] = useState<UserError>({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [userState,setUserState] = useState("idle");
    const [serverErr, setServerErr] = useState("")
    const navigate = useNavigate();
    //const {setState} = useLoginState();
    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone:"",
        password: ""
    })
  
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value
      });
    };
        
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setErrors(validateSignUp(values));
      setIsSubmitting(true);
    };
  
    console.log("values", values)
    useEffect(
      () => { 
        const createUser = async ()=>{
          let request = client()
          try {
              await request.post(url, values).then((res)=>{
                console.log("response::", res.data)
                setUserState("success")
                localStorage.setItem('v-token', JSON.stringify(res.data?.data?.token))
                localStorage.setItem('v-mail', JSON.stringify(values.email));
                navigate("/verify-user")
              }).catch((err)=>{
                setUserState("failed")
                setServerErr(err.response?.data?.message)
              })
          
          } catch (err) {
            console.log(err)
          }
        }
  
        if((Object.keys(errors).length === 0) && (Object.keys(values).length >= 5) && (isSubmitting)){
            setUserState("loading")
            setValues({
                firstName: "",
                lastName: "",
                email: "",
                phone:"",
                password: ""
            })
            setIsSubmitting(false)
            setTimeout(()=>{
             createUser();
            }, 500)
          
        }else{
          setIsSubmitting(false)
        }
  
        
      },
      [errors, isSubmitting, values,url, navigate]
    );


    return   {userState, errors, serverErr, values, handleChange, handleSubmit}
}

export default useSignUp