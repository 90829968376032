import React from 'react'
import { NewContainer } from './modal.styled'

type MProp ={
    title:string
}
const NewModalHeader = ({title}: MProp) => {
  return (
   <NewContainer>
        <div className='modal-title'>
          {title}
        </div>
   </NewContainer>
  )
}

export default NewModalHeader