import React,{CSSProperties} from 'react'
import Puff from "react-spinners/PuffLoader";
import BarLoader from 'react-spinners/BarLoader'

//import { CSSProperties } from 'styled-components';


type PuffProp = {
    cssClass?: string
}
const PuffLoader = ({cssClass}: PuffProp) => {
  return (
    <div className={cssClass}>
         <Puff
        color={"green"}
        //loading={loading}
        //cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
    />
    </div>
   
  )
}

export const ProgressLoader = () => {
  return (
    <div className={"bar__loader"}>
         <BarLoader
            color={"#4D9200"}
            height={10}
            width={200}
            speedMultiplier={1}
            className="bar__loader"
            //loading={loading}
            //cssOverride={override}
            //size={250}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
    </div>
   
  )
}

export default PuffLoader