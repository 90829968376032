import React from 'react'
import {MdOutlineSignalCellularAlt, MdSpeed} from 'react-icons/md'
import {BiGasPump} from 'react-icons/bi'
import { GiSpeedometer} from 'react-icons/gi'
import {BsThermometerHigh} from 'react-icons/bs'
import {WiHumidity} from 'react-icons/wi'
import { SENSORVALUE } from '../device/sensorValue'
import { HiDotsVertical } from 'react-icons/hi'

type SensorItemProp = {
    item: any
}
const SensorItem = ({item}:SensorItemProp) => {
    console.log("senso ite", item)
    const IconSelect = item?.mainSensor ?  SENSORVALUE[item.mainSensor].icon : SENSORVALUE[item?.mainSensorName].icon
  return (
   <>
        <div className="bottom-detail">
            <div className="bottom-detail-left">
                <IconSelect/>
                {
                    item?.mainSensor 
                    ? (
                        <span className='text-2'>
                            {item.mainSensor}: {item.mainSensorValue}{SENSORVALUE[item.mainSensor].unit}
                        </span>
                    ):(
                        <span className='text-2'>
                            {item?.mainSensorName}: "N/A"
                        </span>
                    )
                }
               
            </div>
        </div>
        {
            item?.sensorsEnabled.map((d:any)=>{
                const SelIcon = d.name && SENSORVALUE[d?.name].icon
                console.log("enbalesens",d)
                return(
                    <div className="bottom-detail" key={d?._id}>
                        <div className="bottom-detail-left">
                            <SelIcon/>
                            <span className='text-2'>{d?.name}: 100%</span>
                        </div>
                    </div>
                )
            })
        }
        {/* <div className="bottom-detail">
            <div className="bottom-detail-left">
                <BiGasPump/>
                <span className='text-2'>Mileage: 100%</span>
            </div>
        </div>
        <div className="bottom-detail">
            <div className="bottom-detail-left">
                <GiSpeedometer/>
                <span className='text-2'>Fuel Level: 100%</span>
            </div>
        </div>
        <div className="bottom-detail">
            <div className="bottom-detail-left">
                <BsThermometerHigh/>
                <span className='text-2'>Temperature: 100%</span>
            </div>
        </div>
        <div className="bottom-detail">
            <div className="bottom-detail-left">
                <MdOutlineSignalCellularAlt />
                <span className='text-2'>Altitude: 100%</span>
            </div>
        </div>
        <div className="bottom-detail">
            <div className="bottom-detail-left">
                <WiHumidity/>
                <span className='text-2'>Humidity: 100%</span>
            </div>
        </div>
        <div className="bottom-detail">
            <div className="bottom-detail-left">
                <MdSpeed/>
                <span className='text-2'>Engine Speed: 100%</span>
            </div>
        </div>
        <div className="bottom-detail">
            <div className="bottom-detail-left">
                <MdOutlineSignalCellularAlt />
                <span className='text-2'>Signal Strength: 100%</span>
            </div>
        </div>
        <div className="bottom-detail">
            <div className="bottom-detail-left">
                <MdOutlineSignalCellularAlt />
                <span className='text-2'>Signal Strength: 100%</span>
            </div>
        </div>
        <div className="bottom-detail">
            <div className="bottom-detail-left">
                <MdOutlineSignalCellularAlt />
                <span className='text-2'>Signal Strength: 100%</span>
            </div>
        </div> */}
   </>
  )
}

export default SensorItem