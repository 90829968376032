export const getId = async (id: string, data: [])=>{
   let newId:any;
   if(id && data){
    newId = data.filter((d:any)=> d._id === id)
        return newId[0]._id
   }else{
    return;
   }
}

export const getmodelId = async (id: string, data: [])=>{
   let newId:any;
   if(id && data){
      newId = data.filter((d:any)=> d._id === id)
      return newId[0]._id
   }else{
    return;
   }
}