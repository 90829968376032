import Card from '../../components/card/Card'
import Layout from '../../components/layout/Layout'
import  Header from '../../components/header/Header'
import {MainSection, Left, Right } from '../login/loginStyled'
import useLang from '../../language/useLang'
import { useAppSelector } from '../../reducers/disPatchSelector'
import { Navigate } from 'react-router-dom'
import LeftItem from '../login/LeftItem'
import OtpForm from './OtpForm'

const EmailVerification = () => {
    const {t, handleLanguage} = useLang();

    const auth = useAppSelector(state=> state.userReducer)
    console.log("auth", auth.currentUser?.verifications)

    return (
      <Layout>
         <Header handleLanguage={handleLanguage}/>
         <MainSection>
           <Left>
            <LeftItem/>
           </Left>
           <Right>
            <Card>
             <OtpForm/>
            </Card>
           </Right>
         </MainSection>
      </Layout>
    )
}

export default EmailVerification