import React, { useState } from 'react'
import { AuthContainer, LeftItemHeader, Logo, List, HeaderPositionItem, SettingContainer } from './headerStyled'
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {VscActivateBreakpoints} from 'react-icons/vsc'
import Lang from '../lang/Lang';
import useLang from '../../language/useLang';
import {BiChevronDown} from 'react-icons/bi'
import {FiUser} from 'react-icons/fi'
import {BsGrid} from 'react-icons/bs'
import HoverHeader from './HoverHeader';
import useToggleClass from '../../features/changeclass';
import {Link} from 'react-router-dom'
import UserSetting from '../userSetting/UserSetting';
import Modal from '../modal/Modal';
import GridLink from '../gridLink/GridLink';

const AuthHeader = () => {
  const {handleLanguage} = useLang();
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [linkItem, setLinkItem] = useState(false)
  const {toggleState, changeClass} = useToggleClass('left-list-item active', 'left-list-item')

  const HandleLinkItem = ()=>{
    setLinkItem(!linkItem)
    toggleState(3)
  }

  const toggleClick = (e: React.MouseEvent<HTMLLIElement>)=>{
    e.preventDefault();
    toggleState(2);
    setShow(prev=> !prev)
  }

  const toggleUser = ()=>{
    setOpen(!open)
  }
  return (
    <HeaderPositionItem
        top={0}
        wid="100%"
        >
        <AuthContainer >
            <Logo>
                <img src={process.env.PUBLIC_URL+"/assets/images/gentro.png"} alt="Gentro Logo"/>
            </Logo>
            <LeftItemHeader className='left'>
                <List>
                    <li onClick={()=>toggleState(0)}>
                        <Link to={"/dashboard"}  className={changeClass(0)}>
                            <WysiwygOutlinedIcon className='list-icon'/>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li  onClick={()=>toggleState(1)}>
                        <Link to="map-items" className={changeClass(1)}>
                            <VscActivateBreakpoints className='list-icon'/>
                            <span>Monitor</span>
                        </Link>
                    </li>
                    <li onClick={toggleClick}>
                        <div className={changeClass(2)} >
                            <ConstructionOutlinedIcon className='list-icon'/>
                            <div>
                                <span>Tools</span>
                                <BiChevronDown className='list-icon right'/>
                            </div>
                        </div>
                        
                        { show &&  <HoverHeader setShow={setShow}/>}
                    </li>
                </List>
                <List>
                    <li className="setting" onClick={HandleLinkItem}>
                        <div className={changeClass(3)}>
                            <BsGrid className='list-icon'/>
                        </div>
                        {
                            linkItem ? <GridLink setLink={HandleLinkItem}/> : null
                        }
                      
                    </li>
                    <li className="setting" onClick={()=> setOpen(!open)}>
                        <div>
                            <FiUser className='list-icon'/>
                        </div>
                    </li>
                    <li>
                        <div>
                            <Lang handleLanguage={handleLanguage}/>
                        </div>
                    </li>
                </List>
            </LeftItemHeader>
            {
                open &&  <SettingContainer>
                            <UserSetting setOpen={setOpen} open={open}/>
                         </SettingContainer>
            }
        </AuthContainer>
    </HeaderPositionItem>
  )
}

export default AuthHeader