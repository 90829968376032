import React, { useState } from "react";


const useToggle = ()=>{
    const [open, setOpen] = useState(false);

    const toggle = (event: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
        event.preventDefault()
        setOpen((prev)=> !prev)
    }

    return {
        open,
        toggle,
        setOpen
    }
}

export default useToggle