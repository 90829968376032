import styled from 'styled-components/macro'

type Props = {
    bg: string,
    shadow: string
}
export const DashboardContainer = styled.div<Props>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* gap: 10px; */
    width: 170px;
    height: 70px;
    background: #fff;
    border-radius: 6px;
    padding: 8px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08), 
             0px 15px 35px -5px rgba(17, 24, 38, 0.15), 
             0px 0px 0px 1px rgba(152, 161, 178, 0.1);
    cursor: pointer;
    
    .desc__icon{
        display: grid;
        place-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: ${({bg})=> bg && bg};
        box-shadow: 0 0 0 5px ${({shadow})=> shadow && shadow}, 
                    0 0 0 2px  ${({shadow})=> shadow && shadow};
    }
    //rgba(236, 28, 36, 0.15)
    .desc__icon .icon{
        font-size: 1.3rem;
        color: #fff;
    }

    .desc__details{
        display: flex;
        flex-direction: column;
    }

    .desc__details span:nth-child(1){
        font-family: 'Roboto';
        /* font-style: normal; */
        font-weight: 500;
        font-size: .875rem;
        line-height: 20px;
        color: #838191;
       
    }
    .desc__details span:nth-child(2){
        font-weight: 600;
        font-size: 1rem;
        line-height: 20px;
        color: #505050;
    }

`