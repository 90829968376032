import React from 'react'
import { useNavigate } from 'react-router-dom'
import { HoverContainer } from './headerStyled'

type HoverProps = {
    setShow:  React.Dispatch<React.SetStateAction<boolean>>
}

const HoverHeader = ({setShow}: HoverProps) => {
    console.log('hover')
    const navigate = useNavigate()
  return (
    <HoverContainer onMouseLeave={()=> setShow(false)}>
        <ul className="hover-list">
            <li className='hover-item' onClick={()=> navigate('/dashboard/reports')}>Report</li>
            <li className='hover-item'>Alert</li>
            <li className='hover-item'>Calibration Management</li>
            <li className='hover-item'>Geofence</li>
            <li className='hover-item' onClick={()=> navigate('/dashboard/canvas')}>2D Canvas</li>
            <li className='hover-item' onClick={()=> navigate('/dashboard/devices')}>Devices</li>
            {/* <li className='hover-item'>Point Of interest</li> */}
            <li className='hover-item'>Manage Users</li>
            {/* <li className='hover-item'>Show Points</li>
            <li className='hover-item'>Show Address</li> */}
        </ul>
    </HoverContainer>
  )
}

export default HoverHeader