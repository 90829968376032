import { AccordionContainer } from './accordion.styled'
import OuterItem from './OuterItem'
import useAccordion from './useAccordion'
import AccordionTopItem from './AccordionTopItem'
import { usePopModal } from '../selectRef/hook/useGeofenceContext'
import Modal from '../modal/Modal'
import EditGroup from '../modalForm/editGroup/EditGroup'
import Delete from '../delete/Delete'

export const popItems = [
    {
        desc: "Edit group",
        col: '#0A73D4'
    },
    // {
    //     desc: "Remove group",
    //     col: '#FF7B01'
    // },
    {
        desc: "Delete group",
        col: '#D1293D'
    }
]

type GroupItem = {
    group: any
    index: number
}
const AccordionItem = ({group, index}: GroupItem) => {
  /**************************************Accordion Hooks Items**************************************/
    const {
        handleCheckAll, 
        handleCheckInner, 
        handleCheckOuter,
        toggleInner, 
        toggleItem, 
        setPop,
        handlePopIndex, 
        pop, 
        checkInner, 
        checkOuter, 
        active, 
        innerActive,
        isCheckedAll,  
        outer, 
        ulRef, 
        inner,
    } = useAccordion();
   
    return (
        <AccordionContainer>
         {/**************************************AccordionTop Layer Item Header**************************************/}
            
            
            <AccordionTopItem
                handleCheckAll={handleCheckAll}
                toggleItem={toggleItem}
                handlePopIndex={handlePopIndex}
                setPop={()=>setPop(undefined)}
                pop={pop}
                index={index}
                popItems={popItems}
                typeItem={""}
                name={group?.name}
            />

        {/**************************************Accordion Inner Layer Item**********************************/}
            <ul className={`accordion__item ${active ? 'active': ''} `} ref={ulRef}>
                <OuterItem 
                    toggleInner={toggleInner}
                    handleCheckOuter={handleCheckOuter}
                    innerActive={innerActive}
                    outerRef={(ele: HTMLInputElement)=> Array.isArray(outer.current) ? outer.current[0] = ele : outer.current }
                    checkOuter={checkOuter}
                    checkInner={checkInner}
                    isCheckAll={isCheckedAll}
                    group={group}
                    device={group.deviceName}
                />
            </ul>
        </AccordionContainer>
    )
}

export default AccordionItem