export type ValueProps = {
    email: string,
    password: string
}

export type Error ={
  [s:string]: string
}

export default function validateLogin(values: ValueProps): Error {
  let errors: Error = {};
  
    if (!values.email) {
      errors.email = 'Email required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Email address is invalid';
    }
    
    if (!values.password) {
      errors.password = 'Password is required';
    } else if (values?.password.length < 7) {
      errors.password = 'password must be of 7 characters and above';
    }

    return errors;
}
    