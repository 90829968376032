import React, {useState, useEffect} from 'react'
import { client } from '../../../utils/axios-instance';
import _, { sum } from 'lodash';
import { EditDeviceType } from './EditDevice';
import { useMutation, useQueryClient} from 'react-query';
import useMutateData from './useMutateData';
import { DevicesData } from '../newDevice/deviceTyped';
import { compareData } from './compareData';

export type EditNewDevice = Omit<EditDeviceType, "deviceType"| "deviceModel" | "imei">
type Submit = (data:EditNewDevice)=> Promise<unknown>
const useEditForm = (data: EditNewDevice , id: string,  url: string, toggleModel:()=>void, oldData: any) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const queryClient = useQueryClient()
   
    const [state, setState] = useState("");

    const submitData = async (newDevice: typeof data)=>{
      let token = JSON.parse(localStorage.getItem("access_token")!)
      let request = client(token)
      let editedData = await compareData(newDevice, oldData)
      console.log("edited", editedData)
      try{
        console.log("inside request")
        const res = await request.patch(url + "/" + id, editedData)
        console.log('mutda', res.data)
        if(res.data !== null) {
          //setMsg(res.data.message)
          setState("success")
          setTimeout(()=>{
            toggleModel()
          }, 1000)
          return res?.data
        }
      }catch(err){
        console.log("err insed call");
        setState("failed");
        // await queryClient.setQueryData("devices", (oldData:any)=>{
        //   console.log("err", oldData)
        //     return {
        //       ...oldData,
        //       data:{
        //         data: [...oldData?.data.data]
        //       }
        //     }
        //   });
      }
  }
  
  // Update the date from server response before database update
  const {mutate} = useMutateData<Submit, EditNewDevice>(submitData, id)
   
  //console.log("is submit", isSubmitting)
  const handleSubmit = async (e: React.FormEvent)=>{
      e.preventDefault();
      let editedData = await compareData(data, oldData)
      console.log("efited sub", editedData)
      if(!(_.isEmpty(editedData))){
        setIsSubmitting(true)
        setState("loading") 
      }
     
  }

   

  //Send Response and mutate the available data from the server response
  useEffect(()=>{
    //console.log("is submitting", isSubmitting)
      
    if(isSubmitting){
      setIsSubmitting(false)
      setTimeout(()=>{
        mutate(data, {
          onSuccess(data, variables, context) {
            // console.log("inside mutate", variables)
            // console.log("context mutate", data)
          },
        })
      }, 500)
        
    }else{
        setIsSubmitting(false)
    }
      
  },[isSubmitting, mutate, data]);


  return {handleSubmit, state, isSubmitting}
}

export default useEditForm