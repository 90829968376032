import React, {memo, useCallback} from 'react'
import { DashboardContainer } from './styled'
import {VscInbox} from 'react-icons/vsc'
import { createSearchParams, useNavigate } from 'react-router-dom'

type CardProps = {
    detail: string,
    bg: string,
    shadow: string,
    total: number
} 
const DashboardCard = ({detail, bg, shadow, total}: CardProps) => {
 const navigate = useNavigate()

 //Create a search object and navigate programmatically
 const openUrl = ()=>{
  navigate({
    pathname: "/dashboard/devices",
    search: createSearchParams({
      id: detail
    }).toString()
  })
 }

  return (
    <DashboardContainer bg={bg} shadow={shadow} onClick={openUrl}>
        <div className="desc__icon">
            <VscInbox className="icon"/>
        </div>
        <div className="desc__details">
          <span>
              {detail}
          </span>
          <span>
              {total || 0 }
          </span>
        </div>
    </DashboardContainer>
  )
}

export default DashboardCard