import React,{useState} from 'react'
import { ContentContainer } from './sidebar.styled'
import DeviceContent from '../device/DeviceContent';
import Modal from '../modal/Modal';
import NewDevice from '../modalForm/newDevice/NewDevice';
import useToggle from '../../features/toggle';
import useModal from '../selectRef/hook/useModal';

export type ModalProps ={
  open: boolean,
  toggle: React.MouseEventHandler<HTMLDivElement>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  title?: string
}


const SidebarContent = ({open, toggle, setOpen, title}: ModalProps) => {
  const handleToggle = ()=>{
    setOpen(!open)
  }
  return (
    <ContentContainer>
        <DeviceContent/>
        <Modal 
            open={open} 
            toggle={toggle} 
            setOpen={setOpen} 
            title="Create New Device" 
            hideScroll='false'
          >
          <NewDevice handleToggle={handleToggle}/>
        </Modal>
    </ContentContainer>
  )
}

export default SidebarContent