import styled from "styled-components";

export const Form = styled.form`
    padding-top: 1rem;
    height: 12rem;
    width: 80%;
    text-align: center;
    position: relative;

    #input-file-upload {
        display: none;
    }

    #label-file-upload {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-width: 2px;
        border-radius: 8px;
        border-style: dashed;
        border-color: #EBEFF2;
        background-color: #fff;
    }

    #label-file-upload.drag-active {
        background-color: #ffffff;
    }

    .drag__text{
        font-size: 1rem;
        font-family: 'Roboto';
        font-weight: 500;
        color: rgba(195, 194, 201, 1);
    }

    .drag__file{
        display: inline-flex;
        color: #C3C2C9;
    }

    .drag__file .file_icon{
        font-size: 5rem;
    }

    .upload-button {
        cursor: pointer;
        padding: 0.25rem;
        border: none;
        background-color: transparent;
        color: rgba(77, 146, 0, 1);
    }

    .upload-button:hover {
        text-decoration-line: underline;
    }

    #drag-file-element {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        //background: red;
    }

    .desc__canvas{
        display: flex;
        gap: 10px;
        padding-top: 1.5rem;
    }

    .desc__canvas .form__item{
        width: 30rem;
        height: 2.8rem;
    }
` 

export const DragStyled = styled.div`
    width: 100%;
    //padding: 2rem 0;
    #input-file-upload {
        display: none;
    }

    #label-file-upload {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-width: 2px;
        border-radius: 8px;
        border-style: dashed;
        border-color: #EBEFF2;
        background-color: #fff;
        padding: 1rem 0;
    }

    #label-file-upload.drag-active {
        background-color: #ffffff;
    }

    .drag__item{
        display: grid;
        place-items: center;
    }
    .drag__item p{
        font-size: 14px;
        color: #505050;
    }
    .drag__item .drag_support{
        padding-bottom: 1rem;
        font-size: 12px;
    }

    /* .drag__text{
        font-family: 'Roboto';
        font-weight: 500;
    } */

    .drag__file{
        display: inline-flex;
        color: #C3C2C9;
    }

    .drag__file .file_icon{
        font-size: 2rem;
    }

    .upload-button {
        cursor: pointer;
        padding: 0.25rem;
        border: none;
        background-color: transparent;
        color: rgba(77, 146, 0, 1);
    }

    .upload-button:hover {
        text-decoration-line: underline;
    }

    #drag-file-element {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        //background: red;
    }

` 