import React, { 
    useState, 
    createContext, 
    SetStateAction, 
    useContext, 
    useReducer 
} from "react";
import { IconType } from "react-icons/lib";
import { QueryClient, useQueryClient } from "react-query";

import geofenceReducer, { InitialState, ActionType } from '../../../features/geofenceReducer'

/***********************GEOFENCE CONTEXT***********************************************/
type GeoContext = {
    geo: boolean 
}

type SetGeoContxt = {
    setGeo : React.Dispatch<SetStateAction<boolean>>
}

type GeofenceCtxs = GeoContext & SetGeoContxt
type CtxProps = {
    children: React.ReactNode
}


/* Creating a context with a type of `GeofenceCtxs | undefined` and setting the default value to
`undefined` */
const GeofenceCtx = createContext<GeofenceCtxs | undefined>(undefined)

const initialState: InitialState = {
    showGeo: false,
    componentType: ""
}

type NewAction = {
    dispatch: React.Dispatch<ActionType>
}


//Geofence user context
type NewCtx = InitialState & NewAction
const CtxNew = createContext<NewCtx | undefined>(undefined);

//Draw map context
type ShapeType = {
    shape: string,
}

type ShapeString = {
    setShape : React.Dispatch<SetStateAction<string>>
}

/***********************MapItem TopRight Items CONTEXT***********************************************/

type MapItem = {
    showItem?: boolean,
    comp: string
}

type MapItemAction = {
    setShowItem: React.Dispatch<SetStateAction<MapItem>>
}

const mapItems: MapItem = {
    showItem: false,
    comp: ""
}


type MapItemType = MapItem & MapItemAction

const MapItemCtx = createContext<MapItemType>( {} as MapItemType)

type MapType = ShapeType & ShapeString
const MapCtx = createContext<MapType | undefined>(undefined)

/***********************POI CONTEXT***********************************************/
type IconProp = {
    icon: IconType | null
}

type POIProps = {
    icn: IconProp
    setIcon :  React.Dispatch<SetStateAction<IconProp>>
}

const POICtx = createContext<POIProps>({} as POIProps)

/***********************Login CONTEXT***********************************************/
// type Login = {
//     state: "idle" | "loading" | "loading" | "success"
// }

type LoginFunc = {
    state: string
    setState: React.Dispatch<React.SetStateAction<string>>
}

const LoginCtx = createContext<LoginFunc>({} as LoginFunc)

/***********************POI Display CONTEXT***********************************************/
type DisplayPoi = {
    poi: boolean,
    showPoi: React.Dispatch<React.SetStateAction<boolean>>
}

const PoiDisplayCtx = createContext<DisplayPoi>({} as DisplayPoi)

/***********************POI FORM CONTEXT***********************************************/
export type POIData = {
    description: string,
    label: string,
    lat: number,
    lon: number,
    iconTag: string,
    customIcon: string
}

export const pois: POIData = {
    description: "",
    label: "",
    lat: 0,
    lon: 0,
    iconTag: "",
    customIcon: ""
}

type POICtxAction = {
    poiData: POIData,
    setPoiData: React.Dispatch<React.SetStateAction<POIData>>
}

export const POIFormCtx = createContext<POICtxAction>({} as POICtxAction)

/***********************SET DEVICE FORM CONTEXT***********************************************/
export type DevicesData = {
    sensorsEnabled: string[],
    users: string[],
    user: string,
    deviceName: string,
    deviceModel: string,
    deviceType: string,
    phone: string,
    imei: string,
    simiccid: string
    icon: string
 }
type SetDeviceTyped =   {
    devices: DevicesData,
    setDevices: React.Dispatch<React.SetStateAction<DevicesData>>
} 

const SetDeviceCtx = createContext<SetDeviceTyped>({} as SetDeviceTyped)
export const devicesData = {
    sensorsEnabled: [],
    users: [],
    user: "63a2d074dcf4ecf313fb9e41",
    deviceName: "",
    deviceModel: "",
    deviceType: "",
    phone: "",
    imei: "",
    simiccid: "",
    icon: ""
}


/***********************Device Tracking FORM CONTEXT***********************************************/

//Get ans set all the device id
type TrackingFn = {
    deviceId: string[],
    setDeviceId: React.Dispatch<React.SetStateAction<string[]>>
}

// Get and display all device
type ActiveDevice = {
    activeDevice: Array<any>,
    setActiveDevice: React.Dispatch<React.SetStateAction<Array<any>>>
}

type DevicePanel = {
    showPanel: boolean,
    deviceSummary: any
}
type MapBottomPanel ={
    panel: DevicePanel,
    setPanel: React.Dispatch<React.SetStateAction<DevicePanel>>
} 

const MapBottomCtx = createContext<MapBottomPanel>({} as MapBottomPanel)
const DeviceTrackingCtx = createContext<TrackingFn>({} as TrackingFn)
const ActiveDeviceCtx = createContext<ActiveDevice>({} as ActiveDevice)

/***********************POPUP Modal context***********************************************/
type PopModal ={
    modalPop: string,
    setModalPop: React.Dispatch<React.SetStateAction<string>>
} 

type ToggleModal = {
    show: boolean,
    setShow:React.Dispatch<React.SetStateAction<boolean>>
}

const PopModalCtx = createContext<PopModal>({} as PopModal)
const ToggleModalCtx = createContext<ToggleModal>({} as ToggleModal)  



export const GeoProvider = ({children}: CtxProps)=>{
    //Geofence state
    const [geo, setGeo] = useState(true)

    //User state with actions
    //const [state, dispatch] = useReducer(geofenceReducer, initialState );
    const [showItems, setShowItem] = useState(mapItems)

    // Login state Action
    const [state, setState] = useState("idle")

    //Map state 
    const [shape, setShape] = useState("");

    //POI Icon State
    const item:IconProp={
        icon: null
    }
    const [icn, setIcon] = useState(item)

    //Display POI
    const [poi, showPoi] = useState(false)

    //POI Form data
    const [poiData, setPoiData] = useState<POIData>(pois)

    //SetDevice Form Data
    const [devices, setDevices] = useState<DevicesData>(devicesData)

    //Tracking Device State 
    const [deviceId, setDeviceId] = useState<string[]>([])
    const [activeDevice, setActiveDevice] = useState<any | []>([])

    //Map Bottom Device Panel
    const panelData = {
        showPanel: false,
        deviceSummary: {}
    }
    const [panel, setPanel] = useState<DevicePanel>(panelData)

    //Pop Modal state and Toggle Modal
    const [modalPop, setModalPop] = useState("")
    const [show, setShow] = useState(true)
    

    
    return (
        <GeofenceCtx.Provider value={{geo, setGeo}}>
            <MapItemCtx.Provider value={{...showItems, setShowItem}}>
                <MapCtx.Provider value={{shape, setShape}}>
                    <POICtx.Provider value={{icn, setIcon}}>
                        <LoginCtx.Provider value={{state, setState}}>
                            <PoiDisplayCtx.Provider value={{poi, showPoi}}>
                                <POIFormCtx.Provider value={{poiData, setPoiData}}>
                                    <SetDeviceCtx.Provider value={{devices, setDevices}}>
                                        <DeviceTrackingCtx.Provider value={{deviceId, setDeviceId}}>
                                            <ActiveDeviceCtx.Provider value={{activeDevice, setActiveDevice}}>
                                                <PopModalCtx.Provider value={{modalPop, setModalPop}}>
                                                    <ToggleModalCtx.Provider value={{show, setShow}}>
                                                        <MapBottomCtx.Provider value={{panel, setPanel}}>
                                                            {children}  
                                                        </MapBottomCtx.Provider>
                                                    </ToggleModalCtx.Provider>
                                                </PopModalCtx.Provider>
                                            </ActiveDeviceCtx.Provider>                                           
                                        </DeviceTrackingCtx.Provider>
                                    </SetDeviceCtx.Provider>
                                </POIFormCtx.Provider>
                            </PoiDisplayCtx.Provider>
                        </LoginCtx.Provider>
                    </POICtx.Provider>
                </MapCtx.Provider>
            </MapItemCtx.Provider>
        </GeofenceCtx.Provider>
    );
}



// export const useGeoUser = ()=>{
//     const {dispatch, ...state} = useContext(CtxNew) as NewCtx;
    
//     if(state === null){
//         throw new Error("state is empty")
//     }
//     return {dispatch, ...state}; //Destructring object directly in array required adding new object in array
// }

export const useNewGeoUser = ()=>{
    const {setShowItem, ...showItems} = useContext(MapItemCtx) as MapItemType
    if(showItems === null){
        throw new Error("state is empty")
    }
    return {setShowItem, ...showItems};
}

/**
 * This function is a hook that returns the geofence context and the setGeofence function from the
 * context provider.
 * @returns The geo and setGeo are being returned.
 */
export const useGeofence = ()=>{
    const {geo, setGeo} = useContext(GeofenceCtx) as GeofenceCtxs;
    if(!setGeo){
        throw new Error("The Provider is missing")
    }
    
    return [geo, setGeo];
}

export const useGeofenceShape = ()=>{
    const {shape, setShape} = useContext(MapCtx) as MapType;

    return {shape, setShape};
}

export const usePOIcon = ()=>{
    const {icn, setIcon} = useContext(POICtx)

    return {icn, setIcon}
}

export const useLoginState = ()=>{
    const {state, setState} = useContext(LoginCtx)
    return {state, setState};
}

export const usePoiDisplay = ()=>{
    const {poi, showPoi} = useContext(PoiDisplayCtx)
    const handlePoiDisplay = (arg: boolean)=>{
       showPoi(arg)
    }

    return {poi, handlePoiDisplay};
}

//Use Set Device Form
export const useSetDevice = ()=>{
    const {devices, setDevices}= useContext(SetDeviceCtx)
   
    return {devices, setDevices}
}

//Use Modal Pop func
export const usePopModal = ()=>{
    const {modalPop, setModalPop} = useContext(PopModalCtx)
    const {show, setShow} = useContext(ToggleModalCtx)
    const toggleModal = (event: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
        event.preventDefault();
        setShow((prev)=> !prev);
    }
    
    const handlePopModal = ()=>{
        setShow(!show)
    }
    return { modalPop, setModalPop,  setShow, show, toggleModal, handlePopModal}
}

//Use Tracking Device state
export const useDeviceTracking = ()=>{
    const {deviceId, setDeviceId} = useContext(DeviceTrackingCtx);
    const {activeDevice, setActiveDevice} = useContext(ActiveDeviceCtx);
    const {panel, setPanel} = useContext(MapBottomCtx);

    //const [activeDevice, setActiveDevice] = useState<any>([])

    const devices:any = useQueryClient().getQueryData("devices")
    //console.log("cache Dev", devices?.data?.data)
    console.log("active", activeDevice)
    console.log("active1", deviceId)

    const getTrackingPanel = (id:string)=>{
        let item = devices?.data?.data.find((item:any)=> item._id === id)
        setPanel({
            showPanel: true,
            deviceSummary: item
        })
    }
    const handleTrackingDevice = (e: React.ChangeEvent<HTMLInputElement>)=>{
        let id = e.target.value
        setDeviceId((prevSelected: string[]) => {

            if(id === "all") return [];
            // if it's in, remove
            const newArray: string[] = [...prevSelected]
            if (newArray.includes(id)) {
                return newArray.filter((item:string) => item !== id)
                // else, add
            } else {
                newArray.push(id)
                return newArray;
            }
        })

        if(deviceId && deviceId.includes(e.target.value) && !(e.target.checked)){
            setActiveDevice((prev:any)=>{
                return prev?.filter((d:any) => d._id !== id)
            })
        }else{
            let newItem = devices?.data?.data.filter((d:any) => d._id === id)
            setActiveDevice(prev=>{
               return  [...prev, ...newItem]
            }) 
        }

        if(e.target.value === 'all'){
            let newItem = devices?.data?.data
            setActiveDevice(prev=>{
                if(e.target.checked){
                    return newItem
                }else{
                    return []
                }
            })
        }

    }

    
    return {activeDevice,  setActiveDevice, handleTrackingDevice, getTrackingPanel, panel}
}