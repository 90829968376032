import {useState, useEffect} from 'react' 
import axios from 'axios';
import { useQueryClient } from 'react-query';
import { useData } from '../../device/fetchDevice';

const usePagination = ()=>{

 // const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [postPerPage, setPostPerpage] = useState(2)
  const [posts, setPost] = useState<any[]>([]);
  const {data:devices, isLoading:loading} = useData()
  const queryClient = useQueryClient();
  let LastIndexOfPost = currentPage * postPerPage;
  let firstIndexOfPost = LastIndexOfPost - postPerPage;
  let currentPosts = posts?.slice(firstIndexOfPost, LastIndexOfPost);
  let totalPages = Math.ceil(posts?.length/postPerPage);

  console.log("totalpages", totalPages)
  const onNext = ()=>{
    //Check total pages
    if(currentPage === totalPages){
      return totalPages
    }
    setCurrentPage(prev=> prev + 1)
  }

  const onPrev = ()=>{
    if(currentPage <= 1){
      return 1;
    }
    setCurrentPage(prev=> prev - 1)
  }

  const changeRow = (row: number) =>{
    setPostPerpage(row)
  }

  
  useEffect(()=>{
   setPost(devices?.data.data)
    
  },[devices?.data.data])
  console.log("inside table", posts)
  return {onNext, onPrev, changeRow, currentPage, currentPosts, loading, totalPages}
}

export default usePagination;