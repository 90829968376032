
import Delete from '../delete/Delete'
import { CenterLoadIndicator } from '../device/device.styled'
import { useGroup } from '../device/fetchDevice'
import useDeviceContent from '../device/useDeviceContent'
import PuffLoader from '../loaders/PuffLoader'
import Modal from '../modal/Modal'
import EditGroup from '../modalForm/editGroup/EditGroup'
import NewGroup from '../modalForm/newGroup/NewGroup'
import { usePopModal } from '../selectRef/hook/useGeofenceContext'

import TwoLevelLayer from './twoLevel/TwoLevelLayer'


const GroupMenu = () => {
   const {data:allGroups, isError, isLoading, error} = useGroup()
   const allData =  allGroups?.data?.data.groups

   const  {modalPop, setShow, show} = usePopModal()
   const {itemId, getItemId} = useDeviceContent()

   const toggleModalPop = (event: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
       event.preventDefault();
       setShow((prev)=> !prev);
   }
   console.log("group new", allGroups)
   console.log("itemd id", itemId)
   if(isLoading){
        return (
            <CenterLoadIndicator>
                <PuffLoader cssClass='edit__loader'/>
            </CenterLoadIndicator>
        )
    }


    return (
       <>
        {
           allData?.map((g:any, index:number)=>  <TwoLevelLayer key={g?._id} item={g} popIdx={index} getItemId={getItemId}/>)
        }

        {
            modalPop !== "" && 
                <Modal 
                    open={show} 
                    toggle={toggleModalPop} 
                    setOpen={setShow} 
                    title={modalPop} 
                    hideScroll='false'
                    //newBg={true}
                >
                    {
                        modalPop === "Delete group" &&  <Delete 
                                                            item={allData[itemId]} 
                                                            delType='groups' 
                                                            toggleBack={()=> setShow(prev=>!prev)}
                                                        />
                    }
                    {
                        modalPop ==="Edit group" && <NewGroup 
                                                        item={allData[itemId]} 
                                                        groupKind='editgroup' 
                                                        toggleModal={()=>setShow(!show)}
                                                    />
                    }

                </Modal>
                        
        }

       </>
    )
}

export default GroupMenu