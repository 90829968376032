import React from 'react'
import Button from '../../button/Button'
import Divider from '../../divider/Divider'
import CustomDrag from '../../dragDropFile/CustomDrag'
import TextInput from '../../floatInput/FloatInput'

const UserProfile = () => {
  return (
    <>
    {/***********************************Persona Details************************************/}
        <Divider title="Personal Details" width={50}/>
        <div className="form-content">
            <div className="form-item">
                <TextInput label="John Dusting"/>
            </div>
            <div className="form-item">
                <TextInput label="johndusting1213@gmail.com" read={true}/>
            </div>
            <div className="form-item">
                <TextInput label="+2348023458967"/>
            </div>
            <div className="form-item">
                <TextInput label="23, Ashy Road, Lagos"/>
            </div>
        </div>
    {/***********************************Old Password************************************/}
        <Divider title="Old Password" width={52}/>
        <div className="form-content">
            <div className="form-item">
                <TextInput label="Old Password"/>
            </div>
        </div>
    {/***********************************New Password************************************/}
        <Divider title="New Password" width={51}/>
        <div className="form-content">
            <div className="form-item">
                <TextInput label="Password"/>
            </div>
            <div className="form-item">
                <TextInput label="Repeat Password"/>
            </div>
        </div>
    {/***********************************Signature************************************/}
        <Divider title="Signature" width={53.5}/>
        <div className="form-content signature">
         <div className="upload__tab">
            <span className="tab_item">Draw</span>
            <span className="tab_item active">Upload</span>
         </div>
         <CustomDrag/>
        </div>

        <div className="user__btn">
            <Button className="user_cancel">Cancel</Button>
            <Button className="user_save">Save</Button>
        </div>
    </>
   
  )
}

export default UserProfile