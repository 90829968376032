import React from 'react'
import useMonitorClient from '../../hooks/useMonitorClient'
import Modal from '../modal/Modal'
import NewGroup from '../modalForm/newGroup/NewGroup'
import GroupMenu from '../multAccordion/GroupMenu'
import { ContentContainer} from './sidebar.styled'
import { ModalProps } from './SidebarContent'

const SidebarGroup = ({open, toggle, setOpen}: ModalProps) => {
  const handleToggle = ()=>{
    setOpen(!open)
  }

  const {clientData} = useMonitorClient("groups")

  return (
    <ContentContainer>
        <GroupMenu/>
        <Modal 
          open={open} 
          toggle={toggle} 
          setOpen={setOpen} 
          title="Create New Group" 
          hideScroll='true'
        >
          <NewGroup toggleModal={handleToggle} groupKind='newgroup'/>
        </Modal>
    </ContentContainer>
  )
}

export default SidebarGroup