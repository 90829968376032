import {useState, memo} from 'react'
import  VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {Props} from './typeProps'
import {Input} from './input.styled'



const  InputField: React.FC<Props> = ({text, placeText, name, id, icon, val, styles, handleChange}) => {
    const [show, setShow] = useState(false)
    const toggleShow = ()=>{
        setShow(prev=> !prev)
    } 

    console.log(icon)
 return( 
      <Input icon={icon}>
          {
             icon
             ? <input 
                type={show ? `${text}`: `password`}
                placeholder={placeText} 
                name={name} 
                id={id}
                style={styles}
                value={val}
                onChange={handleChange}
                />
             : <input 
                type={text}
                placeholder={placeText} 
                name={name} 
                id={id}
                style={styles}
                value={val}
                onChange={handleChange}
              /> 
          }

        <span>
            {
                show
                ?<VisibilityOutlinedIcon className="icon" onClick={toggleShow}/>
                :<VisibilityOffOutlinedIcon className="icon" onClick={toggleShow}/>
            }
        </span>
      </Input>
    )
}

export default InputField