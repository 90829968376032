import React from 'react'
import { ArrangeItem, ItemPositionContainer } from './canvas.styled'
import { useNavigate } from 'react-router-dom'
import ModalHeader from '../../components/modal/ModalHeader'
import Button from '../../components/button/Button'
import DraggableIcon from './DraggableIcon'


const DevicePosition = () => {
  const navigate = useNavigate();
  return (
    <ItemPositionContainer>
      <div className="header__title">
            Create 2D Canvas
        </div>
        <ArrangeItem>
            <ModalHeader title='Arrange your devices on the canvas' chg/>
            <div className="drag__drop">
               <div className="image__canvas">
                  <DraggableIcon temp="120MRP"/>
                  <DraggableIcon temp="20'C"/>
               </div>
            </div>
            <div className="canvas__form btn_group">
               <Button className="canvas__cancel" onClick={()=>navigate(-1)}>Cancel</Button>
               <Button className="canvas__save">save</Button>
            </div>
        </ArrangeItem>
    </ItemPositionContainer>
  )
}

export default DevicePosition;