import React from 'react'
import { HiOutlineArrowLeft } from 'react-icons/hi'
import Button from '../../button/Button'
import Divider from '../../divider/Divider'
import TextInput from '../../floatInput/FloatInput'
import Select from '../../select/Select'
import SelectItems from '../../select/SelectItems'
import { useNewGeoUser } from '../../selectRef/hook/useGeofenceContext'
import usePOIForm from '../../selectRef/hook/usePOIForm'
import { POIDetail, POIForm } from '../tab.styled'

const NewPOI = () => {
    const {setShowItem} = useNewGeoUser()
    const {handleSubmit, poiData,setPoiData} = usePOIForm()
    console.log(poiData)
    const handleInputData = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        e.preventDefault()
        const {name, value} = e.target
        setPoiData(prev=>{
           return {
            ...prev,
            [name]:value
           }
        })
    }
    const handleShow = ()=>{
        setShowItem(prev=>{
            return {
                ...prev,
                comp: "SHOW_POI"
            }
        })
    }
  return (
    <POIDetail>
        <div className="geofence__header">
            <Divider title="Create POI" width={12}/>
            <span className='back_btn' onClick={handleShow} >
                <HiOutlineArrowLeft className="back__arrow"/>
            </span>
        </div>
        
        <POIForm>
            <div className="geo__form shape">
                {/* <div className="geo__shape">
                    <input type="radio" id="shape" name="shape" value="circle" onChange={changeShape}/>
                    <FiCircle className='icon'/>
                    <span>Ellipse</span>
                </div> 
                <div className="geo__shape">
                    <input type="radio" id="shape" name="shape" value="polygon" onChange={changeShape}/>
                    <TbPolygon className='icon'/>
                    <span>Polygon</span>
                </div> */}
            </div>
            <div className="geo__form">
                <TextInput 
                    type="text" 
                    label="Truck Park" 
                    name='label' 
                    val={poiData.label}
                    onChange={handleInputData}
                />
            </div>
            {/* <div className="geo__form">
            <textarea placeholder='Description' cols={46} rows={4}/>
            </div> */}
            <div className="geo__form">
                <textarea 
                    name="description" 
                    id="" 
                    cols={47} 
                    rows={5} 
                    placeholder="Description" 
                    value={poiData.description}
                    onChange={handleInputData}
                />
            </div>
            {/* <div className="geo__form">
                <TextInput type="text" label="Color"/>
            </div> */}
            <div className="geo__form">
               <Select/>
            </div>
            <div className="geo__form btn_group">
                <Button className="geo_white" onClick={handleShow}>Cancel</Button>
                <Button className="geo_green" onClick={handleSubmit}>Save</Button>
            </div>
        </POIForm>
    </POIDetail>
  )
}

export default NewPOI