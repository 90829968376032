import React from 'react'
import { MutationFunction, useMutation, useQueryClient} from 'react-query';


export const sortData = (a:any, b:any)=>{
    if (a.deviceName < b.deviceName) {
      return -1;
    }
    if (a.deviceName > b.deviceName) {
      return 1;
    }
    return 0;
}


const useMutateData = <T, D>(submitData:MutationFunction<T, D>, id?: string) => {
    const queryClient = useQueryClient()
    return useMutation("MutateDevice", submitData, {
      onMutate: async(newDevice)=>{
      //   await queryClient.cancelQueries("devices")
      //   const oldData:any = await queryClient.getQueryData("devices")
      //  return {
      //   oldData
      //  }
      
      },
      onSuccess: async (data:any, variables, context)=> {
        //await queryClient.cancelQueries("devices")
        //await queryClient.invalidateQueries("devices");
        const old:any = await queryClient.getQueryData("devices")
        const prev = old.data.data.some((d:any)=> d._id === id )
        if(prev){
          await queryClient.setQueryData("devices", (oldData:any)=>{
            console.log("update", data)
            console.log("old inide", oldData)
            let newData;
            const prevData = old.data.data.filter((d:any)=> d?._id !== id )
            if(data === undefined){
              newData = [...prevData]
              return {
                ...oldData,
                data:{
                  data: newData.sort(sortData)
                } 
              }
            }else{
              newData = [...prevData, data?.data]
              return {
                ...oldData,
                data:{
                  data: newData.sort(sortData)
                } 
              }
            }
           
          });

          await queryClient.invalidateQueries("devices");
        }else{
          await queryClient.setQueryData("devices", (oldData:any)=>{
            console.log("new", data)
            const newData = [...oldData.data.data, data?.data]
            return {
              ...oldData,
              data:{
                data: newData.sort(sortData)
              } 
            }
          });

          await queryClient.invalidateQueries("devices");
        }
        
      },

      onError: async (error, variables, context)=> {
        console.log("errl", error)
        await queryClient.setQueryData("devices", (oldData:any)=>{
          console.log("err", oldData)
            return {
              ...oldData,
              data:{
                data: [oldData?.data.data]
              }
            }
          });
      },
      onSettled: async (data, error, _variables, context:any)=> {
        // if(data){
         
        // }
        if(data === undefined){
          console.log("no", data)

          await queryClient.invalidateQueries("devices");
            await queryClient.setQueryData("devices", (oldData:any)=>{
            console.log("err", context?.oldData)
            return context?.oldData
          });
        }

      }, 
    })
}

export default useMutateData