import React, {useEffect, useState} from 'react'
import Divider from '../../divider/Divider'
import { DeviceContainer, Form } from '../modalForm.styled'
import TextInput from '../../floatInput/FloatInput'
import useRequestDevice from '../../../hooks/useRequestDevice'
import MultiSensorPage, { MultiSensorEditPage } from '../../multiDeviceSelect/MultiSensorPage'
import useEditForm from './useEditForm'
import EditDeviceItem from './EditDeviceItem'
import _ from 'lodash'
import PuffLoader from '../../loaders/PuffLoader'
import FailedState from '../../failed/FailedState'
import Success from '../../success/Success'



export type NewDeviceProp = {
  handleToggle: ()=> void
}



type EditProps = {
    item: any,
    toggleModal: ()=> void
}

export type EditDeviceType = {
    sensorsEnabled: string[],
    deviceName: string,
    phone: string,
    imei: string,
    simiccid: string
    //icon: string
    deviceType: string,
    deviceModel: string
}

const removeItem = ["deviceType", "deviceModel", "imei", "connectivityType", "icon", "user", "users", "__v", "account", "_id"]
const url = process.env.REACT_APP_ENTERPRISE_URL + "devices"
const EditDevice = ({item, toggleModal}: EditProps) => {
    const [simType, setSimType] = useState("gentro")
    const  {info, dataState} = useRequestDevice("devices", item._id)
    //console.log("editItemman" , info?.data)

    const [editDevice, setEditDevice] = useState<EditDeviceType | undefined>(undefined);
    const {handleSubmit, state:formState} = useEditForm(_.omit(editDevice, ["deviceType", "deviceModel", "imei"]), item._id, url, toggleModal, _.omit(info?.data, removeItem))
    const handleSelection = (e:React.ChangeEvent<HTMLInputElement>)=>{
        const {value} = e.target
        setSimType(value)
    }
    const handleEdit = (e: React.ChangeEvent<HTMLInputElement>)=>{
        e.preventDefault()
        const {name, value} = e.target
        setEditDevice((prev:any)=>{
            return {
                ...prev,
                [name]: value
            }
        })
    }

    
 

    useEffect(()=>{
        setEditDevice({
            sensorsEnabled: [],
            deviceName: info?.data.deviceName,
            deviceType: info?.data.deviceType.title,
            deviceModel: info?.data.deviceModel.deviceModel,
            phone:info?.data.phone,
            imei:info?.data.imei,
            simiccid:info?.data.simiccid,
            //icon:info?.data.icon
        })
    }, [info?.data])

    //console.log("editvice", editDevice)


  return (
    
        <>
            {
                dataState === "loading" && <PuffLoader cssClass='edit__puff'/>
            }
            {
                dataState === "success" && info?.data 
                && <EditDeviceItem 
                item={editDevice} 
                handleEdit={handleEdit}
                handleSubmit={handleSubmit}
                setEditDevice={setEditDevice}
                sensors={info?.data.deviceModel.sensors}
                simType={simType}
                handleSelection={handleSelection}
                formState={formState}
              />
            }
            {
                dataState === "failed" && <FailedState desc={item.deviceName} toggleModal={toggleModal}/>
            }
        </>
  
    
  )
}

export default EditDevice