import React from 'react'

const LeftItem = () => {
  return (
   <>
    <h1>One platform for all IoT</h1>
    <p>
        Hardware-agnostic IoT platform that helps reduce operational costs and increase efficiency in your business processes.
    </p>
    <div className='image'>
        <img 
        src={process.env.PUBLIC_URL + "/assets/images/tracking.png"} 
        alt="tracking"
        />
    </div>
   </>
  )
}

export default LeftItem