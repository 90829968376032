import React, {useState, useEffect, useRef} from 'react'
import useClient from '../../../hooks/useClient'
import useMultipleClients from '../../../hooks/useMultipleClient'
import TextInput from '../../floatInput/FloatInput'
import MultiSensorPage from '../../multiDeviceSelect/MultiSensorPage'
import SubusersPage from '../../multiUserSelect/SubusersPage'
import DeviceIcon from '../../select/deviceSelect/DeviceIcon'
import { DevicesData, useSetDevice } from '../../selectRef/hook/useGeofenceContext'
import CheckIcon from './CheckIcon'
//import { DevicesData } from './deviceTyped'
import useDeviceForm from './useDeviceForm'

type SetDeviceProp = {
    devices: DevicesData,
    handleDevice: React.ChangeEventHandler,
    deviceType: [],
    models:[],
    sensors: [],
    checkSim: string,
    checkImei:string,
    errors: {[s:string]: string}
    handleSelection: React.ChangeEventHandler
    simType: string
}
const gData = [
    {
        id: "1",
        title: "Temperature"
    },
    {
        id: "2",
        title: "Speed "
    },
]
const radioData = [
    {

        id:"gentro",
        name: "sim",
        value:"gentro",
        title: "Gentrol Sim",
        check: true
    },
    {

        id:"regular",
        name: "sim",
        value:"regular",
        title: "Regular Sim",
        check: false
    },
    {

        id:"other",
        name: "sim",
        value:"other",
        title: "Other Sim",
        check: false
    },
    {

        id:"lora",
        name: "sim",
        value:"lora",
        title: "LORA ",
        check: false
    }
]
const SetDevice = (
    {
        devices, 
        handleDevice, 
        deviceType, 
        models, 
        sensors, 
        checkSim,
        checkImei,
        errors,
        handleSelection,
        simType
    }: SetDeviceProp) => {
   
  //const {firstCall} = useClient("device-types");
    //const {firstCall} = useMultipleClients("device-types", "device-models")
    const {setDevices} = useSetDevice()
    const {resp } = useClient("users/sub-users")
    //console.log("subusers", resp)
   
  
  return (
    <div className="form-content">
        <div className="form-item radio-item">
            <div className="radio-btn">
                <input type='radio' id='gentro' name='sim' value='gentro' onChange={handleSelection} checked={simType === "gentro"}/>
                <span className="radio-desc">Gentro Sim</span>
            </div>
            <div className="radio-btn">
                <input type='radio' id='regular' name='sim' value='regular' onChange={handleSelection}/>
                <span className="radio-desc">Regular Sim</span>
            </div>
            <div className="radio-btn">
                <input type='radio' id='other' name='sim' value='other' onChange={handleSelection}/>
                <span className="radio-desc">Other</span>
            </div>
            <div className="radio-btn">
                <input type='radio' id='lora' name='sim' value='lora' onChange={handleSelection}/>
                <span className="radio-desc">LORA</span>
            </div>
        </div>
        <div className='form-item'>

        </div>
        <div className="form-item">
            <TextInput 
                label="Device Name" 
                name="deviceName" 
                val={devices.deviceName}
                onChange={handleDevice}
            />
            {
                errors.deviceName && simType === "gentro" 
                ?<span className="err">{errors.deviceName}</span>
                : null
            }
        </div>
        <div className="form-item">
            <select 
                placeholder='Device Type' 
                name="deviceType" 
                onChange={handleDevice} 
                required
            >
                <option value="" disabled selected hidden>Device Type</option>
                    {
                        deviceType?.map((d:any)=><option value={d?._id} key={d._id}>{d.title}</option>)
                    }
            </select>
            {
                errors.deviceType && simType === "gentro" 
                ?<span className="err">{errors.deviceType}</span>
                : null
            }
        </div>
        <div className="form-item">

            <TextInput 
                label="IMEI/ID" 
                name="imei" 
                val={devices.imei} 
                onChange={handleDevice}
            />
            {
               checkImei !== "idle"  ? <CheckIcon isValid={checkImei}/> : null
            }

           {
                errors.imei && simType === "gentro" 
                ?<span className="err">{errors.imei}</span>
                : null
            }
        </div>
        <div className='form-item'>
            <select name="deviceModel" onChange={handleDevice} required>
                <option value="" disabled selected hidden>Device model</option>
                {
                  models 
                  ?  models.map((d:any)=><option value={d?._id} key={d._id}>{d.deviceModel}</option>) 
                  : <option>No Available model</option>
                }
            </select>
            {
                errors.deviceModel && simType === "gentro" 
                ?<span className="err">{errors.deviceModel}</span>
                : null
            }
        </div>
        <div className='form-item'>
           <DeviceIcon/>
        </div>
        <div className='form-item'>
            <SubusersPage options={resp?.data} setOption={setDevices} label="Add Sub-users"/>
            {/* <TextInput 
                label='User' 
                name='user' 
                val={devices?.user} 
                onChange={handleDevice}
                read={true}
            /> */}
        </div>
      
        <div className='form-item'>
            <MultiSensorPage 
                options={sensors} 
                setOption={setDevices} 
                label="Select Sensor"
            />
           {
                errors.sensorsEnabled && simType === "gentro" 
                ?<span className="err">{errors.sensorsEnabled}</span>
                : null
            }
        </div>
        <div className="form-item">
            {
                simType === "lora" ?
                    ( <TextInput 
                        label="APPEUI" 
                        name="appEui" 
                        // val={devices?.simiccid}
                        // onChange={handleDevice}
                    />
                ): ( 
                    <>
                        <TextInput 
                            label="SIM/ICCID" 
                            name="simiccid" 
                            val={devices.simiccid}
                            onChange={handleDevice}
                        />
                        {
                            (devices?.simiccid &&  simType==="gentro") ? <CheckIcon isValid={checkSim}/> : null
                        }
                    </>
                )
            }
           {
                errors.simiccid && simType === "gentro" 
                ?<span className="err">{errors.simiccid}</span>
                : null
            }
        </div>
        <div className='form-item'>
            {
                simType === "lora" 
                ? (<TextInput 
                        label="App Key" 
                        name="appKey" 
                        // val={devices?.phone}
                        // onChange={handleDevice}
                    />
                ) 
                :(  <TextInput 
                        label="Phone" 
                        name="phone" 
                        val={devices.phone}
                        onChange={handleDevice}
                    />
                )
            }
            {
                errors.phone && simType === "gentro" 
                ?<span className="err">{errors.phone}</span>
                : null
            }
            
        </div>
        <div className='form-item'>
            <select name="deviceModel">
                <option value="" disabled selected hidden>Select 2D Object</option>
            </select>
        </div>
       
    </div>
  )
}

export default SetDevice