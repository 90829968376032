import styled from 'styled-components/macro'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--primary-bg);
    padding: 8px;
    gap: 15px;

    .left-item{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding:5px 0;
        cursor: pointer;
    }
    span{
        display: inline-flex;
    }

    .location{
        font-size: 1.3rem;
        color: #252525;
    }
`
export const LeftItemWrapper = styled(Wrapper)`
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 4px;
    box-shadow: 0 1px 5px rgb(0 0 0 / 65%);
    background-clip: padding-box;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    padding: 0;
    width: 100%;
    gap: 10px;
    /* background:#CFF4D2; */

    .left-item{
        width: 100%;
        /* border-bottom: 2px solid #e4e4e4;
        justify-content: flex-start; */
    }

    .left-item.activated{
        background:#CFF4D2;
    }
`

export const TopWrapper = styled(Wrapper)`
    flex-direction: row;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;

    .top-item{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding: 5px;
        text-decoration: none;
        color: initial;
    }

    .top-item.active{
        background:#CFF4D2;
        color: var(--item-bg);
        border-radius: 5px;
    }

    .top-left-icon{
        font-size: .874rem;
        font-weight: 600;
        color: #252525;
    }
    .top-right-icon{
        font-size: 1.5rem;
        font-weight: 600;
        color: #252525;
    }

    p{
        font-size: 0.875rem;
    }

`