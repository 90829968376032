import React,{useEffect, useRef} from 'react'
import Select from '../select/Select'
import { BackDrop, ModalWrapper, ModalContent } from './modal.styled'
import ModalHeader from './ModalHeader'
import { ModalProps} from './typeProps'


const Modal = ({open, toggle, setOpen, children, title, hideScroll, newBg=false}: ModalProps) => {


  return (
    <>

      {
          open && (
            <BackDrop newBg={newBg}>
              <ModalWrapper>
                <ModalHeader toggle={toggle} title={title}/>
                <ModalContent hideScroll={hideScroll}>
                  {children}
                </ModalContent>
              </ModalWrapper>
            </BackDrop>
          )
        }
    

    </>
  )
}

export default Modal