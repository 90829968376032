import {MdOutlineSignalCellularAlt} from 'react-icons/md'
import {GoLocation, GoPrimitiveDot} from 'react-icons/go'
import { useDeviceTracking } from '../selectRef/hook/useGeofenceContext'
import moment from 'moment'
type DeSummary = {
    item: any
}
const DeviceSummary = ({item}: DeSummary) => {

    const {panel} = useDeviceTracking()
    console.log("sumry", panel.deviceSummary)
  return (
   <>
        <div className="bottom-detail">
            <div className="bottom-detail-left">
                <GoPrimitiveDot className="indicator"/>
                <span className='text-2'>Online</span>
            </div>
        </div>
        <div className="bottom-detail">
            <div className="bottom-detail-left">
                <MdOutlineSignalCellularAlt className="indicator"/>
                <span className='text-2'>Signal Strength: 100%</span>
            </div>
        </div>
        <div className="bottom-detail">
            <div className="bottom-detail-left">
                <GoLocation/>
                <span className='text-2'>23, Ajayi Cresent, ikeja, <br/>Lagos State, Nigeria</span>
            </div>
        </div>
        <div className="bottom-detail">
            {
                item?.locationGPS && (
                    <div className="bottom-detail-left">
                        <div className='space'></div>
                        <span className='text-2 locate'>
                            <span>Lat: {item?.locationGPS.lat.toFixed(5)}</span>
                            <span>Lng: {item?.locationGPS.lon.toFixed(5)}</span>
                        </span>
                    </div>
                )
            }
           
        </div>
        <div className="bottom-detail">
            <div className="bottom-detail-left">
                {/* <MdOutlineLocationSearching/> */}
                <div className='space'></div>
                <span className='text-2 imei'>IMEI : {item?.imei}</span>
            </div>
        </div>
        <div className="bottom-detail">
            <div className="bottom-detail-left">
                {/* <MdOutlineLocationSearching/> */}
                <div className='space'></div>
                <span className='text-2 network'>
                    <span className="gps">GPS</span>
                    <span className="cell">Cell</span>
                    {/* <span className="lora">Lora</span> */}
                </span>
            </div>
        </div>
        <div className="bottom-detail">
            <div className="bottom-detail-left">
            <div className='space'></div>
                <span className='text-2'>Device Status : Standstill</span>
            </div>
        </div>
        <div className="bottom-detail">
            <div className="bottom-detail-left">
                <div className='space'></div>
                <span className='text-2'>Standstill Duration : 2hrs 23min</span>
            </div>
        </div>
        <div className="bottom-detail">
            <div className="bottom-detail-left">
                <div className='space'></div>
                <span className='text-2'>Last Updated: {moment(item?.sensorTime).format("DD/MM/YYYY,  hh:mm")}</span>
            </div>
        </div>
        <div className="bottom-detail">
            <div className="bottom-detail-left">
                <div className='space'></div>
                <span className='text-2'>Plan: Basic</span>
            </div>
        </div>
        <div className="bottom-detail">
            <div className="bottom-detail-left">
                <div className='space'></div>
                <span className='text-2'>Driver: Catongo Lisa</span>
            </div>
        </div>
   </>
  )
}

export default DeviceSummary