import { useEffect } from 'react';
import {MapContainer, TileLayer, Tooltip, ZoomControl, useMapEvents, useMap, useMapEvent} from 'react-leaflet'
import usePOIForm from '../../components/selectRef/hook/usePOIForm';
import { LatLng, LocationFinderProps } from './map.typed';
import { LeafletEventHandlerFnMap, LeafletMouseEvent, LeafletMouseEventHandlerFn } from 'leaflet';

export const LocationFinderDummy = ({setPos}:LocationFinderProps) => {
        const {setPoiData} = usePOIForm()
        const map = useMapEvents({});
        map.scrollWheelZoom.enabled()
        map.on("click", (e)=>{
        const {lat, lng} = e.latlng
        setPos({lat: lat, lng: lng})
        setPoiData(prev=>{
            return {
            ...prev,
            lat: lat,
            lon: lng
            }
        })
        })
        // console.log("maps", map)
        return null;
    };

// export const RecenterAutomatically = ({lat, lng}:LatLng) => {
//     const map = useMap();
//     useEffect(() => {
//     map.setView([lat, lng], {
//         anima
//     });
//     }, [lat, lng]);

//     return null;
// }

// export function SetViewOnClick({lat, lng }:LatLng) {
//     const map = useMapEvents({
//         click(){
//             map.flyTo([lat, lng], map.getZoom(), {
//                 animate: true
//             })
//         }
//     })
//     return null;
// }