import React, { useState, useRef } from "react";
import { TooltipProps } from "./Tooltip-types";
import { TooltipWrapper } from "./tooltip.styled";
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'

type DragData = {
  activeDrag: number,
  deltaPosition: {
    x: number,
    y: number
  }
}

type AllDevice = {

}
const Tooltip = ({children, delay, content = "cool Tooltip", direction}: TooltipProps) => {
  let timeout: ReturnType<typeof setTimeout>;
  const [active, setActive] = useState(false);
 
 
  let position = {
    activeDrag: 0,
    deltaPosition: {
      x: 0,
      y: 0
    }
  }
  const [dragPosition, setDragPosition] = useState<DragData>(position);
  const handleDrag = (e: DraggableEvent, ui: DraggableData) => {
    const {x, y} = dragPosition.deltaPosition;
    console.log( ui.deltaX)
    setDragPosition(prev=>{
      return {
        ...prev, 
        deltaPosition: {
          x : x +  ui.deltaX, 
          y : y +  ui.deltaY 
        } 
      }
    })

  }

  const onStart = () => {
    setDragPosition(prev=> {
      return { 
        ...prev, 
        activeDrag: ++dragPosition.activeDrag
      }
    });
  };

  const onStop = () => {
    //Take all the davices data registered before positioning
    //Set all the devicers data and send to the backend
    setDragPosition(prev=> {
      return { 
        ...prev, 
        activeDrag: --dragPosition.activeDrag
      }
    });
  };
    
  const dragHandlers = {onStart: onStart, onStop: onStop};

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  console.log(dragPosition);
  return (
    <Draggable  bounds="parent"  onDrag={handleDrag} {...dragHandlers}>
        <TooltipWrapper
          // When to show the tooltip
          onMouseEnter={showTip}
          onMouseLeave={hideTip}
        >
          {/* Wrapping */}
          {children}
          {active && (
            <div className={`Tooltip-Tip ${direction || "top"}`}>
              {/* Content */}
              {content}
            </div>
          )}
        </TooltipWrapper>
    </Draggable>
  );
};

export default Tooltip