import {useRef, useState, forwardRef, useEffect} from 'react'
import {MapContainer, TileLayer, Tooltip, ZoomControl, useMapEvents, LayersControl, LayerGroup} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import '../map/map.styled.css'
import DraggableMarker from '../../components/maps/Marker';
import Shape from './Shape';
import { useDeviceTracking, useGeofenceShape, useNewGeoUser, usePoiDisplay } from '../../components/selectRef/hook/useGeofenceContext';
import POIMarker from '../../components/customMarker/POIMarker';
import usePOIForm from '../../components/selectRef/hook/usePOIForm'
import toast, { Toaster } from 'react-hot-toast';
import DeviceMarker from '../../components/customMarker/DeviceMarker'
import { LocationFinderDummy } from './mapevents'
import NewDeviceMarker from '../../components/customMarker/NewDeviceMarker'


export type LatLng = {
  lat: number,
  lng: number
}

type Layers = {
  label: string,
  id: number,
  latlng: LatLng | LatLng[]
  radius?: number

}[]


const pos: [number, number] = [6.5244, 3.3792];
const markerPos = {
  lat: 6.5244,
  lng: 3.3792
}

const MapContent = () => {
    const [zoomLevel, setZoomLevel] = useState<number>(13);
    const [position, setPos] = useState<LatLng>(markerPos)
    const {shape} = useGeofenceShape()
    const {comp} = useNewGeoUser()
    const {poi} = usePoiDisplay()
    const {setPoiData} = usePOIForm()
    const   {activeDevice} = useDeviceTracking()
    const {BaseLayer} =  LayersControl

   
    
    // const notify = ()=> toast.success("POI Save successfully", {
    //   position: "top-center"
    // })
   
    return (
      <>
          <MapContainer center={pos} zoom={zoomLevel} scrollWheelZoom={true} zoomControl={false}>
            <LayersControl>
              <BaseLayer  name="Open Street map" checked>
                <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
              </BaseLayer>
              <BaseLayer checked name="Google Map">
                <TileLayer
                  attribution="Google Maps"
                  url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                />
              </BaseLayer>
              <BaseLayer name="Google Map Satellite">
                <LayerGroup>
                  <TileLayer
                    attribution="Google Maps Satellite"
                    url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                  />
                  <TileLayer url="https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}"/>
                </LayerGroup>
              </BaseLayer>
            </LayersControl>
            
              {
                shape === 'polygon' &&  <Shape shapeType={shape}/> 
              }
              {
                shape === 'circle' &&  <Shape shapeType={shape}/>
              }
              {/* <DraggableMarker/> */}

              {/* Set Marker Position  for the poi*/}
              {
                poi && (
                  comp === "poiForm" ? <POIMarker pos={position} showPop={true}/> : <POIMarker pos={{lat:6.5244, lng:3.3792}} toolTip={false}/>
                ) 
              }

              {/* Display  tracking device position*/}
              {
                activeDevice && (
                  activeDevice.map(d=> <NewDeviceMarker item={d} key={d?._id}/>)
                )
              }

              {/* <DeviceMarker/> */}
              <LocationFinderDummy setPos={setPos}/>
              {/* <ZoomControl position='topright' /> */}
          </MapContainer>
      </>
  )
}

export default MapContent