import React, { 
    useState, 
    createContext, 
    useContext, 
} from "react";
import { useEffect } from "react";

type Title = {
    title: string
}
type SelectContextProps = {
    children: React.ReactNode
}

type ContextProps = {
    selected: string[]
    toggleOption: ({title}: Title)=> Promise<void>
}

/* Creating a context with a type of `GeofenceCtxs | undefined` and setting the default value to
`undefined` */
const SelectCtx = createContext<ContextProps>({} as ContextProps)


export const SelectProvider = ({children}:SelectContextProps)=>{
    const [selected, setSelected] = useState<string[]>([]);

    
    const toggleOption = async ({ title }: Title) => {
        setSelected((prevSelected: string[]) => {
            // if it's in, remove
            const newArray: string[] = [...prevSelected]
            if (newArray.includes(title)) {
                return newArray.filter((item:string) => item.toLowerCase() !== title.toLowerCase())
                // else, add
            } else {
                newArray.push(title)
                return newArray;
            }
        })
    }

    return (
        <SelectCtx.Provider value={{selected, toggleOption}}>
            {children}
        </SelectCtx.Provider>
    );
}



export const useSelectOption = ()=>{
    const {selected, toggleOption} = useContext(SelectCtx)

    return {selected, toggleOption}
}

