import { useState, useRef, useMemo, useCallback} from 'react'
import { Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import useDraggable from './useDraggable';

//Icons
const greenIcon = L.icon({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),

    iconSize:     [20, 55], // size of the icon
    shadowSize:   [50, 64], // size of the shadow
    iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
    shadowAnchor: [40, 80],  // the same for the shadow
    popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
});

//Default center
const center = {
    lat: 6.5244,
    lng: 3.3992,
  }
const DraggableMarker = () => {
   
    const {
      draggable, 
      position, 
      markerRef, 
      handleMakerPosition, 
      toggleDraggable
    } = useDraggable()

    //Reference an instnce of the marker
    

    return (
        <Marker
          draggable={draggable}
          eventHandlers={handleMakerPosition}
          position={position}
          icon={greenIcon}
          ref={markerRef}>
          <Popup minWidth={90}>
            <span onClick={toggleDraggable}>
              {draggable
                ? 'Marker is draggable'
                : 'Click here to make marker draggable'}
            </span>
          </Popup>
        </Marker>
      )
}

export default DraggableMarker