import React,{useState, useMemo, useRef, useCallback} from 'react'
import L from 'leaflet'

const center = {
    lat: 6.5244,
    lng: 3.3992,
  }
const useDraggable = (initVal = center) => {
    const [draggable, setDraggable] = useState(false)
    const [position, setPosition] = useState(initVal)


    //Reference an instnce of the marker
    const markerRef = useRef<L.Marker>(null);
    const handleMakerPosition = useMemo(()=>({
        dragend(){
            const marker = markerRef.current
            if(marker !== null){
               setPosition(marker.getLatLng())
            }
        }
    }), [])

    const toggleDraggable = useCallback(() => {
        setDraggable((d: boolean) => !d)
    }, [])

    return {draggable, position, handleMakerPosition, markerRef, toggleDraggable};
}

export default useDraggable