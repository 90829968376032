import React,{useState} from 'react'
import { Form, VerificationForm } from '../login/loginStyled'
import Button from '../../components/button/Button'
import Otp from '../../components/otp-input/Otp'
import OtpInput from '../../components/otp-input/OtpInput'
import useOtp from './useOtp'
import Spinner from '../../components/spinner/Spinner'
import { FiCheckCircle } from 'react-icons/fi'
import { useAppSelector } from '../../reducers/disPatchSelector'

const OtpForm = () => {
    
    const {otpState, otp, resendState, serverMsg, onChange, resendCode, handleSubmitCode} = useOtp()
    console.log("otppp", otp)
  return (
    <VerificationForm onSubmit={handleSubmitCode}>
        <h4>Email Verification</h4>
        {
            (resendState === "success"  || resendState === "failed")  && <span>{serverMsg}</span>
        }
        {
            resendState === "verifying"  && <span>{"email will be sent shortly..."}</span>
        }
        <div className="verify__mail">
            <div className="verify_img">
                <img 
                    src={process.env.PUBLIC_URL + "/assets/images/email.png"} 
                    alt="gentro Logo"
                />
            </div>
            <span className="check_title">
                Check Your Email
            </span>
            <span className="check_desc">
                We sent four digits number to your email, please type in 
                the number in the space provided below 
            </span>
            <OtpInput value={otp.code} valueLength={4} onChange={onChange}/>
        </div>

        <Button className="button" disabled={otpState === "verifying" || resendState === "verifying"}>
            {
                (otpState === "idle" ||  otpState === "failed") && "Send"
            }
            {
                otpState === "verifying" && <Spinner/>
            }
            {
                otpState === "success" && <FiCheckCircle/>
            }
        </Button>
        <div className="code_resend">
            <span>
                Didn't receive the code? click <span onClick={resendCode}>Resend code</span>
            </span>
        </div>
    </VerificationForm>
  )
}

export default OtpForm