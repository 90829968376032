import {useRef} from 'react'
import { LeftPositionItem, PositionItem } from '../header/headerStyled'
import { TopWrapper } from '../mapleft/styled';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import useToggleClass from '../../features/changeclass';
import {  useNewGeoUser, usePoiDisplay } from '../selectRef/hook/useGeofenceContext';
import { BsGeo } from 'react-icons/bs';
const data = [
  {
    id: 1, 
    icon: <BsGeo className="top-right-icon"/>,
    title: "SHOW_GEO",
    comp: "geo"
  },
  {
    id: 2, 
    icon: <AddLocationAltOutlinedIcon className="top-right-icon"/>,
    title: "SHOW_POI",
    comp: "poi"
  },
  {
    id: 3, 
    icon: <MapOutlinedIcon className="top-right-icon"/>,
    title: "map",
    comp: "map_item"
  },
  {
    id: 4, 
    icon: <SearchOutlinedIcon className="top-right-icon"/>,
    title: "search",
    comp: "search_item"
  },
]
const TopRight = () => {
  const {changeClass, toggleState} =  useToggleClass('top-item active', 'top-item')
 // const {dispatch, ...state} = useGeoUser()
  const {setShowItem, ...showItems}= useNewGeoUser()
  const {handlePoiDisplay} = usePoiDisplay()
  const itemRef = useRef<HTMLDivElement[]>([]);

  console.log(showItems)
 
  const handleClick = (id: number, title:string) =>{
    toggleState(id)
    setShowItem({showItem: true, comp: title})
    
    if(title === "SHOW_POI"){
      handlePoiDisplay(true);
    }else{
      handlePoiDisplay(false);
    }

  }
  return (
    <LeftPositionItem top={6} right={8}>
     <TopWrapper>
        {
          data.map((item, id)=>{

            let val = item.comp.toUpperCase()
            return(
              <div
                // ref={(elem: HTMLDivElement)=> itemRef.current[id] = elem} 
                id={item.title}
                className={changeClass(id)} 
                key={item.title} 
                onClick={()=>{handleClick(id, item.title)}
                }>
                <span>
                {item.icon}
                </span>
              </div>
            )
          })
        }
      </TopWrapper>
    </LeftPositionItem>
  )
}

export default TopRight