import React,{useState, useEffect, useMemo} from 'react'
import moment, { getTime } from './getTime'
import { useData } from '../../components/device/fetchDevice'
import { getToken } from '../../hooks/getToken'
import { client } from '../../utils/axios-instance'
export type ReportData   ={
    title:string,
    sensors:string[]
    format:string
    imei:string,
    startTime:string,
    endTime:string
}

const useReport = () => {
    console.log("newdate",moment.utc().toDate())
    const report:ReportData ={
        title:"General Report",
        sensors:["gpson"],
        format:"pdf",
        imei:"",
        startTime: moment.utc().toJSON(),
        endTime:moment.utc().toJSON()
    }

    const [device, setDevice] = useState([])
    const [dateFrom, setDateFrom] = useState(getTime(parseInt("1")))
    const [dateTo, setDateTo] = useState(getTime(parseInt("1")))
    const {data:devices, isLoading:loading} = useData();
    const [reportItem, setReportItem] = useState<ReportData>(report)
    const [day, setDay] = useState("1")
    const [reportMsg, setReportMsg] = useState()
    const [isSubmitting, setIsSubmitting] =  useState(false)

    const handleReport =  (e:React.ChangeEvent<HTMLInputElement|HTMLSelectElement>)=>{
        e.preventDefault();
        const {name,  value} = e.target

        setReportItem(prev=>{
            return{
               ...prev,
               [name]: value 
            }
        })

        if(name === "endTime"){
            const endTime = typeof dateTo === "string" ? dateTo.split("T")[0]+"T"+value : ""
            console.log("wooo")
            if(moment(endTime).isValid()){
                setReportItem(prev=>{
                    return{
                       ...prev,
                       [name]: endTime 
                    }
                })
                console.log(moment.utc(endTime).toJSON())
            }
        }

        if(name === "startTime"){
            const startTime = typeof dateFrom === "string" ? dateFrom.split("T")[0]+"T"+value : ""
            console.log("humm")
            if(moment(startTime).isValid()){
                setReportItem(prev=>{
                    return{
                       ...prev,
                       [name]: startTime 
                    }
                })
                console.log(moment.utc(startTime).toJSON())
            }
        }
       
    }
    
    const selectDate =(e:React.ChangeEvent<HTMLInputElement>)=>{
        const {value} = e.target
        setDay(value)
        const  dateType = getTime(parseInt(value))
        if(value !== "1" && typeof dateType ==="object"){
            const {startDate, endDate} =  dateType
            setDateFrom(startDate!)
            setDateTo(endDate!)
        }else{
            setDateFrom(getTime(parseInt(value)))
            setDateTo(getTime(parseInt(value)))
        }
    }

    const handleDate = (e:React.ChangeEvent<HTMLInputElement>)=>{
        const  {value, name} =    e.target
        if(name === "dateFrom")setDateFrom(value)
        if(name === "dateTo") setDateTo(value)
        // if(moment(value, "YYYY-MM-DD").isValid()){
          
        // }
    }

    const handleSubmit =  (e:React.FormEvent)=>{
        e.preventDefault();
        setIsSubmitting(true)
        console.log("submitted",report)
    }

    useEffect(()=>{
        const generateReport = async()=>{
            const  token = getToken();
            const request = client(token);

            try {
                await  request.post(process.env.REACT_APP_MONITOR_URL+"report", reportItem)
                .then((res)=>{
                    return res.data.data
                }).catch((err)=>{
                    setReportMsg(err.response.message)
                })
            } catch (error:any) {
                setReportMsg(error)
            }
        }

        if(isSubmitting){
            setIsSubmitting(false);
            setTimeout(()=>{
                generateReport();
            },500)
        }
    },[reportItem, isSubmitting])


    useEffect(()=>{
        setDevice(devices?.data.data)
         
    },[devices?.data.data])

    return {dateFrom, dateTo, day, device, reportItem, selectDate, handleDate, handleReport, handleSubmit}
}

export default useReport