import { BiBody, BiListCheck } from 'react-icons/bi'
import { BsAlignCenter } from 'react-icons/bs'
import { CgSearchLoading } from 'react-icons/cg'
import { FaAccessibleIcon } from 'react-icons/fa'
import { 
    MdAddAPhoto, 
    MdBluetoothDrive, 
    MdElectricRickshaw, 
    MdOutlineElectricRickshaw, 
    MdOutlineLocalShipping, 
    MdOutlineToys, 
    MdOutlineSpeed,
    MdOutlineNaturePeople
} from 'react-icons/md'
import {TbBus} from 'react-icons/tb'
import {ImLocation} from 'react-icons/im'
import {FaThermometerQuarter, FaThermometerThreeQuarters} from 'react-icons/fa'
import {GiCctvCamera} from 'react-icons/gi'
import {IoMdSpeedometer} from 'react-icons/io'





export const icons = [
    {
        id: "mdr133",
        icon:  MdElectricRickshaw,
        title: "MdElectricRickshaw"
    },
    {
        id: "mou884",
        icon: MdOutlineNaturePeople,
        title: "MdOutlineNaturePeople"
    },
    {
        id: "gic354",
        icon: GiCctvCamera,
        title: "GiCctvCamera"
    },
    {
        id: "iod275",
        icon: IoMdSpeedometer,
        title: "IoMdSpeedometer"
    },
    {
        id: "mbm902",
        icon:  MdOutlineSpeed,
        title: "MdOutlineSpeed"
    },
    {
        id: "mya234",
        icon:  MdOutlineLocalShipping,
        title: " MdOutlineLocalShipping"
    },
    {
        id: "mdq761",
        icon:   MdOutlineElectricRickshaw,
        title: "MdOutlineElectricRickshaw"
    },
    {
        id: "mbo123",
        icon:   MdOutlineToys,
        title: "MdOutlineToys"
    },
    {
        id: "fad765",
        icon: FaThermometerQuarter,
        title: "FaThermometerQuarter"
    },
    {
        id: "fah351",
        icon: FaThermometerThreeQuarters,
        title: "FaThermometerThreeQuarters"
    },
    {
        id: "mdb351",
        icon:  MdBluetoothDrive,
        title: " MdBluetoothDrive"
    },
    {
        id: "h0d625",
        icon: FaAccessibleIcon,
        title: "FaAccessibleIcon"
    },
    {
        id: "loag46",
        icon: ImLocation,
        title: "ImLocation"
    },
    {
        id: "tbs234",
        icon: TbBus,
        title: "TbBus"
    },
    {
        id: "hdjf78",
        icon: BiBody,
        title: "BiBody"
    },
    {
        id: "gas90e",
        icon: BiListCheck,
        title: "BiListCheck"    
    },
    {
        id:"pas983",
        icon: BsAlignCenter,
        title: "BsAlignCenter"

    },
    {
        id: "mad7e5",
        icon: CgSearchLoading,
        title: "CgSearchLoading"
    },
    {
        id: "mdh83h",
        icon: MdAddAPhoto,
        title: "MdAddAPhoto"
    },
]