import styled from 'styled-components/macro'
import { SearchHeader } from '../../components/sidebar/sidebar.styled'

export const LayoutContainer = styled.div``

export const DashboardContainer = styled.div`
    position: relative;
    padding: 5rem 2.5rem;
    padding-bottom: 1rem;
    background: #E5E5E5;
    /* overflow-y: scroll; */
`

export const DashboardWrapper = styled.div`
    .dashboard__cards{
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding-top: 1rem;
    }

    .card__items{
        display: flex;
        justify-content: space-between;
        /* gap: 20px; */
        align-items: center;
        /* padding-right: 1rem; */
    }

    .map__chart, .alert__map{
        display: flex;
        justify-content: space-between;
        gap: 20px;
        padding-top: 1rem;
        /* align-items: center; */
    }
    .alert__map{
        gap: 0;
    }

    .map__chart .react_chart{
        flex: 1.5;
    }

    .map__chart .pie_chart{
        flex: 1;
        background: #FFFFFF;
        border-radius: 8px;
    }

    .pie_chart .analysis{
        /* font-size: 12px !important; */
        font-weight: 600;
    }

    .alert__map .map__item{
        flex: 2;
        margin-left: 50px;
    }

    .alert__map .alert__item{
        flex: 1.8;
        background-color: #fff;
        border-radius: 8px;
    }

    .leaflet-container{
        width: 600px;
        height: 100%;
        overflow-x: hidden;
    }

    .other {
        width: 100%;
        /*z-index: 500;
        position: absolute;
        top: 100px; */
    }

    .other div{
        /* background-color: azure;
        width: 10%; */
    }
`

export const AlertDetail = styled.div`
    display: flex;
    flex-direction: column;

    .alert__header{
        display: flex;
        gap: 20px;
        padding: 15px;
        position: relative;
    }

    .alert__header .title, .item_select{
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.02em;
        color: #505050;
    }

    .alert__pop,  
    .alert__pop .selected__item{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }

    .selected__item .select_icon{
        font-size: .8rem;
        color: #505050;
    }

    .alert__pop ul{
        position: absolute;
        top: 2.8rem;
        left: 3.5rem;
        list-style-type: none;
        box-shadow: 0px 0px 0px 1px rgba(152, 161, 179, 0.1), 
                    0px 15px 35px -5px rgba(17, 24, 38, 0.15), 
                    0px 5px 15px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        padding: 8px;
        background-color: #fff;
    }
    .alert__pop .pop__items{
        display: flex;
        flex-direction: column;
        justify-content:flex-start;
        gap: 5px;
    }

    .pop__items li{
        font-size: 0.8rem;
        font-weight: 500;
        color: #505050;
        padding: 5px;
    }
    .pop__items li:hover{
        background: #CFF4D2;
        border-radius: 4px;
    }

    .alert__details{
        display: flex;
        flex-direction: column;
        padding: 10px 15px;
        background: #E9EDF5;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .alert__details .wrapper{
        width: 100%;
        display: flex;
        justify-content: space-between;
        /* padding-bottom: 10px; */
        padding-top: 10px;
    }

    .alert__desc .device__name{
        font-size: 0.875rem;
        font-weight: 500;
        color: #1A1F36;
        line-height: 20px;
    }

    .alert__desc .device__date{
        font-size: 0.75rem;
        font-weight: 500;
        color: #A5ACBB;
        line-height: 20px;
    }

    .alert__count{
        font-size: 0.875rem;
        font-weight: 700;
        color: #505050;
        line-height: 20px;
    }
`
export const DeviceContainer = styled.div`
    //background:#E5E5E5;
    .header__title{
        display : flex;
        justify-content: space-between;
        padding-left: 10px;
        padding-bottom: 2rem;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.01em;
        color: #000000;
    }

    .back__summary{
        cursor: pointer;
    }
` 

//This styled override all the classNames in the DeviceLabel section
export const DeviceSearch = styled(SearchHeader)`
    background: none;
    justify-content: space-between;
    padding: 0;
    padding-bottom: 1rem;

    .search-item-input{
        height: 30px;
        background: none;
    }

    .search-left, .search-right{
        background: #fff;
        margin: 0;
        padding: 0;
        border-radius: 6px;
    }

    .search-left select{
        border: none;
    }
    .search-left select::placeholder{
        padding-right: 20px;
    }

    .search-item-input::before{
        right: -15px;
    }

    .calender__icon{
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 
                    0px 0px 0px 1px rgba(70, 79, 96, 0.16);
        width: 30px;
        height: 30px;
        border: 1px solid #838191;
        border-radius: 5px;
        padding: 10px;
    }

    .search-item-btn{
        width: 8rem;
        padding: 0 5px;
    }
       
   .icon1{
       font-size: 1.2rem;
   }
`

