import {useState, useEffect} from "react"
import { client } from "../../../utils/axios-instance";
import {useNavigate } from 'react-router-dom'
import { useMutation , useQueryClient} from 'react-query';
import { DevicesData } from "../../modalForm/newDevice/deviceTyped";
import { sortData } from "../../modalForm/editDevice/useMutateData";
import { compareData, compareObjData } from "../../modalForm/editDevice/compareData";
import { GroupData } from "../../modalForm/newGroup/data.typed";

type Submit = (data: DevicesData)=> Promise<unknown>
const useFormSubmission = <T>(data:T | any , url: string, toggleModel:()=>void, err = {}, kind:string, id?: string, oldVal?:any) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [state, setState] = useState("idle");
  const queryClient = useQueryClient()

  console.log("dev", data)
  
  console.log("is submit", isSubmitting)
  const handleSubmit = (e: React.FormEvent)=>{
    e.preventDefault();
    
    if(Object.keys(err).length > 0){
      console.log("there is error", err)
      setIsSubmitting(false)
    }else{
      console.log("er data", err)
      setIsSubmitting(true)
    }
    
  }

  const submitData = async (val: T extends {} ? T : {} )=>{
    let token = JSON.parse(localStorage.getItem("access_token")!)
    let request = client(token)

    console.log('tokenG', token)

    try{
      console.log("inside request")
      const res = await request.post(url, val)
      //console.log(res.data)
      console.log("data sumitted", res.data)
    
      if(res.data !== null) {
        //setMsg(res.data.message)
        setState("success")
        toggleModel()
        // setTimeout(()=>{
        //   navigate(1);
        // }, 2000)
      }
    }catch(err){
      console.log(err);
      setState("failed");
      //toggleModel()
    }
  }

  const editData = async (newG: typeof data)=>{
    let token = JSON.parse(localStorage.getItem("access_token")!)
    let request = client(token)
    let editedData = await compareData(newG, oldVal)
      console.log("edited", editedData)

    console.log('tokenG', token)

    try{
      console.log("inside request")
      const res = await request.put(url + "/" + id, editedData)
      //console.log(res.data)
      console.log("update", res.data)
    
      if(res.data !== null) {
        console.log("updatedii",res.data);
          await queryClient.setQueryData("groups", (oldData:any)=>{
            const prevData = oldData.data.data.groups.filter((d:any)=> d?._id !== id )
            const newData = [...prevData, res.data?.data?.group]
            return {
              ...oldData,
              data:{
                data:{
                  groups:newData.sort(sortData)
                } 
              } 
            }
        });
        //setMsg(res.data.message)
        setState("success")
        toggleModel()
        // setTimeout(()=>{
        //   navigate(1);
        // }, 2000)
      }
    }catch(err){
      console.log(err);
      setState("failed");
      //toggleModel()
    }
  }

  const {mutate} = useMutation("NewGroup", submitData, {
    onSuccess: async (data, variables, context)=> {
      console.log("add",data)
      console.log("variable",variables)
      console.log("contx",context)
        await queryClient.setQueryData("groups", (oldData:any)=>{
          console.log("new", data)
          const newData = [...oldData.data.data.groups, variables]
          return {
            ...oldData,
            data:{
              data:{
                groups:newData.sort(sortData)
              } 
            } 
          }
        });
    },
  })
  

  const {mutate:editGroup} = useMutation("EditGroup", editData, {
    onSuccess: async (data:any, variables, context)=> {
      console.log("add",data)
      console.log("variable",variables)
      console.log("contx",context)
      const old:any = await queryClient.getQueryData("devices")
      // const prevData = old.data.data.groups.filter((d:any)=> d?._id !== id )
      //   await queryClient.setQueryData("groups", (oldData:any)=>{
      //     console.log("new", data)
      //     const newData = [...oldData.data.data.groups, variables]
      //     return {
      //       ...oldData,
      //       data:{
      //         data:{
      //           groups:newData.sort(sortData)
      //         } 
      //       } 
      //     }
      //   });
    },
  })
    useEffect(()=>{
    if(isSubmitting){
      setIsSubmitting(false)
      setState("loading")
      if(kind === "newgroup"){
        setTimeout(()=>{
          mutate(data)
        }, 1000)
      }

      if(kind === "editgroup"){
        setTimeout(()=>{
          editGroup(data)
        }, 1000)
      }
        
    }else{
      setIsSubmitting(false)
    }
        
    },[isSubmitting, data, url, toggleModel, kind, mutate, editGroup]);


  return {handleSubmit, state, isSubmitting}

}

export default useFormSubmission