import styled from 'styled-components'

/***********************************NEW-DEVICE FORM STYLED***********************************/
export const DeviceContainer = styled.div`
    .text{
        width: 300px;
        display: flex;
        flex-direction: column;
        gap:10px;
    }
`

export const Form = styled('form')`
    padding: 0 10px;
    padding-bottom: 10px;
    position: relative;

    .form-content{
        padding: 10px;
        padding-inline: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 20px;
        align-items: center;
    }

    .form-content.auto{
        justify-content: flex-start;
        //background:  rgba(169, 169, 169, 0.5);
        color: rgba(182, 182, 180);
    }

    .form-content.gps{
       padding-top: 1.2rem;
       flex-direction: column;
       align-items: flex-start;
    }

   /* Setting the padding-bottom to 5rem and the height to initial. */
    .form-content.configure{
        padding-bottom: 5rem;
    }

   


    /* Setting the width and height of the form-item class. */
    .form-content .form-item{
        width: 450px;
        height: 50px;
    }

     /* Setting the height of the form-item to 45px and the width to 450px. */
     .form-content.configure .form-item{
        height: initial;
    }

    .form-item{
        color: #838191;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 5px;
        //padding-bottom: 1rem;
    }

   

    .form-item select{
        width: 100%;
        height: 100%;
        border: none;
        background-color: #F3F4F6;
        border-radius: 8px;
        color: #838191;
        font-size: 16px;
        font-weight: 400;
        font-family: "Roboto";
        outline-color: #4D9200;
        padding-left: .8rem;
    }

    .form-item select::placeholder{
        font-size: 16px;
        padding-left: 2rem;
    }


    .form-content .form-item.radio-item{
        padding-bottom: 25px;
        display: flex;
        flex-direction: row;
        justify-content: space-between
    }

    .form-content .form-item.radio-item.config{
        width: 500px;
    }

    .form-item .radio-btn{
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .form-item.radio-item .radio-btn{
        display: flex;
        justify-content:center;
        align-items: center;
        gap: 10px;
    }


    .form-item .radio-btn{

    }

    .form-item .radio-btn .radio-desc{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: .875rem;
        line-height: 20px;
        color:#505050;
    }

    .form-item .check_icon{
        position: absolute;
        right: 1rem;
        bottom: .2rem;
        font-size: 1.5rem;
        color: #4D9200;
    }

    .form-item .check_icon.invalid{
        color: red;
    }

    .form-item .err{
        display: inline-block;
        font-size: .7rem;
        font-weight: 400;
        color: #D8000C;
    }

    .save-device,
    .save-btn{
        width: 150px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 12px;
        background: #4D9200;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #4D9200;
        border-radius: 6px;
        border: none;
        position: absolute;
        right: 20px;
        bottom: 10px;
        color: #fff;
    }

    .save-btn{
        width: 100px;
        position: relative;
        right: 0;
        bottom: 0;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
    }
`

/*****************************************NEW-GROUP FORM STYLED************************************/

export const NewGroupContainer = styled(DeviceContainer)`
   
`
export const NewGroupForm = styled(Form)`
     .form-content.group{
        padding-top: 2rem;
    }

    .save-device.group{
        bottom: -20px;
    }

    .group__btn{
        position: absolute;
        right: 10px;
        top: 18rem;
    }
`

/*****************************************EDIT PROFILE FORM STYLED************************************/
export const ProfileContainer = styled.div`
    width: 100%;
`

export const UserProfileForm = styled(Form)`
    span{
        font-size: 1rem;
    }
    .form-content.signature{
        width: 20rem;
    }

    .upload__tab{
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .upload__tab .tab_item{
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        letter-spacing: -0.02em;
        color: #505050;
    }

    .tab_item.active{
        color: #4D9200;
        border-bottom: 2px solid #4D9200;
    }

    .user__btn{
        position: absolute;
        //top: 20rem;
        bottom: -4rem;
        right: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        padding-bottom: 1rem;
    }
    .user__btn button{
        padding: 6px 15px;
        border: 1px solid #B5B5B5;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
    }

    .user__btn .user_save{
        color: #fff;
        background: #4D9200;
    }
    .user__btn .user_cancel{
        color: #505050;
        background: #fff;
    }
`
export const SwitchProfile = styled.div`
    //width: 30rem;
    display: flex;
    justify-content: space-around;
    padding-inline: 18rem; 
    padding-top: 2rem;
    font-size: .95rem; //the third val Used to close the two borders for both personal & business tabs
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.2px;

    .user__profile{
        width: 300px;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 8px 20px;
        cursor: pointer;
    }

    .user__profile:nth-child(1){
        border-radius: 5px 0px 0px 5px;
        border: 1px solid #E9EDF5;
        //border-radius: 0px 5px 5px 0px;
        //background: #CFF4D2;
    }

    .user__profile:nth-child(2){
        border: 1px solid #E9EDF5;
        border-radius: 0px 5px 5px 0px;
    }

    .user__profile.active{
        background: #CFF4D2;
        color: #4d9200;
    }

    .user__profile span{
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
`