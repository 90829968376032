import styled from 'styled-components'
import { InPutProp } from './typeProps'

export const Input = styled.div<InPutProp>`
display: flex;
position: relative;
input{
    width: 100%;
    padding:0 10px;
    border-radius: 6px;
    border: none;
    background: var(--input-bg);
    &:focus{
        outline-color: var(--item-bg);
    }

    &::placeholder{
        padding: 20px;
        padding-left:0;
        font-size: 1rem;
    }
}

span{
    display: ${({icon})=> icon ? "inline-flex": "none"};
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1rem;
    //bottom: 1rem;
    right: 2.5rem;
    color: inherit;
    width: 27.49px;
    height: 24px;
}

.icon{
   font-size: 1.2rem;
}
`
