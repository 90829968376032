import styled from 'styled-components';

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
 /* padding: 10px; */
`;

export const StyledReportTable = styled.table`
  width: 90%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-left: 2rem;
  margin-top: 2rem;
 /* padding: 10px; */
`;

export const THead = styled.thead`
  background: rgba(247, 249, 252, 0.8);
  backdrop-filter: blur(8px);
  //padding: 10px;
  .table__header{
    padding: 10px;
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #687182;
  }

  .header__icon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    margin: 0;
    padding: 0;
    font-size: 7px;
    color: #687182;
    padding-left: 5px;
  }
`

export const TFoot = styled.tfoot`
  
`;

export const TBody = styled.tbody`
  .table__body{
    padding: 10px;
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #687182;
  }

  .table__body.status .table__body.geo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  } 

  .table__body.status span:nth-child(1){
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: red;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  .table__body.icon{
    font-size: 1.2rem;
    color: rgba(77, 146, 0, 1);
  }

`;

type Background = {
  bg?: boolean,
  status?: "idle" | "active" | "offline" | "inactive"
}
export const TR = styled.tr<Background>`
  border-top: 1px solid #E9EDF5;
  background: ${({bg})=> bg && '#fff' };

  .table__body.status span:nth-child(1){
    background: red;
  }

`;

export const TH = styled.th`
  
`;

export const TD = styled.td`
  .table_body.icons{
    display: flex;
  }

  .body_icons{
    margin-left: 1.3rem;
  }

  .body_icons span{
    cursor: pointer;
  }

  .body_del{
    color: red;
    margin-left: 0.65rem;
  }

  
`;

