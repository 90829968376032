import React, {ReactElement} from 'react'
import { IconProps } from './TabTitle'
export type TabProps = IconProps & {
  children: React.ReactNode,
  title: string
}

const Tab: React.FC<TabProps> = ({ children }) => {
  return <div>{children}</div>
}

export default Tab