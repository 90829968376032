
import styled from 'styled-components'
import {BsInbox, BsX} from 'react-icons/bs'
import {TbDashboard} from 'react-icons/tb'
import {CgToolbox} from 'react-icons/cg'
import { useDeviceTracking } from '../selectRef/hook/useGeofenceContext'

const Container = styled.div`
    width: 100%;
    height: 44.5px;
    display: flex;
    background: #CFF4D2;
`

type HeaderProps = {
  toggle : React.MouseEventHandler<HTMLSpanElement>
}
const BottomHeader = ({toggle}: HeaderProps) => {
  const {panel} = useDeviceTracking()
  return (
    <div className="bottom-header">
      <div className="bottom-header-item pr-2">
          <div className="btt-left-item align">
            <BsInbox className='btt-icon-1'/>
            {panel.deviceSummary?.deviceName}
          </div>
      </div>
      <div className="bottom-header-item">
          <div className="btt-left-item align">
            <TbDashboard className='btt-icon-1'/>
              Sensors
          </div>
      </div>
      <div className="bottom-header-item">
          <div className="btt-left-item align">
            <CgToolbox className='btt-icon-1'/>
              Services
          </div>
          <span className="bottom-close-icon" onClick={toggle}>
            <BsX className='btt-header-icon'/>
          </span>
      </div>
    </div>
  )
}

export default BottomHeader