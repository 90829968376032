import styled from 'styled-components/macro'

export const HistoryContent = styled.div`
    padding: 5px 10px;

    .history__content, 
    .history__content_item{
        display: flex;
        flex-direction: column;
        gap: 10px;
        /* justify-content: center;
        align-items: center; */
    }

    .history__content .history__content_item{
        gap: 0;
        flex-direction: row;
        justify-content: space-between;
    }

    .history__content .history__option{
       padding-left: 12rem;
       color: var(--item-bg);
       cursor: pointer;
    }

    .history__content .history__option.change{
        color: #7DE495;
    }

    .history__content .stop__time{
        display: flex;
        gap: 30px;
    }

    .stop__time .stop__time_desc, 
    .history__content .stop__time_road{
        display: flex;
        align-items: center;
        gap: 15px;
        font-size: 0.7rem;
        font-weight: 500;
        letter-spacing: 0.2px;
        color: #505050;
    }

    .history__content_item .input__item,  
    .history__content .show__ctr{
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .input__item input, 
    .stop__input input{
        padding: 10px 10px;
        background: #F3F4F6;
        border-radius: 5px;
        border: none;
        color: #838191;
    }

    .history__content .stop__input, 
    .history__content .stop__time_road, 
    .history__content .show__ctr{
        width: 16rem;
        padding-left: 3.8rem;
    }

    .history__content .stop__input{
        width: 15rem;
    }

    .history__content .show__ctr{
        width:18.5rem;
        padding-left: 3.6rem;
    }

    .show__ctr .show__download{
        display: grid;
        place-items: center;
        width: 30px;
        height: 30px;
        aspect-ratio: 1;
        background: #E9EDF5;
        border-radius: 5px;
    }

    .show__ctr .show__history{
        display: grid;
        place-items: center;
        width: 15rem;
        height: 30px;
        background: #4D9200;
        box-shadow: 
                    0px 1px 1px rgba(0, 0, 0, 0.1), 
                    0px 0px 0px 1px #4D9200;
        border-radius: 6px;
        font-size: 0.8rem;
        font-weight: 500;
        color: #fff;
        cursor: pointer;
    }

    .history__content .stop__input input{
        padding: 10px 5px;
    }

    .show__history__item{
        display: flex;
        gap: 15px;
        align-items: center;
        font-size: 0.8rem;
    }
    
`