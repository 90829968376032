import React, { useState, useEffect, useMemo, memo,useRef } from "react";

import { Marker, MarkerProps, Tooltip, Popup } from "react-leaflet";
import ReactDOM from "react-dom/client";
import L, { marker, tooltip } from "leaflet";
import useDraggable from "../maps/useDraggable";
import { root } from "../..";


interface Props extends MarkerProps {
  /**
   * Options to pass to the react-lefalet L.divIcon that is used as the marker's custom icon
   */
  iconOptions?: L.DivIconOptions;
  children: React.ReactChild | Iterable<React.ReactNode>,
  toolTip?: boolean,
  showPop?: boolean
}

//const root =ReactDOM.createRoot()

/**
 * React-leaflet marker that allows for fully interactive JSX in icon
 */
const JSXMarker = React.forwardRef<L.Marker, Props>(
  ({ children, iconOptions, toolTip = true, showPop = true, ...rest }, refInParent) => {
    const [ref, setRef] = useState<L.Marker| null>();
    const node = useRef<L.Marker>(null)
    const  {draggable, toggleDraggable, handleMakerPosition, markerRef, position} = useDraggable()

    //console.log(L.Marker)
    //const node = useMemo(() => (ref ? ReactDOM.createRoot(ref.getElement()!) : null),[ref]);
   const newNode = ref ?   ReactDOM.createRoot(ref.getElement() as HTMLElement) : null
    //console.log(ref?.getLatLng())
    return (
      <>

            {/* <Marker
              {...rest}
              ref={(r) => {
                setRef(r as L.Marker);
                if (refInParent) {
                  // @ts-expect-error fowardref ts defs are tricky
                  refInParent.current = r;
                }
              }}
              icon={L.divIcon(iconOptions)}
            > 
            {ref && node?.render(children)!}
            </Marker> */}
        {useMemo(
          () => (
            <Marker
              {...rest}
              ref={(r) => {
                setRef(r as L.Marker);
                // console.log(r?.getLatLng())
                if (refInParent) {
                  // @ts-expect-error fowardref ts defs are tricky
                  refInParent.current = r;
                }
              }}
              icon={L.divIcon(iconOptions)}
              eventHandlers={handleMakerPosition}
              //draggable={draggable}
            >
              {
                toolTip 
                ? ( <Tooltip direction="top" offset={[-20, -40]}>
                        Click any location on the map to set your POI
                    </Tooltip>
                  ) 
                : null
                
  
              }
              {
                showPop 
                ? (   <Popup minWidth={90} className="popup_new">
                        <div>
                          This a pop
                        </div>
                      </Popup>
                  )
                : null
              }
             
            </Marker>
          ),
          [iconOptions, refInParent, rest, handleMakerPosition, showPop, toolTip] 
        )}
        {ref && newNode?.render(children)}
      </>
    );
  }
);

export default memo(JSXMarker)