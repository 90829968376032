import styled from 'styled-components'

export const DeviceContainer = styled.div`
  width: 310px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-size: 0.875rem;

    /* .header-content{
        width: 310px;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        font-size: 0.875rem;
    } */

  .header-content-items{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 20px;
  }

  /* .header-content-item.sensor{
      padding-left: 10px;
  } */
  .header-inner{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .header-content-items input{
    width: 0.9rem;
    height: 0.9rem;
  }
  .header-content-items .inset{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 50%;
    background-color: #505050;
    width: 1rem;
    height: 1rem;
  }

`
export const CenterLoadIndicator = styled(DeviceContainer)`
  justify-content: center;
  align-items: center;
  height: 100%;
`
type MainShow = {
  darkTrans: string
} 

export const MainContentContainer = styled.div`
  max-height: 280px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  z-index: 10;

  &::-webkit-scrollbar{
      background: #fff;
      width: 8px;
    }
   
  &::-webkit-scrollbar-thumb {
    background: #E9EDF5;
    border-radius: 20px;
  }

  .edit__puff{
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
  }
`

export const MainContent = styled.div<MainShow>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font-size: 0.85rem;
    padding: 10px;
    padding-right: 0;
    padding-bottom: 5px;
    border-bottom: 1px solid #E9EDF5;
    background: ${({darkTrans})=>darkTrans ? darkTrans: "" };  
    width: 360px;
    /* height: 300px; */
    //cursor: inherit;

    /* .main-content.grey{
      background: red;
    } */

    .main-content-inner{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      font-weight: 500;
      color: #505050;
      position: relative;
      /* width: 450px; */
      cursor: inherit;
    }

    .main-content-inner.col2{
      width: 200px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      padding-left: 10px;
      cursor: inherit;
      /* padding-top: 5px; */
    }

    /* .main-content-inner{
      font-weight: 500;
      color: #505050;
      position: relative;
    } */

    .main-content-inner input[type="checkbox"]{
      width: 0.9rem;
      height: 0.9rem;
    }

    .main-content-inner .inset{
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-radius: 50%;
      background-color: var(--item-bg);
      width: 0.8rem;
      height: 0.8rem;
    }

    .header-content-items .inset{
      background-color: #505050;
      color: #fff;
    }

    .main-content-inner.col2 span:nth-child(2){
      font-size: 0.63rem;
      font-weight: 300;
    }

    .main-content-inner .main-icon{
      color: var(--item-bg);
      font-size: 1.3rem;
      margin-left: 5px;
    }

    .main-content-inner .inner-icon{
      font-size: 1.1rem;
    }

    .main-content-inner .dev__unit{
      display: inline-flex;
      width: 4.5rem;
    }
`