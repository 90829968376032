import React from 'react'
import { GridLinkContainer } from './grid.styled'

type LinkProp = {
    setLink: ()=> void
}
const GridLink = ({setLink}: LinkProp) => {
  return (
    <GridLinkContainer onMouseLeave={setLink}>
        <div className="link__wrapper">
            <div className="link_item" >
                <img src={process.env.PUBLIC_URL+"/assets/images/energy.png"} alt="Energy"/>
            </div>
            <div className="link_item">
                <img src={process.env.PUBLIC_URL+"/assets/images/connect.png"} alt="Connect"/>
            </div>
           <div className="link_item">  
                <img src={process.env.PUBLIC_URL+"/assets/images/agric.png"} alt="Agric"/>
           </div>
           <div className="link_item">
                <img src={process.env.PUBLIC_URL+"/assets/images/fleet.png"} alt="Fleet"/>
            </div>
        </div>
    </GridLinkContainer>
  )
}

export default GridLink