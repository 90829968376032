import React from 'react'
import Card from '../../components/card/Card'
import Header from '../../components/header/Header'
import Layout from '../../components/layout/Layout'
import useLang from '../../language/useLang'
import {Right, Left, Wrapper, Image} from '../forgetPassword/forgetpassword.styled'
import NewForm from './NewForm'

const NewPassword = () => {
    const {handleLanguage} = useLang()
  return (
    <Layout>
        <Header handleLanguage={handleLanguage}/>
        <Wrapper>
            <Right>
                <Image>
                  <img  
                    src={process.env.PUBLIC_URL + "/assets/images/forgot.png"} 
                    alt="tracking"
                    loading='lazy'
                  />
                </Image>
            </Right>
            <Left>
              <Card>
                <NewForm/>
              </Card>
            </Left>
        </Wrapper>
    </Layout>
  )
}

export default NewPassword