
import  styled from 'styled-components/macro'


export const HeaderContainer = styled.div`
    /* width: 100%; */
    /* max-width: 100%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--primary-bg);
    /* padding: 0 10px; */
    height: calc(60px/var(--size-divisor));

    @media only screen and (min-width: 768px) {
        /* width: 450px; */
        padding: 0 2rem;
    }
`
export const Logo = styled.div`
    width: 95px;
    height: 25px;
    padding: 0;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`
export const Language = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    label{
        display: inline-block;
        font-size: .85rem;
    }

    select{
        border-radius: 4px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 
                    0px 0px 0px 1px rgba(70, 79, 96, 0.16);
    }
`

type PositionProps = {
    top?: number,
    bottom?: number,
    left?: number,
    right?: number
    wid?: string,
    height?: string
}

export const PositionItem = styled.div<PositionProps>`
    display: grid;
    position: absolute;
    width: ${({wid})=> wid && wid};
    height: ${({height})=> height && height};
    z-index: 600;
    top: ${({top})=> top && top + 'rem'};
    right: ${({right})=> right && right + 'rem'};
    bottom: ${({bottom})=> bottom && bottom + 'rem'};
    left: ${({left})=> left && left + 'rem'};

   .toggle-icon{
       font-size: 1.5rem;
       background-color: #fff;
       margin-left: 10px;
   }
`

export const LeftPositionItem = styled(PositionItem)`
    z-index: 500;
`
export const BottomPositionItem = styled(PositionItem)`
    z-index: 550;
`
export const HeaderPositionItem = styled(PositionItem)`
    position: fixed;
    z-index: 700;
`
   
/**********************AuthHeader Styled******************************************* */
export const  AuthContainer = styled(HeaderContainer)`
    /* width: 100%; */
    position: relative;
    padding: 0 3.125%;
    ${Logo}{
     
    }
` 
/***********************UserSetting Styled******************************************* */
export const SettingContainer = styled.div`
    display: grid;
    background: #fff;
    border-radius: 6px;
    position: absolute;
    right: 1rem;
    top: 3rem;
    box-shadow: 
        0px 0px 0px 1px rgba(152, 161, 179, 0.1), 
        0px 15px 35px -5px rgba(17, 24, 38, 0.15), 
        0px 5px 15px rgba(0, 0, 0, 0.08);

    width: 16rem;
    max-width: 16rem;

    .setting__container{
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .setting__container .user__details{
        display: flex;
        flex-direction: column;
        gap: 8px;
        border-bottom: 1px solid #eee;
        padding-left: 5px;
    }

    .user__details .user_header{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .user_header span{
        display: inline-flex;
        width: 30px;
        height: 30px;
    }

    .user_header .name{
        background: #4D9200;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        font-size: 14px;
    }

    .user_header .close{
        background: rgba(236, 28, 36, 0.15);
        justify-content: center;
        align-items: center;
        border-top-right-radius: 5px;
        width: 40px;
        height: 40px;
        font-size: 1.5rem;
    }

    .user__details .user_name{
        font-size: 1rem;
        font-weight: 700;
        font-weight: "Roboto";
        color: #505050;
        line-height: 25px;
        letter-spacing: 0.2px;
    }

    .user__details .user_email{
        font-size: 14px;
        font-weight: 500;
        color: #505050;
        padding-right: 15px;
        padding-bottom: 10px;
    }

    .setting__container .user__role{
        font-size: 14px;
        color: #505050;;
        font-weight: 500;
        padding-left: 5px;
        padding-bottom: 10px;
    }

    .user__role .role_title{
        color: rgba(7, 7, 7, 0.4);
    }

    .user__role .role_name{
        padding-left: 10px;
    }

    .setting__container .edit__user{
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .edit_profile{
        display: flex;
        align-items: center;
        padding-left: 5px;
        cursor: pointer;
    }
    .edit_profile .edit_item,
    .edit_profile .logout_item{
        font-size: 1.5rem;
        color:   #4D9200;
    }

    .edit_profile .edit_title,
    .edit_profile .logout_title{
        font-size: 14px;
        font-weight: 500;
        color:   #4D9200;
        padding-left: 10px;
        letter-spacing: 0.2px;
    }

    .edit_profile .logout_item{
        color: #EC1C24;
    }

    .edit_profile .logout_title{
        color: #EC1C24;
    }

`

export const LeftItemHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 60px;
    padding: 20px;
`
export const List = styled.ul`
    display: flex;      
    align-items: center;
    gap: 10px;
    list-style-type: none;
    font-family: 'Roboto';
    line-height: 32px;
    letter-spacing: var(--letter-spacing);

    li > div,  li > div > div{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    span{
        display: inline-flex;
        padding-left: 5px;
        font-size: 0.85rem;
    }

    .left-list-item{
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: initial;
    }

    .left-list-item.active{
        border-bottom: 2px solid var(--item-bg);
        color: var(--item-bg);
    }

    /* .left-list-item.hover-contain{
        position: relative;
    } */

    .list-icon{
        font-size: 1.2rem;
    }

    .list-icon.right{
        font-size: 1rem;
        margin-left: 5px;
    }

    .setting{
        cursor: pointer;
    }
`

export const HoverContainer = styled.div`
    /* display: grid; */
    /* z-index: 750; */
    background: #fff;
    box-shadow: var(--shadow);
    border-radius: 6px;
    width: 12rem;
    position: absolute;
    top: 45px;
    .hover-list{
        list-style-type: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5px;
        font-size: 0.875rem;
        padding: 10px 0;
    }

    .hover-item{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        padding-inline: 10px;
        border-radius: 4px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 25px;
        letter-spacing: var(--letter-spacing);
    }

    .hover-item:hover{
        background: #CFF4D2;
        /* width: 90%; */
    }
`