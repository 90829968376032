import React from 'react'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import PopUp from '../../popItems/PopUp'
const popData = [
    {
        col: "#4d9200",
        desc: "Edit Geo"
    },
    {
        col: "#D1293D",
        desc: "Delete Geo"
    }
]
type POIProps = {
    item: string,
    show: boolean
}
const POIDetails = ({item, show}: POIProps) => {
  return (
    <div className="geo__point">
        <div className="geo__item">
            <input type="checkbox"/>
            <span className='geo_name'>{item}</span>
        </div>
        
        <div className="geo_pop">
            {
                show ? <PopUp items={popData}/> : <BiDotsVerticalRounded className='icon'/>
            }
        </div>
    </div>
  )
}

export default POIDetails