import React, {useEffect, useState} from 'react'
import Body, { BodyItems } from './Body'
import Header from './Header'
import { StyledReportTable, StyledTable, TBody, THead } from '../tableStyled'
import { useQueryClient } from 'react-query'
import { StringDecoder } from 'string_decoder'



type TableProps = {
  loading: boolean,
  posts: any[]
  reportType: string
}
const scheduleData=[
    {
        id: "jak12rrtdhfkjgs",
        name:"Geohjdf783",
        type:"overspeed",
        format:"pdf",
        size:"50KB",
        email:"adehjg123fg12@gmail.com"
    },
    {
        id: "jkgndnkf878fvhuvsu",
        name:"GEO-kjkjdk125-JMk",
        type:"overspeed",
        format:"pdf",
        size:"50KB",
        email:"adelantyg123fg12@gmail.com"
    },
    {
        id: "jakdhhjdf783fkjgs",
        name:"Geohjdf783",
        type:"overspeed",
        format:"pdf",
        size:"50KB",
        email:"adehjg123fg12@gmail.com"
    },
    {
        id: "jkgndfvgh56213huvsu",
        name:"GEO-kjkjdk125-JMk",
        type:"overspeed",
        format:"pdf",
        size:"50KB",
        email:"adelantyg123fg12@gmail.com"
    },
    {
        id: "jajkas9236kdhfkjgs",
        name:"Geohjdf783",
        type:"overspeed",
        format:"pdf",
        size:"50KB",
        email:"adehjg123fg12@gmail.com"
    },
    {
        id: "jkgn12hsdjfhdfvhuvsu",
        name:"GEO-kjkjdk125-JMk",
        type:"overspeed",
        format:"pdf",
        size:"50KB",
        email:"adelantyg123fg12@gmail.com"
    }
]

const savedData=[
    {
        id: "jakdhfkjgs",
        title:"One week",
        type:"overspeed",
        format:"pdf",
        device:"Apapa cold room",
        geofence:"21.6KM",
      
    },
    {
        id: "jghf54dfvhuvsu",
        title:"One week",
        type:"overspeed",
        format:"pdf",
        device:"Apapa cold room",
        geofence:"21.6KM",
    },
    {
        id: "j98987ggghhfkjgs",
        title:"One week",
        type:"overspeed",
        format:"pdf",
        device:"Apapa cold room",
        geofence:"21.6KM",
      
    },
    {
        id: "jjkggyudfvhuvsu",
        title:"One week",
        type:"overspeed",
        format:"pdf",
        device:"Apapa cold room",
        geofence:"21.6KM",
    },
    {
        id: "jakd89980hfkjgs",
        title:"One week",
        type:"overspeed",
        format:"pdf",
        device:"Apapa cold room",
        geofence:"21.6KM",
      
    },
    {
        id: "jkgndf1212vhuvsu",
        title:"One week",
        type:"overspeed",
        format:"pdf",
        device:"Apapa cold room",
        geofence:"21.6KM",
    }
]

const saved = ["title",  "type", "format" , "devices", "geofences", "schedule"]
const schedule = ["name",  "type", "format" , "size", "email", "sent"]
const ReportTable = ({loading, posts, reportType}: TableProps) => {

  //That data item is to be implemented
  return (
    <>
    {
      loading 
      ?<div>Loading...</div> 
      : (
          <StyledReportTable>
            <THead>
                {
                    reportType === "saved" 
                    ? <Header headers={saved} showInput={false}/> 
                    : <Header headers={schedule} showInput={true}/>
                }
             
            </THead>
            
            <TBody>
              {
                reportType ===  "saved" &&(
                    savedData?.map((item:any, index:number)=> (<Body 
                                        title={item.title}
                                        type={item.type}
                                        format={item.format}
                                        device={item.device}
                                        geofence={item.geofence}
                                        reportType={reportType}
                                        key={item.id} />
                    ))
                )
               
              }
               {
                reportType ===  "schedule" &&(
                    scheduleData?.map((item:any, index:number)=> (<Body 
                                        name={item.name}
                                        type={item.type}
                                        format={item.format}
                                        size={item.size}
                                        email={item.email}
                                        reportType={reportType}
                                        key={item.id} />
                    ))
                )
               
              }
            </TBody>
          </StyledReportTable>
      )
    }
    </>
  )
}

export default  ReportTable