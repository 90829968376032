import { icon } from 'leaflet'
import React from 'react'
import { IconType } from 'react-icons/lib'
import { useNewGeoUser } from '../selectRef/hook/useGeofenceContext'
import usePOIForm from '../selectRef/hook/usePOIForm'
import { icons } from './iconData'
export type IconProp = {
       icon: IconType | null,
}

export type SelectIconProp = IconProp &{
       title: string
}
export type SelectProp = {
       setIcon: React.Dispatch<React.SetStateAction<IconProp>>
       toggleSelect: React.Dispatch<React.SetStateAction<boolean>>
}
const SelectItems = ({setIcon, toggleSelect}:SelectProp) => {
       const {setShowItem} = useNewGeoUser()
       const {setPoiData} = usePOIForm()
       const handleItem = ({icon, title}:SelectIconProp)=>{
              toggleSelect(prev=> !prev)
              setIcon({icon})
              setPoiData(prev=>{
                     return {
                            ...prev,
                            iconTag: title,
                            customIcon: title
                     }
              })
       }
  return (
    <div className='option-container'>
       {
              icons.map(icn=> <div className="options" key={icn.id} onClick={()=> handleItem({icon: icn.icon, title:icn.title})}><icn.icon/></div>)
       }
    </div>
  )
}

export default SelectItems