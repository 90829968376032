import React from 'react'
import { memo } from 'react';
import { useState } from 'react';
import {RiArrowDownSLine} from 'react-icons/ri'
import { GroupData } from '../modalForm/newGroup/data.typed';
import { MultiSelectContainer } from '../multiselect/multiStyled';
import { Data, MultipleSelectProps } from '../multiselect/multiTyped';
import { useSelectOption } from '../multiselect/useSelectContext';
import { DevicesData } from '../selectRef/hook/useGeofenceContext';
//import { useSelectOption } from '../multiselect/useSelectContext';


const data = [
    {
        id: 1,
        title: "Temperature"
    },
    {
        id: 2,
        title: "Speed "
    },
]

const MultiGroupDevice = ({ options, setOption, label}: MultipleSelectProps<GroupData>) => {
    const [open, setOpen] = useState(false);
    const {selected, toggleOption} = useSelectOption()
    const handleToggleData = ({id, title}: Data)=>{
        toggleOption({title: title })
         setOption((prev:any)=>{
            return {
                ...prev,
                devices: prev?.devices.includes(id) 
                    ? prev.devices.filter((item:string) => item !== id) 
                    : [...prev.devices, id]
            }
        })
    } 
    console.log(selected)
    return (
        <MultiSelectContainer>
            <div className="c-multi-select-dropdown__selected" onClick={()=>setOpen(!open)}>
                <div className="selected__options">
                    {
                        selected.length !== 0 
                        ? selected?.map((d: string) =><span key={d}>{d}</span>) 
                        : <span>{label ? label : "Add a label to the element"}</span>
                    }
                </div>
                <span>
                    <RiArrowDownSLine/>
                </span>
            </div>
            {
                open ? 
                (
                    <ul className="c-multi-select-dropdown__options" onMouseLeave={(e:React.MouseEvent<HTMLUListElement>)=>setOpen(!open)}>
                        {options?.map((option:any, index:number )=> {
                            const isSelected = selected.includes(option?.deviceName);

                            return (
                                <li className="c-multi-select-dropdown__option" key={index}>
                                    <input 
                                        type="checkbox" 
                                        checked={isSelected} 
                                        className="c-multi-select-dropdown__option-checkbox"   
                                        onClick={()=> handleToggleData({id: option._id, title: option?.deviceName})} 
                                        readOnly
                                    />
                                    <span>{option?.deviceName}</span>
                                </li>
                            )
                        })}
                    </ul>
                )
                : null
            }
            
        </MultiSelectContainer>
    )
}

export default memo(MultiGroupDevice)