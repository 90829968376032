import React from 'react'

const Canvas2D = () => {
  return (
    <div style={{textAlign: 'center', paddingTop: '5rem'}}>
        2dCanvas
        <h1>2dCanvas</h1>
        <h1>2dCanvas</h1>
        <h1>2dCanvas</h1>
        <h1>2dCanvas</h1>
        <h1>2dCanvas</h1>
        <h1>2dCanvas</h1>
    </div>
  )
}

export default Canvas2D