import React from 'react'
import { AccordionContainer } from '../accordion.styled'
import { RiNodeTree } from 'react-icons/ri'
import { BiChevronDown } from 'react-icons/bi'
import PopUp from '../../popItems/PopUp'
import { popItems } from '../AccordionItem'
import { HiDotsVertical } from 'react-icons/hi'
import useAccordion from '../useAccordion'
import { MdOutlineThermostat } from 'react-icons/md'
import { usePopModal } from '../../selectRef/hook/useGeofenceContext'
import Modal from '../../modal/Modal'
import Delete from '../../delete/Delete'
import EditGroup from '../../modalForm/editGroup/EditGroup'
import { AiOutlineBlock } from 'react-icons/ai'
import General from './General'
import Combined from './Combined'
import InnerItem from '../InnerItem'
import { TwoLayerProp } from '../groupProps.typed'
import useDeviceContent from '../../device/useDeviceContent'

const TwoLevelLayer = ({item, popIdx, getItemId}: TwoLayerProp) => {
    const {
        handleCheckAll, 
        toggleItem, 
        setPop,
        pop, 
        active,
        isCheckedAll,  
        ulRef
    } = useAccordion()
    const  {modalPop,setShow, show} = usePopModal()
    
    const toggleModalPop = (event: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
        event.preventDefault();
        setShow((prev)=> !prev);
    }

    const getPopIndex = (id: number)=>{
        setPop(id)
    }
  return (
    <AccordionContainer>
        {
            item && (
                item.type === "combined" ?  
                <Combined 
                    handleCheckAll={handleCheckAll} 
                    toggleItem={toggleItem} 
                    setPop={setPop} 
                    getPopIndex={getPopIndex} 
                    pop={pop}
                    idx={popIdx}
                    groupName={item.name}
                    key={item._id}
                    getItemId={getItemId}
                /> 
            : <General 
                    handleCheckAll={handleCheckAll} 
                    toggleItem={toggleItem} 
                    setPop={setPop} 
                    getPopIndex={getPopIndex} 
                    pop={pop}
                    idx={popIdx}
                    groupName={item.name}
                    key={item._id}
                    getItemId={getItemId}
                />
            )
        }
       
        <ul className={`accordion__item ${active ? 'active': ''} `} ref={ulRef}>
            {
                item?.devices?.length !== 0 
                ? item?.devices?.map((d:any)=>  <InnerItem isCheckAll={isCheckedAll} device={d.deviceName} key={d?._id}/>)
                : null
            }
          
        </ul>
    </AccordionContainer>
  )
}

export default TwoLevelLayer