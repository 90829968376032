export type UserCredential = {
    firstName: string,
    lastName:string,
    email: string,
    phone:string,
    password: string
}

export type UserError ={
  [s:string]: string
}

export default function validateSignUp(values: UserCredential): UserError {
  let errors: UserError = {};
  
    if (!values.email) {
      errors.email = 'Email required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Email address is invalid';
    }
    
    if (!values.password) {
      errors.password = 'Password is required';
    } else if (values?.password.length < 8) {
      errors.password = 'password must be of 8 characters and above';
    }

    if (!values.firstName) {
        errors.firstName = 'Password is required';
    } else if (values?.firstName.length < 4) {
        errors.firstName = 'name must be of 4 characters and above';
    }

      if (!values.lastName) {
        errors.name = 'Password is required';
    } else if (values?.lastName.length < 4) {
        errors.lastName = 'name must be of 4 characters and above';
    }

    if (!values.phone) {
        errors.phone = 'Password is required';
    } else if (values?.phone.length <11 ) {
        errors.phone = 'phone number must be of 11 characters and above';
    }

    return errors;
}
    