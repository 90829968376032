import React, { useState } from 'react'
import { FilterItem } from './sidebar.styled'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import FilterPop from './FilterPop';
import useToggle from '../../features/toggle';


/**
 * FilterProps is an object with a data property that is an array of objects with id, item, and icon
 * properties, and a setOpen property that is a function that takes a MouseEvent and returns void.
 * @property {{id: number, item: string, icon: React.ReactNode}[]} data - {id: number, item: string,
 * icon: React.ReactNode}[]
 * @property setOpen - React.MouseEventHandler<HTMLDivElement>
 */
type FilterProps = {
    data?: {id: number, item: string, icon: React.ReactNode}[],
    setOpen: React.MouseEventHandler<HTMLDivElement>
}
const Filter = ({data, setOpen}: FilterProps) => {
    const [pop, setPop] = useState(false)

    const showPop = (e: React.MouseEvent<HTMLLIElement, MouseEvent>)=>{
       e.preventDefault()
        if(e.currentTarget.textContent === "Status"){
            setPop(prev=> !prev)
        }
        return false;
    //    console.log(e.currentTarget.textContent)
    }
  return (
   <FilterItem>
       <div className="header">
           <div className='filter-item'>
               <FilterAltOutlinedIcon className='filter-item-icon'/>
               <span>Filter</span>
           </div>
           <div className='filter-close' onClick={()=> setOpen}>
                <CloseOutlinedIcon className='filter-item-icon'/>
           </div>
       </div>
       <div className='main-list'>
            <ul>
                {
                    data?.map((list)=> (
                        <li key={list.id}>
                            <span onMouseEnter={showPop}>{list.item}</span>
                            {list?.icon}
                           { list.item === "Status"
                                ? (pop && <FilterPop/>) 
                                : null
                            }
                        </li>
                    ))
                }
            </ul>
       </div>
   </FilterItem>
  )
}

export default Filter