import React, { useState } from 'react';
import { InputContainer } from './float.styled';


type TextProps={
  type?: string,
  label: string
  read?: boolean,
  name?: string
  val?: string,
  dval?: string,
  onChange?: React.ChangeEventHandler
}
function TextInput(
  {  type = 'text', 
    label , 
    name, 
    val="",
    dval="" ,
    read = false, 
    onChange
  }:TextProps) 
  {

  const [value, setValue] = useState('');


  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
  }

  return (
    <InputContainer>
      <input 
        className="float__input" 
        type={type} 
        value={val} 
        onChange={onChange} 
        readOnly={read}
        name={name}
      />
      <label className={val && "filled"} htmlFor='name'>
        {label}
      </label>
    </InputContainer>
  );
}

export default TextInput