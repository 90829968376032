import React from 'react'
import TextInput from '../../floatInput/FloatInput'
import { Select, Option } from '../../selectRef/select/index'

const DeviceConfiguration = () => {
  return (
    <div className="form-content configure">
        <div className="form-item radio-item config">
            <div className="radio-btn">
                <input type='checkbox' id='server' name='server' value='server'/>
                <span className="radio-desc">Set ServerIP</span>
            </div>
            <div className="radio-btn">
                <input type='checkbox' id='gprs' name='gprs' value='gprs'/>
                <span className="radio-desc">Activate Device GPRS</span>
            </div>
            <div className="radio-btn">
                <input type='checkbox' id='time' name='time' value='time'/>
                <span className="radio-desc">Set Current Time</span>
            </div>
        </div>
        <div className="form-item">
            <div className="radio-btn pl-0 pb-0">
                <input type='checkbox' id='interval' name='interval' value='interval'/>
                <span className="radio-desc">Set Update Interval</span>
            </div>
            <TextInput label="Enter Update interval"/>
        </div>
        <div className="form-item">
            <div className="radio-btn pl-0 pb-0">
                <input type='checkbox' id='center' name='center' value='center'/>
                <span className="radio-desc">Set Center Number</span>
            </div>
            <TextInput label="Enter Center number"/>
        </div>
        <div className="form-item">
            <div className="radio-btn pl-0 pb-0">
                <input type='checkbox' id='sos' name='sos' value='sos'/>
                <span className="radio-desc">Set SOS Number</span>
            </div>
            <TextInput label="Enter SOS Number"/>
        </div>
        <div className="form-item">
            <div className="radio-btn pl-0 pb-0">
                <input type='checkbox' id='zone' name='zone' value='zone'/>
                <span className="radio-desc">Time Zone Settings</span>
            </div>
            <div style={{height: '45px'}}>
                <Select placeholder='Select Time Zone'>
                        <Option value='23'>UTC</Option>
                        <Option value='23'>UTC</Option>
                        <Option value='23'>UTC</Option>
                </Select>
            </div>
        </div>
        <div className="form-item chip">
            <TextInput label="Chip Number"/>
        </div>
    </div>
  )
}

export default DeviceConfiguration