import React, {useState} from 'react'
import Table from '../../components/table/Table'
import { DeviceContainer, DeviceSearch } from './dashboard.styled'

import Modal from '../../components/modal/Modal';
import NewDevice from '../../components/modalForm/newDevice/NewDevice';
import DeviceLabel from '../../components/devicesearch-dashboard/DeviceLabel';
import Pagination from '../../components/pagination/Pagination';
import usePagination from '../../components/selectRef/hook/usePagination';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
const tableData = [
    {
      status: "A",
      rowNumber: 1,
      deviceType: "Cold Room",
      deviceName: " Ikorodu Factory deep freezer",
      imei:  238900671567892,
      simIccd: 567865438902,
      phone: "09124786598",
      lastConnected: "10th Feb 2022"
    },
    {
      status: "A",
      rowNumber: 2,
      deviceType: "Generator",
      deviceName: " Ikorodu Factory deep freezer",
      imei:  238900671567892,
      simIccd: 567865438902,
      phone: "09124786598",
      lastConnected: "10th Feb 2022"
    },
    {
      status: "A",
      rowNumber: 3,
      deviceType: "Truck",
      deviceName: " Ikorodu Factory deep freezer",
      imei:  238900671567892,
      simIccd: 567865438902,
      phone: "09124786598",
      lastConnected: "10th Feb 2022"
    },
    {
      status: "O",
      rowNumber: 4,
      deviceType: "Cold Room",
      deviceName: " Ikorodu Factory deep freezer",
      imei:  238900671567892,
      simIccd: 567865438902,
      phone: "09124786598",
      lastConnected: "10th Feb 2022"
    },
    {
      status: "I",
      rowNumber: 5,
      deviceType: "Cold Room",
      deviceName: " Ikorodu Factory deep freezer",
      imei:  238900671567892,
      simIccd: 567865438902,
      phone: "09124786598",
      lastConnected: "10th Feb 2022"
    },
    {
      status: "I",
      rowNumber: 6,
      deviceType: "Cold Room",
      deviceName: " Ikorodu Factory deep freezer",
      imei:  238900671567892,
      simIccd: 567865438902,
      phone: "09124786598",
      lastConnected: "10th Feb 2022"
    },
    {
      status: "I",
      rowNumber: 7,
      deviceType: "Cold Room",
      deviceName: " Ikorodu Factory deep freezer",
      imei:  238900671567892,
      simIccd: 567865438902,
      phone: "09124786598",
      lastConnected: "10th Feb 2022"
    }, 
    {
      status: "I",
      rowNumber: 8,
      deviceType: "Cold Room",
      deviceName: " Ikorodu Factory deep freezer",
      imei:  238900671567892,
      simIccd: 567865438902,
      phone: "09124786598",
      lastConnected: "10th Feb 2022"
    },
  
  ]
const url='https://jsonplaceholder.typicode.com/users'

const Devices = () => {
    const [open, setOpen] = useState(false)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    console.log()
    const {
        onNext, 
        onPrev, 
        changeRow, 
        currentPage, 
        currentPosts, 
        loading, 
        totalPages
    } = usePagination()

    const toggleOpen = ()=>{
        setOpen(prev=> !prev)
    }


    console.log("inside Devicetable", currentPosts)
  return (
    <DeviceContainer>
        <div className="header__title">
          {searchParams.get('id')}
           <div className="back__summary" onClick={()=>navigate(-1)}>
            <BiArrowBack/>
           </div>
        </div>
        <DeviceSearch>
            <DeviceLabel toggleOpen={toggleOpen}/>
        </DeviceSearch>
        {
          loading 
          ? <div>loading</div>
          : (
            <>
               <Table posts={currentPosts} loading={loading}/>
                <Pagination 
                    page={currentPage}  
                    total={totalPages} 
                    next={onNext} 
                    prev={onPrev} 
                    loading={loading} 
                    changeRow={changeRow} 
                />
            </>
          )
        }
       
        <Modal 
            open={open} 
            toggle={toggleOpen} 
            setOpen={setOpen} 
            title="Create New Device" 
            hideScroll='false'
          >
          <NewDevice handleToggle={toggleOpen}/>
        </Modal>
    </DeviceContainer>
  )
}

export default Devices