import React from "react"

export type IconProps = {
    icon?: React.ReactNode
}
type Props = IconProps & {
    title: string
    index: number
    setSelectedTab: (index: number) => void,
    toggleState: (id:number)=> void,
    changeClass: (id: number)=> string
  }
  
  const TabTitle: React.FC<Props> = ({ title, setSelectedTab, index, icon, changeClass, toggleState}) => {
    const changeState = () =>{
      setSelectedTab(index);
      toggleState(index);
    }
    return (
        <li className={changeClass(index)} onClick={changeState}>
         <span>
            {icon}
         </span>
         <span >
            {title}
         </span>
        </li>
    )
  }
  
  export default TabTitle