import React from 'react'

const useDragDrop = () => {
    // drag state
    const [dragActive, setDragActive] = React.useState(false);

    // ref
    const inputRef = React.useRef<HTMLInputElement>(null);

    // handle drag events
    const handleDrag = function(e:React.DragEvent<HTMLDivElement | HTMLFormElement>) {
        e.preventDefault();
        e.stopPropagation();
        console.log(e)
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            // handleFiles(e.dataTransfer.files);
            console.log('file Drop', e.dataTransfer.files)
        }
    };

    // triggers when file is selected with click
    const handleChange = function(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            // handleFiles(e.target.files);
        }
    };
    
    // triggers the input when the button is clicked
    const onButtonClick = (e:any) => {
        e.preventDefault();
        inputRef.current?.click();
    };

    return {dragActive, handleDrag, handleDrop, handleChange, onButtonClick, inputRef};

}

export default useDragDrop