import React, { useState } from 'react'
import {IoIosArrowDown} from 'react-icons/io'
import AlertItem from './AlertItem'
import { AlertDetail } from './dashboard.styled'

const Alert = () => {
    const [alert, setAlert] = useState(false)
    const handleAlert = ()=>{
        setAlert(!alert)
    }
  return (
    <AlertDetail>
        <div className="alert__header">
            <span className="title">Alert</span>
            <div className="alert__pop">
                <div className="selected__item" onMouseEnter={handleAlert}>
                    <span className='item_select'>Today</span>
                    <IoIosArrowDown className="select_icon"/>
                </div>
                {
                    alert && (
                        <ul className="pop__items" onMouseLeave={handleAlert}>
                            <li>Today</li>
                            <li>Last 24hrs</li>
                            <li>This weeks</li>
                            <li>Last week</li>
                            <li>Last 7 days</li>
                            <li>This month</li>
                            <li>Last month</li>
                            <li>Last 3months</li>
                        </ul>
                    )
                }
            </div>
        </div>
        <div className="alert__details">
           <AlertItem/>
           <AlertItem/>
           <AlertItem/>
           <AlertItem/>
           <AlertItem/>
           <AlertItem/>
           <AlertItem/>
           <AlertItem/>
           <AlertItem/>
        </div>
    </AlertDetail>
  )
}

export default Alert