import {configureStore} from '@reduxjs/toolkit'
import { FLUSH,REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, persistStore, persistReducer} from 'redux-persist';
import rootReducer from './rootReducers'
import storage from 'redux-persist/lib/storage'


/* A configuration object for the persistReducer function. */
const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    blacklist: ['state']
}


/* A function that takes two arguments: persistConfig and rootReducer. */
const persistedReducer = persistReducer(persistConfig, rootReducer)

/* Creating a store with the persistedReducer. */
export const store = configureStore({
    reducer: persistedReducer,
    // devTools: { trace: true, traceLimit: 25 },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

//Set the root state
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const persistor = persistStore(store)

