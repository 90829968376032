import { combineReducers } from '@reduxjs/toolkit'
import userReducer from './login/loginSlice'
import mapReducer from './slices/mapSlice'


const rootReducer = combineReducers({
    userReducer,
    mapReducer
})

export default rootReducer