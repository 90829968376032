import styled from 'styled-components/macro'


export const MultiSelectContainer = styled.div`
    /* margin:100px auto; */
    font-family: 'Roboto', sans-serif;
    position:relative;
    width: 100%;

    .selected__options{
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .selected__options span{
        font-size: .9rem;
        color: #838191;
    }

    /* &:hover{
        .c-multi-select-dropdown__options{
            display:block;
        }
    } */

    .c-multi-select-dropdown__selected{
        border:solid 1px #eee;
        border-radius: 5px;
        font-size:14px;
        padding:10px;
        display:flex;
        justify-content: space-between;
        align-items: center;
        background: #F3F4F6;
        //z-index: 100;

        > span {
            height:18px;
        }
    }

    .c-multi-select-dropdown__options{
        display:block;
        position: absolute;
        box-sizing: border-box;
        left:0;
        width:100%;
        height: 10rem;
        list-style: none;
        padding-left:0px;
        border:solid 1px #eee;
        padding:5px 0px;
        background: #fff;
        box-shadow: var(--shadow);
        overflow-x: hidden;
        overflow-y: scroll ;
        z-index: 10;
        &::-webkit-scrollbar{
            background: #fff;
            width: 8px;
       }

       &::-webkit-scrollbar-thumb {
            background: #F3F4F6;
            border-radius: 10px;
       }
    }

   
   

    .c-multi-select-dropdown__option{
        display:flex;
        align-items: center;
        padding:6px 10px;
        cursor:pointer;

        &:hover{
            background-color: #F3F4F6;
        }
        
        &-checkbox{
            margin-right:6px;
        }
    }
 
`