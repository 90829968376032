import { Form } from "./loginStyled"
import InputField from '../../components/input/input'
import Button from "../../components/button/Button";
import { Link, useNavigate } from 'react-router-dom'
import Spinner from "../../components/spinner/Spinner";
import { FiCheckCircle } from 'react-icons/fi'
import useLogin from "./useLogin";
import validateLogin from "./validateLogin";
import { loginUser } from "../../utils/apiCalls";
import { useLoginState } from "../../components/selectRef/hook/useGeofenceContext";

export type LangProps = {
  t: (value: string)=> typeof value;
  verifyUser?: boolean
}

// type VUser={
//   verifyUser: boolean
// }

// type LoginProps= VUser &  LangProps

const LoginForm = ({t, verifyUser}: LangProps) => {

  const {handleSubmit, handleChange, errors} = useLogin(validateLogin, loginUser)
  const navigate = useNavigate()
  const   {state} = useLoginState();


  console.log("state", state)

  return (
    <Form action="" onSubmit={handleSubmit}>
        <h4>{t('login')}</h4>
        {
          state === "failed" &&  <span className="err_msg">username or password is incorrect</span>
        }
        {
          (verifyUser !== undefined && !verifyUser) &&  <span className="verify_user">This account has not been 👉 <span className="err_msg" onClick={()=> navigate("/verify-user")}>{"verify"}</span></span>
        }
        <InputField 
            text="email" 
            placeText="Email" 
            name="email" 
            id="email"
            styles={{width: "481px", height: "56px"}}
            handleChange={handleChange}
        />
       {
        errors.email && <span className="err_msg">{errors?.email}</span>
       }
        <InputField 
            text="text" 
            placeText="Password" 
            name="password" 
            id="password"
            icon={true}
            styles={{width: "481px", height: "56px"}}
            handleChange={handleChange}
        />
        {
          errors.password && <span className="err_msg">{errors?.password}</span>
        }
        <div>
          <input type="checkbox" />
          <span>
            <Link to={"/forgot-password"} className="forgot_pass">
              {t('forget password')}
            </Link>
          </span>
        </div>
        {/* <Button className="button">{t("login")}</Button> */}
        <Button className="button" disabled={state === "loading"}>
          {
            ((state === "idle" ||  state === "failed" || state ==="success") && !verifyUser) && "Login"
          }
          {
            (state === "loading" && !verifyUser) && <Spinner/>
          }
          {
            (state === "success" && verifyUser === true)&& <FiCheckCircle/>
          }
        </Button>
        {/* <div className="login__signup">
          <span>
            {t('account')} 

            <span>
                <Link to={"/sign-up"} className="forgot_pass">
                    {t('signup')}
                </Link>
            </span>
          </span>
        </div> */}
       
    </Form>
  )
}

export default LoginForm