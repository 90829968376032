import React, {useState, useRef} from 'react'
import TextInput from '../floatInput/FloatInput';
import { Option, Select } from '../selectRef/select';
import { Form } from './dragdrop.styled'
import useDragDrop from './useDragDrop'
import { AiFillFileAdd } from 'react-icons/ai';

const DragDrop = () => {
    const {
        handleChange, 
        handleDrag, 
        handleDrop, 
        onButtonClick,
        inputRef, 
        dragActive 
    } = useDragDrop();
  
  return (
    <Form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
        <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
        <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
        <div>
            <p className='drag__text'>
                Drag file here or 
                <button className="upload-button" onClick={onButtonClick}>Browse</button>
            </p>
            <span className='drag__file'>
                <AiFillFileAdd className='file_icon'/>
            </span>
            
        </div> 
        </label>
        { 
            dragActive &&   <div 
                                id="drag-file-element" 
                                onDragEnter={handleDrag} 
                                onDragLeave={handleDrag} 
                                onDragOver={handleDrag} 
                                onDrop={handleDrop}
                            >
                             
                            </div> 
        }

        <div className="desc__canvas">
            <div className="form__item">
                <TextInput label="Name"/>
            </div>
            
            <div className="form__item">
                <Select placeholder='Select Device'>
                    <Option value='selct'>Select 1</Option>
                    <Option value='selct'>Select 2</Option>
                    <Option value='selct'>Select 3</Option>
                </Select>
            </div>
           
        </div>
    </Form>
  )
}

export default DragDrop