import styled from 'styled-components/macro'

// type Props ={
//     col?: string, 
// } 

export const PopContainer = styled.div`
    display: grid;
    width: 90px;
    position: absolute;
    //top: 20rem;
    //left: 10rem;
    padding: 8px;
    background: #FFFFFF;
    box-shadow: 0px 0px 0px 1px rgba(152, 161, 179, 0.1), 
                0px 15px 35px -5px rgba(17, 24, 38, 0.15), 
                0px 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    z-index: 100;
    .pop__items{
        display: flex;
        flex-direction: column;
        /* justify-content: flex-start; */
        /* align-items: center; */
        gap: 5px;
        list-style-type: none;
    }

    .item{
        font-size: .75rem;
        padding: 3px;
        border-radius: 5px;
        cursor: pointer;
    }

    .pop__items .item:hover{
        background: #CFF4D2;
    }
`