import styled from 'styled-components'

export const AccordionContainer = styled.div`
    width: 380px;
    padding: 10px;
    
    /* .accordion__title{
        padding: 5px;
        
    } */
    .accordion__header, 
    .accordion__inner__title,
    .accordion__inner__content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #E9EDF5;
        padding-bottom: 10px;
        cursor: pointer;
    }

    .accordion__header{
        /* justify-content: flex-start; */
        cursor: initial;
    }
    .accordion__inner__title{
        width: 21rem;
    }

    .accordion__inner__item,
    .accordion__depth__item{
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .accordion__header__item{
        padding-left: 10px;
        flex:2;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .accordion__header__content{
        display: inline-flex;
        width: 14rem;
        cursor: pointer;
        text-align: left;
    }
    /* .accordion__header__item .accordion__header__content .accordion__icon{
        font-size: 2rem;
    } */

    .accordion__header__content:nth-child(1), 
    .accordion__inner__header:nth-child(1){
        width: 12px;
        height: 12px;
        aspect-ratio: 1;
        background: #FFFFFF;
        /* box-shadow: 
                0px 1px 1px rgba(0, 0, 0, 0.1), 
                0px 0px 0px 1px rgba(70, 79, 96, 0.16); */
        /* border-radius: 4px; */
        border: none;
        outline: none;
    }

    .accordion__header__content:nth-child(2){
        cursor: pointer;
    }

    .accordion__header__content  .accordion__icon{
        font-size: 1.2rem;
    }

    /* .accordion__inner__header input{
        width: 100%;
        height: 100%;
    } */
    .accordion__item{
        display: none;
        padding-left: 25px;
        padding-top: 10px;
        list-style-type: none;
    }

    .accordion__item.active{
        display: flex;
        flex-direction: column;
        max-height: max-content;
        transition: all 1s ease-in-out;
    }
    .accordion__inner__items{
        display: none;
        padding-left: 10px;
    }

    .accordion__inner__items.active{
        display: inline-flex;
        flex-direction: column;
        transition: all 1s ease-in-out;
    }

    .accordion__inner__content{
        width: 20.3rem;
        padding-top: 10px;
        padding-left: 10px;
        cursor: initial;
    }

    .depth__content{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    
    .depth__content .text-4{
        line-height: 12px;
    }

    .accordion__depth__item:nth-child(1) .depth__content:nth-child(2){
        flex-direction: column;
        gap: 0;
        align-items: center;
        /* padding-bottom: 5px; */
    }
    .accordion__inset{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--item-bg);
        font-size: 12px;
        padding: 8px;
        color: #FFFFFF;
    }

    .accordion__icon{
       // font-size: 1.1rem;
    }
    .accordion__icon.modify{
        font-size: 1.5rem;
    }
    
`