import React from 'react'
import { HiDotsVertical } from 'react-icons/hi'
import { MdOutlineThermostat } from 'react-icons/md'
import { AiOutlineBlock } from 'react-icons/ai'

import PopUp from '../../popItems/PopUp'
import { popItems } from '../AccordionItem'
import { GenAndCombineProp } from '../groupProps.typed'


const Combined = ({handleCheckAll, toggleItem, setPop, pop, getPopIndex, getItemId, idx, groupName}: GenAndCombineProp) => {
  return (
    <div className="accordion__title" onClick={()=> getItemId(idx)}>
        <div className='accordion__header'>
            <input 
                type='checkbox' 
                name='combine' 
                id='1'
                className='accordion__header__content' 
                onChange={handleCheckAll}
            />
            <div className="accordion__header__item">
                <AiOutlineBlock size={15}/>
                <span className='accordion__header__content text-2' onClick={toggleItem}>
                   {groupName}
                </span>
                <div className="accordion__inner__item">
                    <MdOutlineThermostat/>
                    <span className="accordion__inner__header text-4">
                        200'C
                    </span>
                </div>
                <div className='depth__content' onClick={()=> getPopIndex(idx)} onMouseLeave={()=>setPop(undefined)}>
                    {
                        pop === idx 
                        ? <PopUp items={popItems} removePop={()=>setPop(undefined)} pop={pop}/> 
                        : <HiDotsVertical className='main-icon'/>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default Combined