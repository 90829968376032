import React from 'react'
import { CreateCanvas, UploadImage } from './canvas.styled'
import { HiOutlineArrowLeft } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import ModalHeader from '../../components/modal/ModalHeader'
import DragDrop from '../../components/dragDropFile/DragDrop'
import Button from '../../components/button/Button'

const Create2DCanvas = () => {
    const navigate = useNavigate()
  return (
    <CreateCanvas>
        <div className="header__title">
            Create 2D Canvas
            <div className="back__summary" onClick={()=>navigate(-1)}>
             <HiOutlineArrowLeft/>
            </div>
        </div>
        <UploadImage>
            <ModalHeader title='Upload Canvas' chg/>
            <div className="drag__drop">
                <DragDrop/>
            </div>
            <div className="canvas__form btn_group">
               <Button className="canvas__cancel">Cancel</Button>
               <Button className="canvas__save" onClick={()=>navigate('/dashboard/create-canvas')}>continue</Button>
            </div>
        </UploadImage>
    </CreateCanvas>
  )
}

export default Create2DCanvas