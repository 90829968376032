import React, { useState, useEffect } from 'react'
import { client } from '../../utils/axios-instance'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../reducers/disPatchSelector'

const  url =  process.env.REACT_APP_ENTERPRISE_URL + "users/verify"
const  resendUrl =  process.env.REACT_APP_ENTERPRISE_URL + "users/resend-verification"
type Otp ={
    code:string
}
const useOtp = () => {
    const verifyMail  = useAppSelector(state=> state.userReducer.currentUser?.body?.data?.email)
    const vToken  = useAppSelector(state=> state.userReducer.currentUser?.body?.token)
    const [otpState, setOtpState] =  useState("idle");
    const [resendState, setResendState] =  useState("idle");
    const [serverMsg, setServerMsg]= useState("")
    const [submitCode, setSubmitCode] = useState(false);
    const [otp, setOtp] = useState({code: ""});
    const onChange = (value: string) =>{
        setOtp({code:value}); 
    }
    const navigate  = useNavigate();

    const resendCode = async (e:React.MouseEvent)=>{
        setResendState("verifying");
        let userMail = JSON.parse(localStorage.getItem("v-mail")!) 
            ? JSON.parse(localStorage.getItem("v-mail")!) : verifyMail;
        let request = client();
        let data = {email: userMail}
        try {
            await request.post(resendUrl, data).then(res=>{
                setResendState("success")
                setServerMsg("Check your mail for the resend code");
            }).catch((err)=>{
                setResendState("failed");
                setServerMsg(err.response?.data?.message);
            })
        } catch (e) {
            setOtpState("failed")
        }
    }

    const handleSubmitCode = (e: React.FormEvent)=>{
        e.preventDefault();
        if(otp.code.length === 4){
            setSubmitCode(true)
        }
        return;
    }

    useEffect(()=>{
        const verifyCode = async ()=>{
            setOtpState("verifying");
            let token = JSON.parse(localStorage.getItem("v-token")!) 
                ? JSON.parse(localStorage.getItem("v-token")!) : vToken;
            let request = client(token);
            let data = {code: parseInt(otp.code)}
            try {
                await request.post(url, data).then(res=>{
                    console.log("verify", res?.data)
                    setServerMsg(res?.data?.data?.message)
                    setOtpState("success")
                    navigate("/sign-in");
                    if(localStorage.getItem("v-token") !== null){
                        localStorage.removeItem("v-token")
                        localStorage.removeItem("v-mail")
                        navigate("/sign-in");
                    }else{
                        navigate("/sign-in");
                    }
                     
                }).catch(err=>{
                    setOtpState("failed")
                    setServerMsg(err.response?.data?.message)
                })

                // localStorage.removeItem("v-token")
                // localStorage.removeItem("v-mail")
            } catch (e) {
                setOtpState("failed")
            }
        }

        if(submitCode){
            setSubmitCode(false)
            setTimeout(()=>{
                verifyCode()
            }, 500)
        }

        return;
    }, [navigate, otp, vToken, submitCode])

 return  {otpState,serverMsg, resendState, otp, handleSubmitCode, onChange, resendCode,}

}

export default useOtp