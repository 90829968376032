import React from 'react'
import { BsPlusLg} from 'react-icons/bs'
import { useDeviceTracking } from '../selectRef/hook/useGeofenceContext'
import DeviceSummary from './DeviceSummary'
import SensorItem from './SensorItem'

const BottomContent = () => {
    const {panel} = useDeviceTracking()
    console.log(panel)
  return (
        <React.Fragment>
            <div className="bot-cont-detail">
                {
                    panel.deviceSummary?.sensors 
                    ? <DeviceSummary item={panel.deviceSummary}/> 
                    : <div>This device is not transmitting</div>
                }
                
            </div>

            <div className="bot-cont-detail">
                {
                    panel.deviceSummary?.sensors
                    ? <SensorItem item={panel?.deviceSummary }/> 
                    : <div>No Sensor Values</div>
                }
               
            </div>

            <div className="bot-cont-detail service">
                <div className="bottom-detail service">
                    <div className="bottom-detail-left service pl-0">
                        <BsPlusLg className='btt-detail-icon'/>
                        <span className='text-2'>Add Device</span>
                    </div>
                </div>
            </div>
        </React.Fragment>
  )
}

export default BottomContent