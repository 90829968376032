import {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import { client } from '../utils/axios-instance';
import  { getToken } from './getToken';

type CallData = {
    data: {}[] | any
}
const useMonitorClient = (endpoint: string) => {
    const [clientData, setClientData] = useState<CallData>();
    const [err, setErr] = useState('')
   
    const [data, setData] = useState("");
    
    console.log(endpoint)
    console.log("monitor", clientData)
  
     //Get the devices, and plan addons from the server
    useEffect(()=>{
      let token = getToken()
      let request = client(token)
      setData("loading")
      const fetchData = async ()=>{
        try{
          const res = await request.get(process.env.REACT_APP_MONITOR_URL + endpoint)
          setClientData(res?.data);
          setData("success");
         
        }catch(e: any){
          setErr(e.message);
          setData("failed");
        }
       
      }
  
      fetchData();
    }, [endpoint]);
  
  
    return {clientData,data, err}
}

export default useMonitorClient