import React,{useState} from 'react'
import { HiOutlineArrowLeft } from 'react-icons/hi'
import useMonitorClient from '../../../hooks/useMonitorClient'
import Divider from '../../divider/Divider'
import { useNewGeoUser, usePoiDisplay } from '../../selectRef/hook/useGeofenceContext'
import SidebarSearch from '../../sidebar/SidebarSearch'
import { POIWrapper } from '../tab.styled'
import POIDetails from './POIDetails'

const data = [
    {
        id: 1,
        desc: 'Ikeja cold room 1'
    },
    {
        id: 2,
        desc: 'Apapa cold room 1'
    },
    {
        id: 3,
        desc: 'New Toyotal Truck'
    },
]
const POItems = () => {
    const [show, setShow] = useState(false)
    const {setShowItem} = useNewGeoUser()
    const {handlePoiDisplay} = usePoiDisplay()
    const  handleShowItem = ()=>{
        setShowItem({showItem: false, comp:""})
        handlePoiDisplay(false)
    }
    const {clientData}= useMonitorClient("poi")
    const poiData = clientData?.data?.pois
    console.log("poi", clientData)
  return (
        <POIWrapper>
            <div className="all__geo">
                <SidebarSearch 
                    btnTitle='Add New POI' 
                    openModal={setShow} 
                    icon 
                    btnShow
                    topRightItem='poiForm'
                />
                <div className="geofence__header">
                    <Divider title="All POI" width={14.5}/>
                    <span className="back_btn" onClick={handleShowItem}>
                        <HiOutlineArrowLeft className="back__arrow"/>
                    </span>
                </div>
                <div className="geo__items">
                    {
                        clientData === undefined 
                        ? (<div>Loading...</div>) 
                        : poiData?.map((p:any)=>  <POIDetails show={show} item={p?.label} key={p?._id}/>)
                    }
                </div>
            </div>
        </POIWrapper>
    )
}

export default POItems