import styled from 'styled-components'

type MarginTop={
    mt?: number
}
export const DividerContainer = styled.div<MarginTop>`
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 0 0;
    margin-top: ${({mt})=> mt && mt+"rem"};

    //Spacing the children element immediately after the first child parent of the element
    *+*{
        margin-left: 20px;
    }
`
export const Info = styled.span`
    display: inline-flex;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.1rem;
    letter-spacing: -0.02px;
    color: #505050;
`

type Width = {
    width?: number
}
export const Line = styled.div<Width>`
    width: ${({width})=> width && width+"rem"};
    hr{
        width: 100%;
        height: 1px;
        background-color: #E9EDF5;
        border-width: 0;
        /* border: none; */
    }
`
type Icon = {
    icon?: boolean
}
export const Error = styled.div<Icon>`
    color: #FBC694;
    display: ${({icon})=> icon ? "block": "none"};
    .err{
        font-size:  1.2rem !important;
    }

    .size{
        font-size:  1.1rem !important;
    }
`
