import React, { memo, useState } from 'react'
import styled from 'styled-components/macro'
import Spinner from '../spinner/Spinner'
import { DeleteContainer } from './delete.styled'
import useDeleItem from './useDeleItem'


type DeleteProp = {
    item: any,
    toggleBack: ()=> void
    delType: string
}
const urlD = process.env.REACT_APP_ENTERPRISE_URL + "/devices"
const urlG = process.env.REACT_APP_MONITOR_URL + "/groups"

const Delete = ({item, toggleBack, delType}: DeleteProp) => {
    //const [remove, setRemove] = useState(false)
    const url = delType === "groups" ? urlG : urlD
    const {handleDelete, changeModal, deleteState} = useDeleItem(url, item?._id, delType, toggleBack)
    //console.log("deleId", item?._id)
    console.log("itemm", item)

    // const handleDelete = ()=>{
    //     setRemove(true)
    // }

  return (
    <DeleteContainer>
         <img className='delete__img' src={process.env.PUBLIC_URL+"/assets/images/delete.png"} alt="delete"/>
         {
            !changeModal ? (
                <div className='delete__bottom'>
                    <p>Are you sure you want to delete {item?.deviceName || item?.name} ?</p>
                    <div className="delete__buttons">
                        <button onClick={toggleBack}>Cancel</button>
                        <button onClick={handleDelete}>Delete</button>
                    </div>
                </div>
            )
         :
         (

                <div className='delete__bottom'>
                    <p className='delete_desc'>Deleting {item?.deviceName || item?.name }</p>
                    <div className="delete__buttons">
                        <div className='delete_request'>
                            {
                                deleteState === "deleting" &&  <Spinner spin='border'/>
                            }
                            {
                                deleteState === "success" &&  <span>Device deleted Successfully</span>
                            }
                            {
                                deleteState === "failed" &&  <span>Delete failed</span>
                            }
                            
                        </div>
                    </div>
                </div>
         )
         }
         
         
    </DeleteContainer>
  )
}

export default Delete