import React, {useState, useEffect} from 'react'
import { useMutation , useQueryClient} from 'react-query';
import { client } from '../../utils/axios-instance';
import { sortData } from '../modalForm/editDevice/useMutateData';

const useDeleItem = (url:string, id: string, delType:string, toggleModal: ()=> void) => {
    const [isDeleting, setDeleting] = useState(false)
    const [changeModal, setChange] = useState(false)

   
    const [deleteState, setState] = useState("");
    const queryClient = useQueryClient()

    console.log("delete", isDeleting)
   
    console.log("is submit", isDeleting)
    const handleDelete = (e: React.FormEvent)=>{
      e.preventDefault();
      setChange(true)
      setState("deleting")
      setDeleting(true)
    }

    const DeleteData = async ()=>{
      let token = JSON.parse(localStorage.getItem("access_token")!)
      let request = client(token)

      try{
        console.log("inside request")
        const res = await request.delete(url + "/" + id)
        //console.log(res.data)
        console.log("data delete", res.data)
      
        if(res.data !== null) {
          console.log(res.data);
          //setMsg(res.data.message)
          setState("success")
          setTimeout(()=>{
            toggleModal()
          }, 500)
        }
      }catch(err){
        console.log(err);
        setTimeout(()=>{
          setState("failed");
      }, 1000)
        //toggleModel()
      }
  }

   const {mutate} =  useMutation("DeviceDelete", DeleteData, {
      onSuccess: async (data:any, variables, context)=> {
        await queryClient.setQueryData("devices", (oldData:any)=>{
          const prevData = oldData?.data?.data.filter((d:any)=> d._id !== id)
          console.log("device del", oldData)
          return {
            ...oldData,
            data:{
              data: [...prevData]
            }
          }
        });

       //await queryClient.invalidateQueries("devices")
      }
    })

    const {mutate: mutateGroup} =  useMutation("MutateGroup", DeleteData, {
      onMutate: async(newDevice)=>{
        //await queryClient.cancelQueries("devices")
      },
      onSuccess: async (data:any, variables, context)=> {
        await queryClient.setQueryData("groups", (oldData:any)=>{
          const prevData = oldData?.data.data.groups.filter((d:any)=> d._id !== id)
          console.log("prevG", prevData)
          return {
            ...oldData,
            data:{
              data: {
                groups: prevData
              }
            }
          }
        });

       //await queryClient.invalidateQueries("groups")
      }
    })
   

    useEffect(()=>{
      if(isDeleting && delType ==="groups"){
        console.log("groups delete")
        setDeleting(false)
        setTimeout(()=>{
          mutateGroup()
        }, 1000)

      }else{
        setDeleting(false)
      }

      if(isDeleting && delType ==="devices"){
        console.log("dev delete")
        setDeleting(false)
        setTimeout(()=>{
          mutate()
        }, 1000)

      }else{
          setDeleting(false)
      }
        
    },[isDeleting,mutateGroup,mutate,delType]);

  

  return {handleDelete, deleteState, changeModal}
}

export default useDeleItem