import React,{useState} from 'react'
import { ActionType } from '../../features/geofenceReducer'
import { GroupData } from '../modalForm/newGroup/data.typed'
import { ActionPageType } from './typed'

type OptionData = {
    id: number | string
    title: string
}
type ActionProps = {
    option: OptionData
    handleItem: ({id, title}:OptionData)=> void
    setActionPage: ()=> void
    setActionGroup: React.Dispatch<React.SetStateAction<GroupData>>
    getTitle: (title: string)=> void
    handleAction: React.ChangeEventHandler
    sensor: ActionPageType
}
const actions = {
    title: "",
    action: "",
    interval: 0,
}
const ActionPage = ({sensor, option, setActionPage, handleItem, setActionGroup, getTitle,handleAction}: ActionProps) => {
    //const  [actionItem, setActionItem] = useState<ActionPageType>(actions)
    //console.log("act", actionItem)

    const handleSave = (e: React.MouseEvent<HTMLButtonElement>) =>{
        e.preventDefault();
        setActionPage();
        handleItem({id: option.id, title: option.title});
        getTitle(option.title)
    }
    const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) =>{
        e.preventDefault();
        setActionPage();
    }

//    const handleAction = (e: React.ChangeEvent<HTMLSelectElement>)=>{
//         e.preventDefault()
//         const {name, value} = e.target
//         setActionItem(prev=>{
//             return {
//                 ...prev,
//                 [name]: value
//             }
//         })
//     }
   
  return (
    <div className="action__container">
        <div className="action_title">Actions</div>
        <div className="action_items">
            <div className="item">
                <select name="action" id="" placeholder='Select action' onChange={handleAction}>
                    <option value="op">Select Action</option>
                    <option value="average">Average</option>
                    <option value="full">Full</option>
                </select>
            </div>
            <div className="item">
                <select name="interval" id="" placeholder='Select action' onChange={handleAction}>
                    <option value="op">Interval</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                </select>
            </div>
            <div className="item">
                <button className='cancel' onClick={handleCancel}>Cancel</button>
                <button className='save' onClick={handleSave}>Save</button>
            </div>
        </div>
    </div>
  )
}

export default ActionPage