import React from 'react'
import {  useNewGeoUser } from '../selectRef/hook/useGeofenceContext'
import GeofenceItem from './Geofence/GeofenceItem'
import NewGeofence from './Geofence/NewGeofence'
import NewPOI from './pointOfInterest/NewPOI'
import POI from './pointOfInterest/POI'

const TopRightMapItem = () => {
  //const {dispatch, ...state}= useGeoUser()
  const {...showItems} = useNewGeoUser()
  //console.log(state)
  return (
  <>
    {
      showItems.comp === "SHOW_GEO" && (<GeofenceItem/>)
    }
    {
      showItems.comp === "SHOW_POI" && (<POI/>)
    }
    {
      showItems.comp === "geoForm" && (<NewGeofence/>)
    }
    {
      showItems.comp === "poiForm" && (<NewPOI/>)
    }
  </>
  )
}

export default TopRightMapItem