import React from 'react'
import { FiCircle } from 'react-icons/fi'
import { HiOutlineArrowLeft } from 'react-icons/hi'
import { TbPolygon } from 'react-icons/tb'
import Button from '../../button/Button'
import Divider from '../../divider/Divider'
import TextInput from '../../floatInput/FloatInput'
import { useGeofenceShape, useNewGeoUser, } from '../../selectRef/hook/useGeofenceContext'
import { Form, GeofenceDetail } from '../tab.styled'

const NewGeofence = () => {
    //const { dispatch } = useGeoUser()
    const {shape, setShape} = useGeofenceShape()
    const {setShowItem} = useNewGeoUser()
    const handleShow = ()=>{
        setShowItem(prev=>{
            return {
                ...prev,
                comp: "SHOW_GEO"
            }
        })
    }

    const changeShape = (e:React.ChangeEvent<HTMLInputElement>) =>{
        setShape(e.target.value);
    }

    console.log(shape)
  return (
    <GeofenceDetail>
        <div className="geofence__header">
            <Divider title="Create Geofence" width={9}/>
            <span className='back_btn' onClick={handleShow}>
                <HiOutlineArrowLeft className="back__arrow"/>
            </span>
        </div>
        
        <Form>
            <div className="geo__form shape">
                <div className="geo__shape">
                    <input type="radio" id="shape" name="shape" value="circle" onChange={changeShape}/>
                    <FiCircle className='icon'/>
                    <span>Ellipse</span>
                </div> 
                <div className="geo__shape">
                    <input type="radio" id="shape" name="shape" value="polygon" onChange={changeShape}/>
                    <TbPolygon className='icon'/>
                    <span>Polygon</span>
                </div>
            </div>
            <div className="geo__form">
               <TextInput type="text" label="Label" onChange={()=>console.log("")}/>
            </div>
            {/* <div className="geo__form">
               <textarea placeholder='Description' cols={46} rows={4}/>
            </div> */}
            <div className="geo__form">
               <TextInput type="text" label="Radius"/>
            </div>
            <div className="geo__form">
               <TextInput type="text" label="Color"/>
            </div>
            <div className="geo__form btn_group">
               <Button className="geo_white" onClick={handleShow}>Cancel</Button>
               <Button className="geo_green">Save</Button>
            </div>
        </Form>
    </GeofenceDetail>
  )
}

export default NewGeofence