import React from 'react'

const Object2D = () => {
  return (
    <div style={{textAlign: 'center', paddingTop: '5rem'}}>
        <h1>Object2D</h1>
        <h1>Object2D</h1>
        <h1>Object2D</h1>
        <h1>Object2D</h1>
        <h1>Object2D</h1>
    </div>
  )
}

export default Object2D