import styled from 'styled-components/macro'

export const SideWrapper = styled.div`
    width: 24rem;
    max-width: 24rem;
    background: #fff;

 //Geofence form
position: relative;
z-index: 600;
`
/**************************************SideHeader Styled***********************************/
export const HeaderWrapper = styled.div`
    width: 100%;
    /* max-width: 100%; */
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ul{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 5px 6px;
        padding-right: 30px;
        gap: 8px;
    }

    li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        padding: 5px;
    }
    span{
        display: inline-flex;
        font-size: 0.875rem;
        line-height: 32px;
        letter-spacing: 0.2px;
        font-weight: 500;
        font-family: 'Roboto';
        color: #505050;
    }


    .side-list.active span{
        color: #4d9200;
    }

    .side-header-icon{
        font-size: 1rem;
    }

    .side-close-icon{
        font-size: 1.3rem;
    }

    .sidebar-close{
        display: grid;
        place-items: center;
        background: rgba(236, 28, 36, 0.15);
        aspect-ratio: 1;
        height: 100%;
        width: 50px;
        /* padding: 5px; */
    }
`

/*******************************SearchHeader styled*****************************************/
export const SearchHeader = styled.div`
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-around;
    background: #E9EDF5;
    align-items: center;
    gap: 5px;
    padding: 10px;

    .search-item-btn, .search-item-input{
        width: 92px;
        height: 28.5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #4D9200;
        border-radius: 5px;
        padding-right: 3px;
    }

    .search-item-btn{
        cursor: pointer;
    }

    .search-item-input, .search-group{
        width: 15rem;
        background: #fff;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 11px;
        padding-left: 5px; 
    }

    .search-group{
        width: 100%;
        border-radius: 5px;
    }

    .search-item-input::before{
        content: '/';
        width: 15px;
        height: 15px;
        font-size: 0.8rem;
        border-radius: 3px;
        background: #E9EDF5;
        position: absolute;
        right: 10px;
        display: grid;
        place-items: center;
        z-index: 1;
    }

    .search-item-input input{
        height: 100%;
        border: none;
        outline: none;
        position: relative;
    }
    input::placeholder{
        padding: 10px;
    }

    .search-item-input span{
        color: #000;
        height: 28.5px;
        display: inline-flex;
        display: grid;
        place-items: center;
    }

    .search-left, .search-right{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3px;
    }

    /* .search-right{
        width: 93%;
    } */
    .search-left p{
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 20px;
        color: #464F60;
    }
    .search-bottom{

    }

    .search-right{
        left: 30px;
        bottom: 4px;
    }

    .search-icon-top, .search-icon-bottom{
        font-size: 1rem;
    }
    .search-icon-top{
        color: #464F60;
    }
    .search-icon-bottom{
        color: #868FA0;
    }

    .search-desc{
        display: inline-flex;
        font-size: 11px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #fff;
        padding: 2px 5px;
    }

    .search-icon{
        font-size: 0.875rem;
        color: #fff;
    }
`
/*******************************Filter styled*****************************************/
export const FilterItem = styled.div`
  width: 12rem;
  height: 9rem;
  background: #fff;
  border-radius: 5px;
  position: absolute;
  top: 35px;
  left: 0;
  z-index: 10;
  box-shadow: var(--shadow);
  .header{
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E9EDF5;
  }
  .main-list{
    padding: 10px;
  }
  .main-list ul{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 5px;
    list-style-type: none;
    padding-left: 5px;
  }

  .main-list li{
    width: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
    cursor: pointer;
  }

  .main-list span{
    font-weight: 500;
    line-height: var(--line-height);
  }

  .main-list li:hover, .main-list span:hover{
    color:  #838191;
  }

  .filter-item{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding-left: 10px;
  }

  .filter-item span{
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: var(--line-height);
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #505050;
  }

  .filter-item-icon{
    font-size: 1.3rem;
  }

  .filter-close{
      display: grid;
      place-items: center;
      background: rgba(236, 28, 36, 0.15);
      aspect-ratio: 1;
      height: 100%;
      width: 40px;
      padding: 5px;
      cursor: pointer;
  }
`
/**************************************SearchContent styled*****************************************/

export const ContentContainer = styled(SideWrapper)`
    height: 20.5rem;
    letter-spacing: var(--letter-spacing);
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar{
        background: #fff;
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: #D9D9D9;
        border-radius: 20px;
        /* background-color: darkgrey;
        outline: 1px solid slategrey; */
    }
`

export const HistoryContainer = styled(ContentContainer)`
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    padding: 0 6px;
    &::-webkit-scrollbar{
        background: #fff;
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: #D9D9D9;
        border-radius: 20px;
        /* background-color: darkgrey;
        outline: 1px solid slategrey; */
    }
`

/*******************************FilterPop styled*****************************************/
export const PopContainer = styled.div`
    background-color: #fff;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 100px;
    left: 90px;
    box-shadow: var(--shadow);
    transition: top 0.5s ease;

    .pop-item{
        display: flex;
        justify-content: space-between;
        gap: 15px;
    }
`