import { DevicesData } from "../../selectRef/hook/useGeofenceContext";
import { devicesData } from "./deviceData";

export default function validateSetDevice(values: DevicesData, simState: string, imeiState:string) {
    let errors:{[s:string]: string} = {};
  
    if (!values.deviceName) {
      errors.deviceName = 'Device Name required';
    } else if (values.deviceName.length < 4) {
      errors.deviceName = 'DeviceName must be greater than 4 characters';
    }

    if(!values.deviceType){
        errors.deviceType = "DeviceType must be selected"
    }

    if(!values.phone){
        errors.phone = "Phone Number is required"
    }
    
    if (!values.imei) {
        errors.imei = 'Imei is required';
    }else if(values.imei.length < 4){
        errors.imei = 'Imei cannot be less than 4 characters';
    }

    if(!values.simiccid){
        errors.simiccid = "Simiccid is reqiured"
    }else if((simState === "failed")){
        errors.simiccid = "Incorrect simiccid, add a valid Gentro simiccid"
    }

    if(!values.sensorsEnabled || values.sensorsEnabled.length === 0){
        errors.sensorsEnabled = "At lease one sensor must be enabled"
    }
    
    if(!values.deviceModel || values.deviceModel.length === 0){
        errors.deviceModel = "At lease one deviceModel must be selected"
    }

    return errors;
}