import Lang from '../lang/Lang'
import {HeaderContainer, Logo} from './headerStyled'

export type LangProps = {
    handleLanguage: (value: string)=> void
}

const Header = ({handleLanguage}: LangProps) => {
  return (
    <HeaderContainer>
        <Logo>
           <img src={process.env.PUBLIC_URL+"/assets/images/gentro.png"} alt="Gentro Logo"/>
        </Logo>
        <Lang handleLanguage={handleLanguage}/>
    </HeaderContainer>
  )
}

export default Header