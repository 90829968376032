import styled from 'styled-components'
import {Hide, NewBg} from './typeProps'

export const BackDrop = styled.div<NewBg>`
    /* display: grid;
    place-items: center; */
    width: 100vw;
    height: 100vh;
    //max-height: 100vh;
    position: fixed;
    background-color: ${({newBg})=> newBg? `rgba(0, 0, 0, 0.05)` : `rgba(51, 42, 42, 0.5)`};
    z-index: 10000;
    top:0;
    left: 0;
    //right: -2rem;
    padding-left:5rem;
    padding-top: 6rem;
`

export const ModalWrapper = styled.div`
    z-index: 10000;
    max-width: 1000px;
    height: 90%;
    display: flex;
    flex-direction: column;
    background:#fff;
`
export const Container = styled.div`
   
    display: flex;

    .modal-title{
        width: 96%;
        padding: 10px 0;
        padding-left: 2rem;
        background: var(--item-bg);
        color: #fff;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1rem;
        letter-spacing: var(--letter-spacing);
    }
    .modal-close{
        display: grid;
        place-items: center;
        background: rgba(236, 28, 36, 0.15);
        aspect-ratio: 1;
        width: 4%;
    }
`

export const NewContainer= styled(Container)`

    .modal-title{
        width: 100%;
    }
    .modal-close{
        display: none;
    }
`


export const ModalContent = styled.div<Hide>`
    /* max-height: 100%; */
    height: 100%;
    overflow-y: ${({hideScroll})=> hideScroll === 'true' ? 'hidden': 'auto'};
    &::-webkit-scrollbar{
        background: #fff;
        width: 8px;
    }
   
    &::-webkit-scrollbar-thumb {
        background: #D9D9D9;
        border-radius: 20px;
        /* background-color: darkgrey;
        outline: 1px solid slategrey; */
    }
`