import React, {useState, useEffect} from 'react'
import { client } from '../../utils/axios-instance'
import { Link, Navigate, useNavigate } from 'react-router-dom'

type Email={
    [s:string]: string  | number
}

const useForgotPassword = (url:string, link:string) => {
 const[item,setItem] = useState<Email|undefined>(undefined)
 const [isSubmitting, setSubmit] = useState(false)
 const [state, setState] = useState("idle")
 const [serverMsg,  setServerMsg]= useState("");
 const navigate = useNavigate()


 const handleChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
    e.preventDefault();
    const {name, value} = e.target
    setItem(prev=>{
        return{
            ...prev,
            [name]: value
        }
    })

    if(name ==="code"){
        setItem(prev=>{
            return{
                ...prev,
                [name]: parseInt(value)
            }
        })
    }
 }

 const handleSubmit = (e:React.FormEvent)=>{
    e.preventDefault();
    let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

    if(item){
        setSubmit(true)
        setState("submitting");
    }else{
        setSubmit(false)
    }

 } 

 useEffect(()=>{
    const submitData = async()=>{
        let email = link === "/sign-in" && JSON.parse(localStorage.getItem("mail")!)

        let request = client()
        let data = link === "/reset-password" ? item : {email:email, ...item}
        console.log("insode",data)
        try {
             await request.post(url, data).then((res)=>{
                setState("done")
                if(link === "/reset-password"){
                    localStorage.setItem('mail', JSON.stringify(item?.email))
                    navigate(link)
                }else{
                    localStorage.removeItem('mail');
                    navigate(link)
                } 
            }).catch(err=>{
                setState("failed")
                setServerMsg(err.response?.data?.message)
            })
            
        } catch (error) {
            setState("failed")
        }
    }

    if(isSubmitting){
        setSubmit(false)
        setTimeout(()=>{
            submitData()
        },0)
    }
 },[isSubmitting, url,item,navigate, link])

 return {item, handleChange, handleSubmit, navigate, serverMsg, state}
    
}

export default useForgotPassword