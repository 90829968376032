import React from 'react'
import useToggle from '../../features/toggle'
import { PopContainer } from './sidebar.styled'

const FilterPop = () => {
    
  return (
    <PopContainer>
        <div className='pop-item'>
            <span className='pop-item1'>
                Active
            </span>
            <span>
                A
            </span>
        </div>
       
        <div className='pop-item'>
            <span className='pop-item1'>
                Active
            </span>
            <span>
                A
            </span>
        </div>
        <div className='pop-item'>
            <span className='pop-item1'>
                Active
            </span>
            <span>
                A
            </span>
        </div>
    </PopContainer>
  )
}

export default FilterPop