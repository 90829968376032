import React from 'react'
import Card from '../../components/card/Card'
import Header from '../../components/header/Header'
import Layout from '../../components/layout/Layout'
import useLang from '../../language/useLang'
import {Right, Left, Wrapper, Image} from './forgetpassword.styled'
import Reset from './Reset'

const ForgotPassword = () => {
    const {t, handleLanguage} = useLang()
  return (
    <Layout>
        <Header handleLanguage={handleLanguage}/>
        <Wrapper>
            <Right>
                <Image>
                  <img  
                    src={process.env.PUBLIC_URL + "/assets/images/lock.png"} 
                    alt="tracking"
                    loading='lazy'
                  />
                </Image>
            </Right>
            <Left>
              <Card>
                <Reset t={t}/>
              </Card>
            </Left>
        </Wrapper>
    </Layout>
  )
}

export default ForgotPassword