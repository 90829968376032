import React from 'react'
import {TH, THead, TR} from './tableStyled'
import { AiFillCaretDown, AiFillCaretUp} from 'react-icons/ai'

type HeaderProps = {
  headers: {
    title: string,
    icon?: boolean
  }[]
}

const Header = ({headers}: HeaderProps) => {
  return (
    <TR>
        <TH>
          <div className="table__header">
            <input type="checkbox"/>
          </div>
        </TH>
        <TH>
          <div className="table__header">
            <span className="table__title">#</span>
            <span className="header__icon">
              <AiFillCaretUp/>
              <AiFillCaretDown/>
            </span>
          </div> 
        </TH>
        {
          headers.map(header=> (
            <TH key={header.title}>
              <div className="table__header">
                <span className="table__title">{header.title}</span>
                {
                  header.icon && <span className="header__icon">
                                    <AiFillCaretUp/>
                                    <AiFillCaretDown/>
                                  </span>
                }
                
              </div> 
            </TH>
          ))
        }
        
        <TH>
          <div className="table__header">
            <span className="table__title more">More</span>
          </div>
        </TH>
    </TR>
  )
}

export default Header