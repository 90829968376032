import React from 'react'
import {HiDotsVertical} from 'react-icons/hi'
import {MdOutlineThermostat} from 'react-icons/md'

import { InnerProps } from './groupProps.typed'


const InnerItem = ({
    isCheckAll, 
    device
}: InnerProps) => {


  
    return (
    <li className="accordion__inner__content">
        <div className="accordion__depth__item">
            <div className="depth__content">
                <input 
                    type='checkbox'  
                    name='ikeja' 
                    id='3'
                    checked={isCheckAll || undefined}
                    className='accordion__inner__header'
                />
                <span className="accordion__inset">S</span>
            </div>
            <div className='depth__content'>
                <span className='text-4'>{device}</span>
                <span className='text-5'>10/20/2022, 10:25am</span>
            </div>
        </div>
        <div className="accordion__depth__item">
            <div className='depth__content'>
                <MdOutlineThermostat className='accordion__icon'/>
                <span className='text-2'>10'C</span>
            </div>
        </div>
    </li>
 )
}

export default InnerItem