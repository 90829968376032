import styled from 'styled-components/macro'


export const SelectContainer = styled.div`
    /* width: 100%; */
    display: grid;
    position: relative;

    .selected-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        border-radius: 6px;
        background: #F3F4F6;
        padding-inline: 10px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 32px;
        letter-spacing: 0.2px;
        color: #838191;
    }

    .select-input{
        display: flex;
        padding: 5px;
        background: #F3F4F6;
    }

    .selected-item .selected_icon, 
    .option-container .options{
        display: flex;
        justify-content: center;
        align-items: center;
        /* gap: 10px; */
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #4D9200;
        color: #fff;
        font-size: 1rem;
    }

    .option-container{
        display: flex;
        flex-wrap: wrap;
        //justify-content: space-between;
        align-items: center;
        gap: 15px;
        width: 100%;
        max-height: 12rem;
        position: absolute;
        top: 2.6rem;
        background: #fff;
        border: 1px solid #E9EDF5;
        border-radius: 8px;
        padding: 10px;
        box-sizing: border-box;
        box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.06), 
                    0px 5px 22px rgba(0, 0, 0, 0.04), 
                    0px 7px 8px rgba(0, 0, 0, 0.08);
        overflow-y: scroll;
        z-index: 10;
        transition: max-height 0.5s ease-in-out;
    }

    .option-container::-webkit-scrollbar{
        background: #fff;
        width: 5px;
    }
   
    .option-container::-webkit-scrollbar-thumb {
        background: #D9D9D9;
        border-radius: 20px;
        /* background-color: darkgrey;
        outline: 1px solid slategrey; */
    }

    .option-container .options{
        width: 40px;
        height: 40px;
        font-size: 1.5rem;
    }
`

export const DeviceSelectContainer = styled(SelectContainer)`
    .pin__icon {
        width: 40px;
        height: 40px;
        background-color: #4D9200;
        border-radius: 50% 50% 0 50%;
        position:relative;
        transform: rotate(45deg);
        color: #4D9200;
    }
    .pin_inner{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px ;
        height: 35px ;
        background: #FFF ;
        position: absolute ;
        left: 5% ;
        top: 5% ;
        border-radius: 50%;
        font-size: 1.2rem;
        transform: rotate(-45deg)
    }
`