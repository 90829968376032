import React from 'react'
import { useSetDevice } from '../../selectRef/hook/useGeofenceContext'
import { icons } from '../iconData'
import { IconProp, SelectProp, SelectIconProp} from '../SelectItems'

type DeviceIconProp = {

}
const DeviceIconItem = ({setIcon, toggleSelect}:SelectProp) => {
   
   // const {setPoiData} = usePOIForm()
   const {setDevices} = useSetDevice()
    const handleItem = ({icon, title}:SelectIconProp)=>{
        toggleSelect(prev=> !prev)
        setIcon({icon})
        setDevices(prev=>{
            return {
                ...prev,
                icon: title
            }
        })
          
    }
return (
 <div className='option-container'>
    {
           icons.map(icn=> 
                <div className="pin__icon" key={icn.id} onClick={()=> handleItem({icon: icn.icon, title:icn.title})}>
                    <div className='pin_inner'>
                        <icn.icon/>
                    </div>
                </div>
            )
    }
 </div>
)
}

export default DeviceIconItem