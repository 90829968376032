import React from 'react'
import { BottomPositionItem, LeftPositionItem, PositionItem } from '../header/headerStyled'
import styled from 'styled-components/macro'
import BottomHeader from './BottomHeader'
import {BsInbox, BsX} from 'react-icons/bs'
import BottomContent from './BottomContent'
import { Wrapper } from './mapbottom.styled'
import FormatIndentIncreaseOutlinedIcon from '@mui/icons-material/FormatIndentIncreaseOutlined'
import useToggle from '../../features/toggle'
const BottomMap = () => {
const {open, toggle} = useToggle()


  return (
    <BottomPositionItem 
        bottom={0.2}
        right={0}
        wid='66%'
        //height='13rem'
    >
      <>

          {
            open 
            ? ( 
                <div onClick={toggle}>
                  <FormatIndentIncreaseOutlinedIcon className="toggle-icon"/>
                </div>
              )
            : (
                <Wrapper>
                  <div className="bottom-item">
                    <BottomHeader toggle={toggle}/>
                    <div className='bottom-content'>
                        <BottomContent/>
                    </div>
                  </div>
                </Wrapper>
              )
          }

      </>
    </BottomPositionItem>
  )
}

export default BottomMap