import React from 'react'
import { RiNodeTree } from 'react-icons/ri'
import { BiChevronDown } from 'react-icons/bi'
import { HiDotsVertical } from 'react-icons/hi'

import PopUp from '../../popItems/PopUp'
import { popItems } from '../AccordionItem'
import { GenAndCombineProp } from '../groupProps.typed'

const General = ({handleCheckAll, toggleItem, setPop, pop,getItemId, getPopIndex, idx, groupName}: GenAndCombineProp) => {
  return (
    <div className="accordion__title" onClick={()=> getItemId(idx)}>
        <div className='accordion__header'>
            <input 
                type='checkbox' 
                name='general' 
                id='1'
                className='accordion__header__content' 
                onChange={handleCheckAll}
            />
            <div className="accordion__header__item">
                <span className='accordion__header__content text-2'>
                    <RiNodeTree/>
                </span>
                <span className='accordion__header__content text-2' onClick={toggleItem}>
                   {groupName}
                </span>
                <span className="span_icon">
                    <BiChevronDown/>
                </span>
                <div className='depth__content' onClick={()=> getPopIndex(idx)} onMouseLeave={()=>setPop(undefined)}>
                    {
                        pop === idx ?  <PopUp items={popItems} removePop={()=>setPop(undefined)} pop={pop}/> : <HiDotsVertical className='main-icon'/>
                    }
                </div>
            </div>
        </div>
    </div> 
  )
}

export default General