import { useQuery} from "react-query"
import { getToken } from "../../hooks/getToken"
import { client } from "../../utils/axios-instance"

export default async function fetchDevice(){
    let token = getToken()
    let request = client(token)
    const res = await request.get(process.env.REACT_APP_ENTERPRISE_URL + "tracking/devices")
    return res;
}

export  async function fetchGroup(){
    let token = getToken()
    let request = client(token)
    const res = await request.get(process.env.REACT_APP_MONITOR_URL + "groups")
    return res;
}



export const useData = ()=>{
    return useQuery("devices", fetchDevice,{
        staleTime: Infinity
    })
}

export const useGroup = ()=>{
    return useQuery("groups", fetchGroup,{
        staleTime: 0
    })
}
