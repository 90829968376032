import React from 'react'
import { TD, TR } from '../tableStyled'
import { HiDotsVertical } from 'react-icons/hi'
import { FiDownload } from 'react-icons/fi'
import { RiDeleteBin6Line } from 'react-icons/ri'

export type BodyItems = {
    title?:string,
    name?:string,
    type?: string,
    format?: string,
    device?: string,
    geofence?:string,
    size?:string,
    email?:string,
    send?:string,
    schedule?:string,
    reportType:string
}
const Body = ({
    title,
    name,
    type,
    format,
    device,
    geofence,
    size,
    email,
    send,
    schedule,
    reportType
}: BodyItems) => {
  return (
   
    <TR bg key={title} >
        {
            reportType ==="schedule" && 
            <TD>
                <div className="table__body">
                    <input type="checkbox" />
                </div>
            </TD>
        }
        <TD>
            <div  className="table__body geo">
                <span>{name || title}</span>
            </div>
        </TD>
        <TD>
            <div  className="table__body">
               {type}
            </div>
        </TD>
        <TD>
            <div  className="table__body">
               {format}
            </div>
        </TD>
        <TD>
            <div  className="table__body">
              {device || size}
            </div>
        </TD>
        <TD>
            <div  className="table__body">
              {email || geofence}
            </div>
        </TD>
        <TD>
            <div  className="table__body icons">
                Yes
                <div className='body_icons'>
                    <span className="body_icn">
                        <FiDownload/>
                    </span>
                   
                    <span className="body_del">
                        <RiDeleteBin6Line />
                    </span>
                </div>
            </div>
        </TD>
    </TR>
  )
}

export default Body