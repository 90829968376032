import React, {useState} from 'react'
import DeviceLabel from '../../components/devicesearch-dashboard/DeviceLabel'
import { DeviceSearch } from '../dashboard/dashboard.styled'
import { CanvasContainer } from './canvas.styled'
import { HiOutlineArrowLeft } from 'react-icons/hi'
import Table from '../../components/table/canvas-table/Table'
import { useNavigate } from 'react-router-dom'

const tableData = [
    {
        rowNumber: 1,
        deviceName: "Cold Room",
        deviceNo: 50,
        modified: "20/07/2022 00:37am"
    },
    {
        rowNumber: 2,
        deviceName: "Cold Room",
        deviceNo: 50,
        modified: "20/07/2022 00:37am"
    },
    {
        rowNumber: 3,
        deviceName: "Cold Room",
        deviceNo: 50,
        modified: "20/07/2022 00:37am"
    },
    {
        rowNumber: 4,
        deviceName: "Cold Room",
        deviceNo: 50,
        modified: "20/07/2022 00:37am"
    },
]
const AllCanvas = () => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const toggleOpen = ()=>{
        setOpen(prev=> !prev)
    }
  return (
    <CanvasContainer>
        <div className="header__title">
            2D Canvas
           <div className="back__summary" onClick={()=> navigate(-1)}>
            <HiOutlineArrowLeft/>
           </div>
        </div>
        <DeviceSearch onClick={()=> navigate('/dashboard/upload-canvas')}>
            <DeviceLabel title="Upload 2D canvas" icon/>
        </DeviceSearch>
        <Table posts={tableData} loading={loading}/>
    </CanvasContainer>
  )
}

export default AllCanvas