import React, { useEffect, useState } from 'react'
import { getToken } from '../../hooks/getToken'
import { client } from '../../utils/axios-instance'

type DashboardSummary = {
    [x:string]:number
}
const useDashboard = () => {
    
    const [dashboardDetails,   setDashboard]= useState<DashboardSummary>({})
    useEffect(()=>{
        const fetchDeviceSummary = async ()=>{
            const token = getToken()
            const request = client(token)
            try {
                await request.get(process.env.REACT_APP_ENTERPRISE_URL+"dashboard").then(res=>{
                    setDashboard(res.data?.data);
                }).catch(err=>{
    
                })
            } catch (error) {
                console.log(error)
            } 
            
        }

        fetchDeviceSummary();

    },[]);


    return  {dashboardDetails}
}

export default useDashboard