import styled from 'styled-components/macro'
import { Form } from '../login/loginStyled'

export const ResetForm = styled(Form)`
    label {
        width: 245px;
        height: 25px;
        line-height: 18.75px;
        font-weight: 300;
        letter-spacing: 0.2px;
    }
    .input{
        display: flex;
        flex-direction: column;
    }

    .input .err{
        font-size: .80rem;
        color: #e45454;
   }
`
export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 5px;
    background: #fff;
    //overflow: hidden;
`
export const Right = styled.div`
    /* width: 593px; */
`
export const Image = styled.div`
    width: calc(34.375rem /var(--size-divisor));
    height: calc(28.125rem/ var(--size-divisor));
    img{
        max-width: 100%;
        height: 100%;
        object-fit: cover;
    }
`
export const Left = styled.div`
    //padding-top: 1.5rem;
    /* padding-inline: 10px; */
    @media (min-width: 768px){
        padding: 1.2rem;
    }
`
export const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width:100%;

    .button{
        width: calc(160px / var(--size-divisor));
    }

    .button.cancel{
        border: 1px solid #e4e4e4;
    }
` 
/*************************************NEWFORM PASSWORD RESET STYLED***********************************/
//Due to consistency the newpassword form style is done here
export const NewPassForm = styled(Form)`
    label {
        width: 100%;
        line-height: 18.75px;
        font-weight: 300;
        letter-spacing: 0.2px;
    }

    .input{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
   .new-form{
       display: grid;
       place-items: center;
       width: calc(30.0625rem / var(--size-divisor));
       height: calc(3rem / var(--size-divisor));
       padding: 15px, 60px;
       border-radius: 8px;
       border: none;
       font-size: 1rem;
       color: var(--primary-bg);
       background: var(--item-bg);
       cursor: pointer;
   }

`
