import Card from '../../components/card/Card'
import Layout from '../../components/layout/Layout'
import  Header from '../../components/header/Header'
import {MainSection, Left, Right } from '../login/loginStyled'
import useLang from '../../language/useLang'
import { useAppSelector } from '../../reducers/disPatchSelector'
import { Navigate } from 'react-router-dom'
import LeftItem from '../login/LeftItem'
import SignUpForm from './SignUpForm'


const SignUp = () => {

    const {t, handleLanguage} = useLang();

  return (
    <Layout>
       <Header handleLanguage={handleLanguage}/>
       <MainSection>
         <Left>
          <LeftItem/>
         </Left>
         <Right>
          <Card hg={35.8}>
            <div  className='image'>
              <img 
                src={process.env.PUBLIC_URL + "/assets/images/gentro.png"} 
                alt="gentro Logo"
              />
            </div>
            <SignUpForm t={t}/>
          </Card>
         </Right>
       </MainSection>
    </Layout>
  )
}

export default SignUp