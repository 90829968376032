import {createSlice, PayloadAction} from '@reduxjs/toolkit'

type LoginModel = {
    currentUser: any,
    isFetching: boolean,
    isAuth: boolean,
    error: boolean
}

const initialUserState:LoginModel = {
    currentUser: {},
    isFetching: false,
    isAuth: false,
    error: false
}

const userSlice = createSlice({
    name: "user",
    initialState: initialUserState,
    reducers: {
        loginStart: (state)=>{
            state.isFetching = true;
        },

        loginSuccess: (state, action:PayloadAction<Omit<LoginModel, "isFetching"|"isAuth"|"error">>)=>{
            state.isFetching = false;
            state.isAuth = true;
            state.currentUser = action.payload;
            state.error = false;
        },

        loginFailure: (state)=>{
            state.isFetching = false;
            state.error = true;
        },

        logOutStart: (state)=>{
            state.isFetching = true;
        },
        logOutSuccess: (state)=>{
            state.isFetching = false
            state.currentUser = {}
            state.isAuth = false
            state.error = false
        },

        logOutFailure: (state)=>{
            state.isFetching = false
            state.error = true
        }
    }
})

export const {
    loginStart, 
    loginFailure, 
    loginSuccess,
    logOutStart,
    logOutFailure,
    logOutSuccess
} = userSlice.actions;
export default userSlice.reducer;

