type ButtonProps = {
  style?: React.CSSProperties,
  onClick? : (()=> void ) | ((e: React.FormEvent)=> void),
  className: string,
  disabled?: boolean
  children: string | React.ReactNode
}

const Button: React.FC<ButtonProps> = ({style, className,onClick, children,  ...props}) => {
  return <button style={style} className={className} onClick={onClick}  {...props}>{children}</button>
}

export default Button