import React from 'react'
import { SuccessContainer } from '../delete/delete.styled'
import ProgressiveBar from '../loaders/ProgressBar'
import { ProgressLoader } from '../loaders/PuffLoader'

type SuccessProp = {
    state?: string
    load?: string,
    failed?: string,
    success?: string
}

const Success = ({state, load, failed, success}: SuccessProp) => {
  return (
    <SuccessContainer>
        <img className='delete__img' src={process.env.PUBLIC_URL+"/assets/images/success.png"} alt="delete"/>
        <div className='delete__bottom'>
            <p className='delete_desc'>
              {
                state === "loading" && load
              }
              { 
                state === "failed" && failed
              }
              { 
                state === "success" && success
              }
               
            </p>
            <div className="delete__buttons">
              {
                state === "loading" && <ProgressLoader/>
              }
            </div>
        </div>
    </SuccessContainer>
  )
}

export default Success