import {useContext, useState, useEffect} from 'react'
import { client } from '../../../utils/axios-instance'
import { POIFormCtx, pois, useNewGeoUser } from './useGeofenceContext'

const usePOIForm = () => {
 const {setShowItem} = useNewGeoUser()
 const  {poiData, setPoiData} = useContext(POIFormCtx)
 const  [isSubmitting, setIsSubmitting] = useState(false)
 const [poiState, setPoiState] = useState("idle")
    const isEmpty = async ()=>{
        if(typeof poiData !== "object"){
        return false;
        }

        let check = Object.values(poiData).every(val => val === "" || val === 0 );
        console.log('validate', check)
        return check;
    }

    const handleSubmit = async (e: React.FormEvent)=>{
        e.preventDefault();
        let notEmpty = await isEmpty();
        console.log(notEmpty);
        if(!notEmpty){  
            setIsSubmitting(true);
            setPoiState("loading")
            console.log("submitting")
        //setState("loading")
        }else{
        setIsSubmitting(false);
        console.log("not submitting")
        //setState("failed")
        //toggleModel();
        }
        
    }

    useEffect(()=>{
        const submitData = async ()=>{
            let token = JSON.parse(localStorage.getItem("access_token")!)
            let request = client(token)

            console.log('mutda', poiData)

            try{
                console.log("inside request")
                console.log("inside try", poiData)
                const res = await request.post(process.env.REACT_APP_MONITOR_URL + "poi", poiData)
                console.log(res.data)
                console.log("data", res.data.message)
                setPoiData(pois)
                setPoiState("success")
                setShowItem(prev=>{
                    return {
                        ...prev,
                        comp: "SHOW_POI"
                    }
                })
            
                if(res.data !== null) {
                    console.log(res.data);
                    //setMsg(res.data.message)
                    //setState("success")
                    //toggleModel()
                    // setTimeout(()=>{
                    //   navigate(-1);
                    // }, 3000)
                }
            }catch(err){
                console.log(err);
                // setState("failed");
                // toggleModel()
            }
        }
        if(isSubmitting){
        setIsSubmitting(false)
        setTimeout(()=>{
            submitData()
        }, 2000)
            
        }else{
            setIsSubmitting(false)
        }
            
    },[isSubmitting, poiData, setPoiData, setShowItem]);


    return {handleSubmit, poiData, setPoiData, poiState}


}

export default usePOIForm