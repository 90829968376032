import { DividerContainer, Info, Error, Line } from './divider.styled'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

type DividerProps = {
    title: string,
    icon?: boolean,
    width?: number,
    size?: string,
    mt?: number
}
const Divider = ({title, icon, width, size, mt}:DividerProps) =>{
    return (
        <DividerContainer mt={mt}>
            <Info>{title}</Info>
            <Error icon={icon}>
                <ErrorOutlineIcon className={`err ${size}`}/>
            </Error>
            <Line width={width}>
                <hr/>
            </Line>
        </DividerContainer>
    )
}

export default Divider