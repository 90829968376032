import React from 'react'
import Button from '../../button/Button'
import Divider from '../../divider/Divider'
import CustomDrag from '../../dragDropFile/CustomDrag'
import TextInput from '../../floatInput/FloatInput'
const BusinessProfile = () => {
  return (
    <>
    {/***********************************Persona Details************************************/}
        <Divider title="Business Logo" width={51}/>
        <div className="form-content signature">
         {/* <div className="upload__tab">
            <span className="tab_item">Draw</span>
            <span className="tab_item active">Upload</span>
         </div> */}
         <CustomDrag />
        </div>
       
    {/***********************************Business Details************************************/}
        <Divider title="Business Details" width={50}/>
        <div className="form-content">
            <div className="form-item">
                <TextInput label="John Dusting"/>
            </div>
            <div className="form-item">
                <TextInput label="johndusting1213@gmail.com" read={true}/>
            </div>
            <div className="form-item">
                <TextInput label="+2348023458967"/>
            </div>
            <div className="form-item">
                <TextInput label="23, Ashy Road, Lagos"/>
            </div>
        </div>

        <div className="user__btn">
            <Button className="user_cancel">Cancel</Button>
            <Button className="user_save">Save</Button>
        </div>
    </>
   
  )
}

export default BusinessProfile