import React, {useState} from 'react'
import { EditDeviceType } from '../modalForm/editDevice/EditDevice';
import { GroupData } from '../modalForm/newGroup/data.typed';
import { MultipleSelectProps } from '../multiselect/multiTyped';
import { SelectProvider } from '../multiselect/useSelectContext';
import { DevicesData } from '../selectRef/hook/useGeofenceContext';
import MultiGroupDevice from './MultiGroupDevice';


const MultiGroupDevicePage = ({options, setOption, label}: MultipleSelectProps<GroupData>) => {
    
    return (
        <SelectProvider>
            <MultiGroupDevice options={options} setOption={setOption} label={label}/>
        </SelectProvider>
    )
}



export default MultiGroupDevicePage