import React,{useState, useEffect} from 'react'
import { client } from '../utils/axios-instance';
import { getToken } from './getToken';
import { CallData } from './useRequestModel';


const useValidateField = (endpoint: string, id: string,  eType:string) => {

    const [val, setVal] = useState<any>();
   
    const [simState, setSimState] = useState("idle");
    useEffect(() => {
        let  timerId: ReturnType<typeof setTimeout>
        let token = getToken()
        let request = client(token)
      const fetchData = async ()=>{
        setSimState("loading")
        try{
          const res = await request.get(process.env.REACT_APP_ENTERPRISE_URL + endpoint + "/" + id)
          console.log("inside model")
          setVal(res?.data);
          console.log("imei/sim", res.data)
          setSimState("success");
         
        }catch(e: any){
          console.error(e.message)
          setSimState("failed");
        }
       
      }
  
      if(eType ==="sim" && (id.length > 17) && (id.length < 22)){
        timerId = setTimeout(() => {
            fetchData();
         // make a request after 1 second since there's no typing 
        }, 1000);
      }

      if(eType ==="imei" && id.length > 2){
        timerId = setTimeout(() => {
            fetchData();
         // make a request after 1 second since there's no typing 
        }, 1000);
      }
        
    
        return () => {
            clearTimeout(timerId);
        };
    }, [endpoint, id, eType]);

    return {val, simState}
}

export default useValidateField