import styled from "styled-components/macro";
import { DeviceContainer, DeviceSearch } from "../dashboard/dashboard.styled";
import { SearchHeader } from "../../components/sidebar/sidebar.styled";
import { Form } from "../../components/modalForm/modalForm.styled";


export const ReportContainer = styled(DeviceContainer)`

`

export const ReportSearch = styled(DeviceSearch)`

    .report_btn_items{
        display: flex;
        gap: 10px;
    }
`

export const SwitchReport = styled.div`
    display: flex;
    justify-content: space-around;
    /* padding-inline: 5rem; 
    padding-top: 2rem; */
    font-size: .95rem; //the third val Used to close the two borders for both personal & business tabs
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.2px;

    .report__section{
        width: 200px;
        display: flex;
        align-items: center;
        gap: 10px;
        background:#fff;
        /* padding: 8px 20px; */
        cursor: pointer;
    }

    .report__section:nth-child(1){
        border-radius: 5px 0px 0px 5px;
        border: 1px solid #E9EDF5;
        //border-radius: 0px 5px 5px 0px;
        //background: #CFF4D2;
    }

    .report__section:nth-child(2){
        border: 1px solid #E9EDF5;
        border-radius: 0px 5px 5px 0px;
    }

    .report__section.active{
        background: #CFF4D2;
        color: #4d9200;
    }

    .report__section span{
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
`

export const ReportItems = styled.div`
    background: #fff;
    height: 85%;
    display: flex;
    flex-direction: column;
    position: relative;
`

export const NewReportForm = styled(Form)`

    .form-item.radio-item.report{
        width: 100%;
    }

    .form-item.radio-item.schedule,
    .form-item.radio-item.time{
        padding-inline: 2.5rem;
        justify-content: flex-start;
        gap: 2rem;
        //gap:  2rem;
    }

    .form-item.radio-item.time{
        /* padding-inline: 2.5rem;
        justify-content: flex-start;
        gap: 2rem; */
        //justify-content: flex-start;
    }

    .radio-btn.tick{
        width: 45px;
        height: 45px;
        border-radius: 8px;
        background: #F3F4F6;
    }

    .form-content.btn{
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        right: 0;
        padding-bottom: 1rem;
    }

    .save-btn.download-btn{
        //padding:1rem;
        font-size:1.8rem;
        color: #4D9200;
        border-radius: 0;
        background: #fff;
        box-shadow: none;
        cursor: pointer;
    }

    .save-btn.cancel{
        background: #fff;
        border: 1px solid #B5B5B5;
        border-radius: 8px;
        color: #505050;
        box-shadow: none;
    }
`