import React from 'react'
import History from '../history/History'
import { HistoryContainer } from './sidebar.styled'

const SidebarHistory = () => {
  return (
    <HistoryContainer>
        <History/>
    </HistoryContainer>
  )
}

export default SidebarHistory