import {Container} from './card.styled'
import { NodeProp } from './props'


const Card: React.FC<NodeProp> = ({children, hg=30.75}) => {
  return (
    <Container hg={hg}>
     {children}
    </Container>
  )
}

export default Card