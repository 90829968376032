import React from 'react'

const AlertItem = () => {
  return (
    <div className="wrapper">
        <div className="alert__desc">
            <p className="device__name">Over speed Alert for pools cars @100Km/hr</p>
            <p className="device__date">Last updated 30-06-22 10:20 AM</p>
        </div>
        <span className="alert__count">9 counts</span>
    </div>
  )
}

export default AlertItem