import React from 'react'
import styled from 'styled-components/macro'
export const FailedContainer = styled.div`
  width: 95%;
  height: 95%;
  background: #FFFFFF;
  border: 2px dashed #EBEFF2;
  border-radius: 8px;
  display: grid;
  place-items: center;
  padding-top: 20px 0;
  padding-left: 20px;
  .failed__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .failed__img{
    width: 20rem;
    height: 15rem;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .failed__text{
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
    /* or 100% */
    letter-spacing: 0.2px;
    /* Primary Green */
    color: #505050;
    span{
      color: red;
      cursor: pointer;
    }
  }

`
type FailedProp = {
    desc: string,
    toggleModal: ()=> void
}
const FailedState = ({desc, toggleModal}: FailedProp) => {
  return (
    <FailedContainer>
        <div className='failed__wrapper'>
            <div className='failed__img'>
                <img src={process.env.PUBLIC_URL + "/assets/images/failed.png"} alt="error" />
            </div>
            <div className='failed__text'>
            Failed to load device, {desc}, <span onClick={toggleModal}>Try Again</span>?
            </div>
        </div>
    </FailedContainer>
  )
}

export default FailedState