import React from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Container } from './modal.styled'
import { ToggleProps } from './typeProps'
import { useNavigate } from 'react-router-dom'


//The hader servers two function
const ModalHeader = ({toggle, title, chg}:ToggleProps) => {
  
  const navigate = useNavigate()
  return (
    <Container>
        <div className='modal-title'>
          {title}
        </div>

        {/**Switch between navigation ui and close modal*/}
        {
          chg ? ( 
                    <div className='modal-close' onClick={()=> navigate(-1)}>
                      <CloseOutlinedIcon className='side-close-icon'/>
                    </div>
                )
                :
                (
                  <div className='modal-close' onClick={toggle}>
                    <CloseOutlinedIcon className='side-close-icon'/>
                  </div>
                )
                 
        }

    </Container>
  )
}

export default ModalHeader