import styled from 'styled-components/macro'

export const DeleteContainer = styled.div`
    display: grid;
    place-items: center;
    .delete__img, .success__img {
        width: 20rem;
        height: 15rem;
        object-fit: cover;
    }

    .delete__bottom{
        padding-top: 1rem;
    }

    .delete__bottom p,
    .delete__bottom .delete_desc {
        color: #505050;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02px;

    }

    .delete__bottom .delete_desc{
        color: #838191;
        text-align: center;
    }

    .delete__bottom .delete__buttons{
        padding-top: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }

    .delete__buttons button{
        width: 8rem;
        padding: 8px 12px;
        border-radius: 5px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02px;
    }

    .delete__buttons button:nth-child(1){
        border: 1px solid #B5B5B5;
        background: #fff;
        color: #505050;
    }

    .delete__buttons button:nth-child(2){
        background: #EC1C24;
        color: #fff;
    }

    .delete__buttons .delete_request{
        width: 16rem;
        padding: 8px 12px;
        border-radius: 5px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #EC1C24;
    }

    .delete_request span{
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: 0.02px;
        line-height: 20px;
        color: #fff;
    }
`

export const SuccessContainer = styled(DeleteContainer)`
    .delete__buttons{
        width: 20rem;
       
    }
`