import styled from 'styled-components/macro'


export const MultiSelectContainer = styled.div`
    /* margin:100px auto; */
    font-family: 'Roboto', sans-serif;
    position:relative;
    width: 100%;

    .selected__options{
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .selected__options span{
        font-size: .9rem;
        color: #838191;
    }

    /* &:hover{
        .c-multi-select-dropdown__options{
            display:block;
        }
    } */

    .c-multi-select-dropdown__selected{
        border:solid 1px #eee;
        border-radius: 5px;
        font-size:14px;
        padding:10px;
        display:flex;
        justify-content: space-between;
        align-items: center;
        background: #F3F4F6;

        > span {
            height:18px;
        }
    }

    .c-multi-select-dropdown__options{
        display:block;
        position: absolute;
        box-sizing: border-box;
        left:0;
        width:100%;
        list-style: none;
        padding-left:0px;
        border:solid 1px #eee;
        padding:5px 0px;
        background: #fff;
    }

    .c-multi-select-dropdown__option{
        position: relative;
        display:flex;
        align-items: center;
        padding:6px 10px;
        //cursor:pointer;

        &:hover{
            background-color: #fff;
        }
        
        &-checkbox{
            margin-right:6px;
        }
    }

    //Action page style
    .c-multi-select-dropdown__option .action__container{
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 5px;
        top: 0;
        left: 8rem;
        padding: 0 10px;
        width: 120px;
        height: 100px;
        background: #fff;
        border-radius: 5px;
        z-index: 10;
        box-shadow: 0px 0px 0px 1px rgba(152, 161, 179, 0.1), 
                    0px 15px 35px -5px rgba(17, 24, 38, 0.15), 
                    0px 5px 15px rgba(0, 0, 0, 0.08);
    }

    .action__container .action_title {
        font-size: 12px;
        color: #4D9200;
    }

    .action__container .action_items{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
       // background: red;
        width: 100px;
        gap: 5px;
    }

    .action_items .item{
        width: 100%;
        /* background: blue; */
        display: flex;
        align-items: center;
        gap: 6px;
    }

    .item select, 
    .item button{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        border: none;
        border: 1px solid #E9EDF5;
        border-radius: 3px;
        background: #FFFFFF;
    }

    .item select{
        width: 100%;
        padding: 2px;
    }

    .item button{
        padding: 3px 5px;
        width: 50px;
    }

    .item .save{
        background: #4d9200;
        color: #fff;
    }

    .item .cancel{
        background: #F3F4F6;
        color: #000;
    }


    /* .item{
        display: flex;
        gap: 5px;
        align-items: center;
    } */
 
`