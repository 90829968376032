import React, { useState, useRef,useEffect, useCallback } from 'react'
import io from 'socket.io-client'
import {useQueryClient } from 'react-query';

import { useData } from './fetchDevice';
import { useDeviceTracking, usePopModal } from '../selectRef/hook/useGeofenceContext';
import { getToken } from '../../hooks/getToken';
import { isIterable} from './getUpdateData';
import { sortData } from '../modalForm/editDevice/useMutateData';
import {mergeData} from './getUpdateData'



const useDeviceContent = () => {
    const [pop, setPop] = useState<number | undefined>(undefined)
    const [popItem, setPopItem] = useState<number | undefined>(undefined)
    const [itemId, setItemId] = useState(0)
    const {handleTrackingDevice, getTrackingPanel}  = useDeviceTracking()
    const queryClient = useQueryClient()
    //const {handlePopModal, toggleModal, show, setShow}= useModal()
    const  {modalPop, handlePopModal, toggleModal, setShow, show, setModalPop} = usePopModal()
   

    //Starting the initial state from undefined gives flexible state to the checkboxes, without useRef
    const [checkItem, setCheckItem] = useState<boolean | undefined>(undefined)
    const popRef = useRef<HTMLDivElement[]>([]); //Casting the div element as an array
    const [botSidebar, setBotSidebar] = useState("")
    const mainRef = useRef<HTMLDivElement[]>([])
    //const {getTrackingPanel, handleTrackingDevice} = useDeviceTracking()
    const {data: devices, isLoading, isError, error, isFetching} = useData()
    let allDevices = devices?.data?.data
    console.log("all devices", allDevices)

    //const Token = localStorage.getItem("access_token") || undefined
    //https://websocket.server.gentro.io
    const URL = "https://websocket.server.gentro.io"
    const socket = io(URL, {
        query: {
            token:
              `Bearer ${getToken()}`
          },
        autoConnect: false,
        reconnectionAttempts: 5
    });


    useEffect(()=>{
        const connect = async() =>{
            await  socket.connect()
        }
        connect();

    }, [socket])

    useEffect(()=>{
        //console.log("second emit")
        const serverListener = async()=>{
             await socket.emit("records", {
                data: {test: true, start:"1676969976", end: "1676969976"}
            });
        
            await socket.on("records", (res:any)=>{
                //console.log("Resposne:::", res)
            })
        
            await  socket.on("device-data", async (res:any[])=>{
                 console.log("device:::", res)
                // console.log("socket id", socket.id)

                if(res && res.length > 0){
                    await queryClient.setQueryData("devices", (oldData:any)=>{
                        const nData = mergeData(oldData?.data?.data, res, "_id")
                        return {
                            ...oldData,
                            data:{
                              data: nData?.sort(sortData)
                            } 
                        }
                    });
                }else{
                    await queryClient.setQueryData("devices", (oldData:any)=>{
                        return {
                            ...oldData,
                            data:{
                              data: oldData?.data?.data?.sort(sortData)
                            } 
                        }
                    });
                   
                }
                
            });
        }

        serverListener();
    }, [socket, queryClient])

  
    const getItemId = (id:  number)=>{
        setItemId(id)
    }
    const handleCheckAll = (e: React.ChangeEvent<HTMLInputElement>)=>{
        // setCheckAll(prev=> !prev)
        handleTrackingDevice(e)
        if(e.target.checked){
            setCheckItem(true)
        }else{
            setCheckItem(undefined)
        }
    }

    //Set background color
    const handleBgColor = (index: number, title: string)=>{
        getTrackingPanel(title)
        if(mainRef.current[index].id === title){
            setPopItem(index)
            setBotSidebar("rgba(214, 230, 213, 0.388)")
        }else{
            setBotSidebar("")
            setPopItem(undefined)
        }
    }
    const handlePop = (index: number, id: string)=>{
        //e.preventDefault();
        if(popRef.current[index].id === id) {
            setPop(index)
            setItemId(index)
        }else{
            console.log("affect")
            setPop(undefined)
        }
    }

    const removePop = ()=>{
        setPop(undefined)
    }

    const toggleModalPop = (event: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
        event.preventDefault();
        setShow((prev)=> !prev);
        
    }
   
    return { 
        toggleModal, 
        removePop, 
        handleBgColor, 
        handleCheckAll, 
        getItemId, 
        handleTrackingDevice, 
        handlePop, 
        handlePopModal, 
        toggleModalPop, 
        setShow, 
        modalPop, 
        isLoading, 
        error, 
        show, 
        popRef, 
        pop, 
        mainRef, 
        allDevices, 
        botSidebar, 
        popItem, 
        checkItem, 
        itemId
    }

}

export default useDeviceContent