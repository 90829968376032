import {Suspense} from 'react';
import {ThemeProvider} from 'styled-components'
import {gentTheme} from './stylesExtend/theme'
import GlobalStyles, {GlobalContainer} from './stylesExtend/globalStyles'
import Login from './pages/login/Login';
import ForgotPassword from './pages/forgetPassword/ForgotPassword';

import NewPassword from './pages/newPassowrd/NewPassword';
import Map from './pages/map/Map';
import { HashRouter,BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/dashboard/Dashboard';
import DashboardContents from './pages/dashboardItems/DashboardContents';
import Tools from './pages/tools/Tools';
import MapContent from './pages/mapContent/MapContent';
import Canvas2D from './pages/canvas/Canvas2D';
import Object2D from './pages/object2d/Object2D';
import Chart from './pages/chart/Chart';
import DashboardSummary from './pages/dashboard/DashboardSummary';
import Devices from './pages/dashboard/Devices';
import { GeoProvider } from './components/selectRef/hook/useGeofenceContext';
import AllCanvas from './pages/canvas/AllCanvas';
import Create2DCanvas from './pages/canvas/Create2DCanvas';
import DevicePosition from './pages/canvas/DevicePosition';
import { useAppSelector } from './reducers/disPatchSelector';
import {ReactQueryDevtools} from 'react-query/devtools'
import SignUp from './pages/sign-up/SignUp';
import EmailVerification from './pages/email-verification/EmailVerification';
import Report from './pages/Report/Report';


function App() {
  //const auth = useAppSelector(state=> state.userReducer.isAuth)
  return (
      <Suspense fallback={"Loading..."}>
         <ThemeProvider theme={gentTheme}>
            <Router>
              <>
                  <GlobalStyles/>
                  <Routes>
                    <Route path='/' element={<Navigate to='sign-in' replace/>}/>
                    <Route path='sign-in' element={<Login/>}/>
                    <Route path='sign-up' element={<SignUp/>}/>
                    <Route path='verify-user' element={<EmailVerification/>}/>
                    <Route path='forgot-password' element={<ForgotPassword/>}/>
                    <Route path='reset-password' element={<NewPassword/>}/>

                    {/* NESTED ROUTES: DASHBOARD, MAPS, TOOLS ITEMS ROUTES*/}
                    <Route path='dashboard' element={<DashboardContents/>}>
                      <Route path='/dashboard' element={<Dashboard/>}>
                        <Route path='' element={<DashboardSummary/>}/>
                        <Route path='devices' element={<Devices/>}/>
                        <Route path="canvas" element={<AllCanvas/>}/>
                        <Route path='upload-canvas' element={<Create2DCanvas/>}/>
                        <Route path='create-canvas' element={<DevicePosition/>}/>
                        <Route path='reports' element={<Report/>}/>
                      </Route>
                      {/* <Route path='tools' element={<Tools/>}/> */}

                      {/*NESTED ROUTES: MAP, CANVAS, 2D OBJECT, CHART ROUTES*/}
                      <Route path='map-items' element={<Map/>}>
                        <Route path='map' element={<MapContent/>}/>
                        <Route path='canvas2d' element={<Canvas2D/>}/>
                        <Route path='object2d' element={<Object2D/>}/>
                        <Route path='chart' element={<Chart/>}/>
                      </Route>
                    </Route>
                  </Routes>
                  <ReactQueryDevtools initialIsOpen={false} position="bottom-right"/>
              </>
            </Router>
          </ThemeProvider>
      </Suspense>
  );
}

export default App;
//import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined'; used in task compeleted

//To draw shaepes on the map use react-leaft-draw
//To print react-leaflet-print
