import styled from 'styled-components/macro'
type StyledProp = {
    hg?: number
}
export const Container = styled.div<StyledProp>`
 display: grid;
 padding: 20px;
 position: relative;
 background: var(--primary-bg);
 box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08), 
             0px 15px 35px -5px rgba(17, 24, 38, 0.15), 
             0px 0px 0px 1px rgba(152, 161, 178, 0.1);
 border-radius: 8px;
 width: 37.1rem;
 height: ${({hg})=> hg}rem;

 .image{
     width: 95px;
     height: 25px;
     /* margin: 50px 250px; */
     position: absolute;
     top: 50px;
     left: 250px;
 }

 img{
     width: 100%;
     height: 100%;
     object-fit: contain;
 }
 /* @media screen and (min-width: 768px){
     width: 23rem;
     height: 15rem;
 } */
 @media screen and (max-width: 990px){
     width: 30.0625rem;
     height: 24.5rem;
 }
 
`