import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CalendarViewMonthOutlinedIcon from '@mui/icons-material/CalendarViewMonthOutlined'
import {TbUpload} from 'react-icons/tb'
import SearchInput from './SearchInput';
import NewLabelBotton from './NewLabelBotton';

export type Toggle = {
    toggleOpen?: React.ReactEventHandler,
    icon?: boolean
    title?: string
}
const DeviceLabel = ({toggleOpen, icon, title}: Toggle) => {
  return (
   <>
       <SearchInput/>
       <NewLabelBotton toggleOpen={toggleOpen} title={title} icon={icon} />
   </>
  )
}

export default DeviceLabel