import React,{useState} from 'react'
import { ReportContainer, ReportItems, ReportSearch, SwitchReport } from './report.styled'
import { HiOutlineArrowLeft } from 'react-icons/hi'
import { DeviceSearch } from '../dashboard/dashboard.styled'
import { useNavigate } from 'react-router-dom'
import DeviceLabel from '../../components/devicesearch-dashboard/DeviceLabel'
import SearchInput from '../../components/devicesearch-dashboard/SearchInput'
import NewLabelBotton from '../../components/devicesearch-dashboard/NewLabelBotton'
import { RiShieldCheckLine, RiUserSettingsLine } from 'react-icons/ri'
import { BiBriefcaseAlt } from 'react-icons/bi'
import useToggleClass from '../../features/changeclass'
import ModalHeader from '../../components/modal/ModalHeader'
import NewModalHeader from '../../components/modal/NewModalHeader'
import Divider from '../../components/divider/Divider'
import ReportTable from '../../components/table/report-table/ReportTable'
import { BsCalendar2Check } from 'react-icons/bs'
import Modal from '../../components/modal/Modal'
import NewReport from './NewReport'

const Report = () => {
    const  navigate = useNavigate();
    const [report, setReport] = useState("saved")
    const [open,   setOpen] =  useState(false)
    const {toggleState, changeClass} = useToggleClass("active", "user__profile");
    const handleToggle=(e: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
        e.preventDefault();
        setOpen(!open)
    }
    const handleTabs = (val: string, num: number)=>{
        setReport(val);
        toggleState(num);
    }
  return (
    <>
        <ReportContainer>
        <div className="header__title">
                Report
            <div className="back__summary" onClick={()=> navigate(-1)}>
                <HiOutlineArrowLeft/>
            </div>
            </div>
            <ReportSearch>
            <SearchInput/>
                <div className='report_btn_items'>
                    <NewLabelBotton title='Create new report' toggleOpen={handleToggle}/>

                    <SwitchReport>
                        <div className={`report__section ${changeClass(0)}`} onClick={()=>handleTabs("saved", 0)}>
                            <span className="user_item">
                                <RiShieldCheckLine className='user_icon'/>
                            </span>
                            <span className="user_item">
                                Saved Reports
                            </span>
                        </div>
                        <div className={`report__section ${changeClass(1)}`} onClick={()=>handleTabs("schedule", 1)}>
                            <span className="user_item">
                                <BsCalendar2Check className='user_icon'/>
                            </span>
                            <span className="user_item">
                                Schedule Report Log
                            </span>
                        </div>
                    </SwitchReport>
                </div>
            </ReportSearch>

            <ReportItems>
                <NewModalHeader title={report + " Report"}/>
                <Divider title='All Reports' width={64} mt={1}/>
                <ReportTable loading={false} posts={[]} reportType={report}/>
            </ReportItems>
        </ReportContainer>

        <Modal
            open={open}
            setOpen={setOpen}
            toggle={handleToggle} 
            title="New  Report"
            hideScroll='false'
        >
            <NewReport/>
        </Modal>
    </>
   
  )
}

export default Report