import React,{useState} from 'react'
import {MdOutlineThermostat} from 'react-icons/md'
import DeviceInfo from '../../components/tooltips/DeviceInfo'
import Tooltip from '../../components/tooltips/Tooltip'
import Draggable, { DraggableEventHandler } from 'react-draggable'

type DraggableProps = {
  temp: string
}

/**
 * It's a function that returns a Tooltip component that contains a div with two spans.
 * @returns A React component
 */
const DraggableIcon = ({temp}:DraggableProps) => {
 
  return (
   
    <Tooltip content="Drag Icon to position" direction="top" delay={10}>
        <div className="draggable__icon">
            <span className="device__value">
             {temp}
            </span>
            <span className="span__icon">
              <MdOutlineThermostat/>
            </span>
        </div>
    </Tooltip>
   
    
  )
}

export default DraggableIcon