import React,{SetStateAction, useState, useEffect, useRef} from 'react'
import Delete from '../delete/Delete'
import Modal from '../modal/Modal'
import NewDevice from '../modalForm/newDevice/NewDevice'
import NewGroup from '../modalForm/newGroup/NewGroup'
import { usePopModal } from '../selectRef/hook/useGeofenceContext'
import useModal from '../selectRef/hook/useModal'
import { PopContainer } from './popUp.styled'

type PopProps = {
    items?: {
        desc: string, 
        col: string
    }[],
    removePop?: React.Dispatch<SetStateAction<boolean>>,
    setPop?: ()=> void
    pop?:  number
}

const PopUp = ({items, removePop, pop, setPop}: PopProps) => {
   // const {show, setShow, toggleModal, handlePop}= useModal()
   const {setModalPop, setShow, modalPop, toggleModal, handlePopModal, show} = usePopModal()
   //const [modal, setModalPop] = useState("")
   //console.log("modall", modal)
   
    const handleModalPop = (str: string)=>{
        setModalPop(str);
        setShow(true);
       
    }

  const initialRender = useRef(0);
//   useEffect(()=>{
//     if()
//   }, [])
  
  return (
    <>

    <PopContainer>
        <ul className='pop__items'    onMouseLeave={()=> setPop && setPop()}>
            {
                items?.map((item, index)=> 
                    <li 
                        key={index}
                        className='item' 
                        style={{color: `${item.col}`}}
                        onClick={()=>handleModalPop(item.desc)}
                    >
                        {item.desc.split(" ")[0]}
                    </li>
                )
            }
        </ul>
        {/*****************All pop modal components*******************/}

       
        {/* {
            modal === "Edit group" && ( <Modal 
                                        open={show} 
                                        toggle={toggleModal} 
                                        setOpen={setShow} 
                                        title="Edit Group" 
                                        hideScroll='false'
                                    >
                                       <NewGroup divider='Group Details' dividerWidth={50} toggleModal={handlePop}/>
                                    </Modal>
                                )
        }
        {
            modal === "Remove group" && ( <Modal 
                                        open={show} 
                                        toggle={toggleModal} 
                                        setOpen={setShow} 
                                        title="Delete Alert" 
                                        hideScroll='false'
                                    >
                                        <h1>Remove</h1>
                                    </Modal>
                                )
        }
        {
            modal === "Delete group" && ( <Modal 
                                        open={show} 
                                        toggle={toggleModal} 
                                        setOpen={setShow} 
                                        title="Delete Alert" 
                                        hideScroll='true'
                                    >
                                        <Delete/>
                                    </Modal>
                                )
        } */}
    </PopContainer>
                {/* <Modal 
                open={show} 
                toggle={toggleModal} 
                setOpen={setShow} 
                title="Edit Device" 
                hideScroll='false'
                >
                    {
                        modalPop === "Delete device" &&  <Delete/>
                    }
                    {
                        modalPop ==="Edit device" && <NewDevice handleToggle={handlePopModal}/>
                    }
              
                </Modal>
    */}

    </>
  )
}

export default PopUp