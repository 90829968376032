import React, { useState } from 'react'
import Divider from '../../divider/Divider'
import { HiOutlineArrowLeft } from 'react-icons/hi'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import PopUp from '../../popItems/PopUp'
import GeofenceDetails from './GeofenceDetails'
import SidebarSearch from '../../sidebar/SidebarSearch'
//import { useGeoUser } from '../../selectRef/hook/useGeofenceContext'
import { GeofenceWrapper } from '../tab.styled'
import { useNewGeoUser } from '../../selectRef/hook/useGeofenceContext'

const data = [
    {
        id: 1,
        desc: 'Ikeja cold room 1'
    },
    {
        id: 2,
        desc: 'Apapa cold room 1'
    },
    {
        id: 3,
        desc: 'New Toyotal Truck'
    },
]
const GeofenceItem = () => {
    const [show, setShow] = useState(false)
    const {setShowItem} = useNewGeoUser()
    //const {dispatch, ...state} = useGeoUser()
  return (
    <GeofenceWrapper>
        <div className="all__geo">
            <SidebarSearch 
                btnTitle='Add Geofence' 
                openModal={setShow} 
                icon 
                btnShow
                topRightItem='geoForm'
            />
            <div className="geofence__header">
                <Divider title="All Geofence" width={11.3}/>
                <span className="back_btn"  onClick={()=>setShowItem({showItem: false, comp:""})}>
                    <HiOutlineArrowLeft className="back__arrow"/>
                </span>
              
            </div>
            <div className="geo__items">
                {
                    data.map(p=>  <GeofenceDetails show={show} item={p.desc} key={p.id}/>)
                }
            </div>
        </div>
    </GeofenceWrapper>
    
  )
}

export default GeofenceItem