import { Outlet } from 'react-router-dom'
import { DashboardContainer, DashboardWrapper } from './dashboard.styled'
import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

// console.log(window.s.on("data-device"))
// window.s.on("device-data", (res:any)=>{
//   console.log("dev", res)
// })



const Dashboard = () => {
 
 
  return (
   <DashboardContainer>
    <DashboardWrapper>
      {/* {
        item !== "" ?  <Devices item={item}/> :  <DashboardCards setItem={setItem}/>
      } */}
      <Outlet/>
    </DashboardWrapper>
   </DashboardContainer>
  )
}

export default Dashboard