import React,{useState, useRef, useEffect} from 'react'
import { LeftPositionItem } from '../header/headerStyled'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FlipIcon from '@mui/icons-material/Flip';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import {AiOutlineDrag, AiOutlineLine} from 'react-icons/ai'
import {BsStoplightsFill} from 'react-icons/bs'
import {BiNavigation} from 'react-icons/bi'
import {FaStreetView} from 'react-icons/fa'
import { LeftItemWrapper } from './styled';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../reducers/store';
import { useAppSelector } from '../../reducers/disPatchSelector';
import { handleActiveItem } from '../../reducers/slices/mapSlice';
import { usePoiDisplay, useDeviceTracking } from '../selectRef/hook/useGeofenceContext';
import { useData } from '../device/fetchDevice';


type ZoomProps = {
    zoomIn?: ()=> void,
    zoomOut?: ()=> void
}

const icons = [
    {
        id: "AiOutlineDrag",
        icon: <AiOutlineDrag className='location'/> 
    },
    {
        id: "MapOutlinedIcon",
        icon: <MapOutlinedIcon className='location'/>
    },
    {
        id:"LocationOnOutlinedIcon",
        icon: <LocationOnOutlinedIcon className='location'/>
    },
    {
        id:"AddRoadIcon",
        icon: <AddRoadIcon className='location'/>
    },
    {
        id:"BiNavigation",
        icon: <BiNavigation className='location'/>
    },
    {
        id:"FaStreetView",
        icon: <FaStreetView className='location'/>
    },
    {
        id:"BsStoplightsFill",
        icon: <BsStoplightsFill className='location'/>
    }

]
const LeftItem = ({zoomIn, zoomOut}: ZoomProps) => {
   // const [clicked, setClicked] = useState<number[] | []>([])
    const itemRef = useRef<HTMLDivElement[]>([])
    const dispatch = useDispatch<AppDispatch>()
    const isClicked = useAppSelector(state=> state?.mapReducer.isActive)
    const {handlePoiDisplay, poi} = usePoiDisplay()
    const  { setActiveDevice } = useDeviceTracking()
    const {data:devices} = useData()
    let allDevices = devices?.data?.data

    const handleClickItem = (id: string, index: number)=>{
        let classLists = Array.from(itemRef.current[index].classList);
        console.log(classLists)
        if(classLists.includes("activated")){
            itemRef.current[index].setAttribute("class", "left-item")
           dispatch(handleActiveItem(index))
        }else{
            itemRef.current[index].setAttribute("class", "left-item activated")
            dispatch(handleActiveItem(index))
            console.log(id)
        }

        //Toggle Point of interest
        if(itemRef.current[index].id === "LocationOnOutlinedIcon"){
            handlePoiDisplay(!poi)
        }

        //Toggle Point of interest
        if(itemRef.current[index].id === id){
           setActiveDevice(prev=>{
                if(classLists.includes("activated")){
                    return allDevices;
                }else{
                    return []
                }
           })
        }
    }
    console.log(isClicked)

    useEffect(()=>{
        if(isClicked){
            for(let i=0; i< isClicked.length; i++){
                itemRef.current[isClicked[i]].setAttribute("class", "left-item activated")
                console.log("atrri", isClicked[i])
            }

            if(isClicked.includes(4)){
                setActiveDevice(allDevices)
            }else{
                setActiveDevice([])
            }
        }
    }, [isClicked, setActiveDevice, allDevices])

  return (
    <LeftPositionItem
        top={11.5}
        right={0}
        wid='3%'
    >
       <LeftItemWrapper>
        {
            icons.map((d, index)=>  
                <div 
                    ref={(elem: HTMLDivElement)=> itemRef.current[index] = elem }
                    className='left-item' 
                    id={d.id}
                    key={d.id}
                    onClick={()=>handleClickItem(d.id, index)}
                >
                    <span>
                        {d.icon}
                    </span>
                </div>
            )
        }   
       </LeftItemWrapper>
    </LeftPositionItem>
  )
}

export default LeftItem