import React from 'react'
import { AiFillFileAdd } from 'react-icons/ai'
import { DragStyled } from './dragdrop.styled';
import useDragDrop from './useDragDrop';

const CustomDrag = () => {
    const {
        handleChange, 
        handleDrag, 
        handleDrop, 
        onButtonClick,
        inputRef, 
        dragActive 
    } = useDragDrop();
  return (
    <DragStyled onDragEnter={handleDrag}>
        <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
        <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
        <div className='drag__item'>
            <span className='drag__file'>
                <AiFillFileAdd className='file_icon'/>
            </span>
            <p className='drag__text'>
                Drag and drop file or 
                <button className="upload-button" onClick={onButtonClick}>Browse</button>
            </p>
            <p className='drag_support'>Support png & jpg</p>
        </div> 
        </label>
        { 
            dragActive &&   <div 
                                id="drag-file-element" 
                                onDragEnter={handleDrag} 
                                onDragLeave={handleDrag} 
                                onDragOver={handleDrag} 
                                onDrop={handleDrop}
                            >
                             
                            </div> 
        }
    </DragStyled>
  )
}

export default CustomDrag