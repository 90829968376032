import { useState, useRef, useEffect } from 'react';
import {MapContainer, TileLayer} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import './map.styled.css'
import DraggableMarker from '../../components/maps/Marker';
import Interaction from '../../components/map-interaction/Interaction';
import AuthHeader from '../../components/header/AuthHeader';
import Sidebar from '../../components/sidebar/Sidebar';
import BottomMap from '../../components/mapbottom/BottomMap';
import LeftItem from '../../components/mapleft/LeftItem';
import TopLeft from '../../components/maptop/TopLeft';
import TopRight from '../../components/maptop/TopRight';

import { Outlet, useLocation, Navigate } from 'react-router-dom';
import { useAppSelector } from '../../reducers/disPatchSelector';
import usePOIForm from '../../components/selectRef/hook/usePOIForm';
import { useDeviceTracking } from '../../components/selectRef/hook/useGeofenceContext';




// This component display different map component layers such as map, 2DCanvas, 2DObject
const Map = () => {
  const auth = useAppSelector(state=> state.userReducer.isAuth)
  let item = useLocation().pathname.split("/")[3]
  const {panel} = useDeviceTracking()
  const {poiState} = usePOIForm()
 
  console.log(item)
  if(item === undefined){
    return <Navigate replace to="/dashboard/map-items/map"/>;
  }

  if(!auth){
    return <Navigate to="/" replace/>
  }
  
  return (
    <div>
       
        <Outlet/>
       
        <Sidebar/>
        {
          panel.showPanel &&  <BottomMap/>
        }
        <LeftItem/>
        <TopLeft/>
        <TopRight/>
    </div>
  )
}


export default Map