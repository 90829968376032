import {LayoutContainer} from './layout.styled'
import { NodeProp } from '../card/props'
const Layout: React.FC<NodeProp> = ({children}) => {
  return (
    <LayoutContainer>
        {children}
    </LayoutContainer>
  )
}

export default Layout