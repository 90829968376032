import styled from 'styled-components'
import { SideWrapper } from '../sidebar/sidebar.styled'


/*******************************GEOFENCE STYLE(Inherited from sideWrapper)******************************/ 
export const GeofenceWrapper = styled(SideWrapper)`
    /* padding: 1rem 0; */
    padding-bottom: .8rem;
    height: 24rem;

    .all__geo{
        width: 100%;
    }
    .geofence__header{
        padding: 0 10px;
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .geofence__header span{
        font-size: 1.2rem;
    }

    .geofence__header .back_btn{
        background: #fff;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 30px;
        color: #505050;
        border-radius: 50%;
        box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.06), 
                    0px 5px 22px rgba(0, 0, 0, 0.04), 
                    0px 7px 8px rgba(0, 0, 0, 0.08);
    }

    .geofence__header .back__arrow{
        color:#505050;
        font-size: 1.15rem;
    }

    .geo__items{
        padding: 0 10px;
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
        max-height: 18rem;
        overflow-y: scroll;
    }

    .geo__items::-webkit-scrollbar{
        background: #fff;
        width: 8px;
    }
   
    .geo__items::-webkit-scrollbar-thumb {
        background: #E9EDF5;
        border-radius: 20px;
    }

    .geo__point{
        padding: 8px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #505050;
        border-bottom: 1px solid #d9d9d9;
    }

    .geo__point .geo_pop{
        position: relative;
    }

    .geo__point .geo_pop div{
        top: -15px;
        right: -8px;
    }

    .geo__point input{
        width: 14px;
        height: 14px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(70, 79, 96, 0.16);
        border: none;
        border-radius: 5px;
        background: #fff;
    }

    .geo__point .geo__item{
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .geo__item .geo_name{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: .87rem;
        font-weight: 500;
        font-family: 'Roboto';
        letter-spacing: 0.02em;
    }

    .geo__point .icon{
        font-size: 1.5rem;
    }

    @media only screen and (min-width: 900px) and (max-width: 1050px){
        height: 25rem;
    }
`
export const Form = styled.form`
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    //align-items: center;
    .geo__form.shape, 
    .geo__shape{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 1rem;
        font-size: 0.875rem;
        color: #838191;
    }

    .geo__shape{
        gap: 10px;
        padding-top: 0;
    }

    .geo__form textarea{
        padding: 10px;
        border: 1px solid #E9EDF5;
        border-radius: 6px;
        background: #F3F4F6;
        outline-color: #4D9200;
    }

    .geo__shape .icon{
        color: initial;
    }

    .geo__form.btn_group{
        padding-top: .9rem;
        display: flex;
        justify-content: flex-end;
        gap: 20px;
    }

    .geo__form.btn_group button{
        padding: 6px 15px;
        border: 1px solid #B5B5B5;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .geo__form.btn_group .geo_white{
        color: #505050;
        background: #fff;
    }

    .geo__form.btn_group .geo_green{
        color: #fff;
        background: #4D9200;
    }
`

export const GeofenceDetail = styled(GeofenceWrapper)`
    padding: 1rem 0;
    width: 25rem;
    overflow-y: auto;
    &::-webkit-scrollbar{
        background: #fff;
        width: 8px;
    }
   
    &::-webkit-scrollbar-thumb {
        background: #d9d9d9;
        border-radius: 20px;
    }

    /* @media only screen and(min-width: 1000px){
        height: 25rem;
    } */
`
/**************************POINT OF INTEREST STYLE(Inherited from GeoFenceWrapper)*****************/
export const POIWrapper = styled(GeofenceWrapper)``
export const POIForm = styled(Form)``
export const POIDetail = styled(GeofenceDetail)``