import React, { useState } from 'react'
import { SearchHeader } from './sidebar.styled';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Filter from './Filter';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import useToggle from '../../features/toggle';
import { useNewGeoUser } from '../selectRef/hook/useGeofenceContext';
//import { useGeoUser } from '../selectRef/hook/useGeofenceContext';


const data = [
    {
        id: 1, 
        item: 'All', 
        icon: null
    },
    {
        id: 2, 
        item: 'Status', 
        icon: <KeyboardArrowDownOutlinedIcon/>
    },
    {
        id: 3, 
        item: 'Location', 
        icon: <KeyboardArrowDownOutlinedIcon/>
    }
]
export type ModalOpen = {
    openModal: React.Dispatch<React.SetStateAction<boolean>>,
    btnTitle?: string,
    icon?: boolean
    btnShow?: boolean
    topRightItem?: string
}
const SidebarSearch = ({openModal, btnTitle, icon,  btnShow, topRightItem=""}: ModalOpen) => {
  const {open, toggle} = useToggle()
  const {setShowItem} = useNewGeoUser()
  const getOn = ()=>{
    setShowItem(prev=>{
        return {
            ...prev,
            comp: topRightItem
        }
    })
  }
  //const {dispatch} = useGeoUser()

  return (
    <SearchHeader>
        <div className='search-item-input'>
            
            <div className='search-left' onClick={toggle}>
                <span className='search-top'>
                    <FilterAltIcon className="search-icon-top"/>
                </span>
                <p>All</p>
                {open && <Filter data={data} setOpen={toggle}/>}
            </div>
            <div className='search-right'>
                <span className='search-bottom'>
                    <SearchIcon className="search-icon-bottom"/>
                </span>
                <input type='text' placeholder='Search'/>
            </div>
        </div>
        {
            icon && (
                
                   btnShow  ? (
                        <div className='search-item-btn'  onClick={getOn}>
                            {/* <AddOutlinedIcon className='search-icon'/> */}
                            <span className='search-desc'>
                                {btnTitle}
                            </span>
                        </div>
                    ): (
                        <div className='search-item-btn' onClick={()=>openModal(true)}>
                            <AddOutlinedIcon className='search-icon'/>
                            <span className='search-desc'>
                                {btnTitle}
                            </span>
                        </div>
                    )
                
            )
        }
       
    </SearchHeader>
  )
}

export default SidebarSearch