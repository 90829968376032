import React from 'react'
import {TH, THead, TR} from '../tableStyled'
import { AiFillCaretDown, AiFillCaretUp} from 'react-icons/ai'

type HeaderProps = {
  headers: string[]
  showInput?:boolean
}

const Header = ({headers, showInput}: HeaderProps) => {
  return (
    <TR>
        {
            showInput && 
            <TH>
                <div className="table__header">
                    <input type="checkbox"/>
                </div>
            </TH>
        }
        
        {
          headers.map(title=> (
            <TH key={title}>
              <div className="table__header">
                <span className="table__title">{title}</span>
              </div> 
            </TH>
          ))
        }
    </TR>
  )
}

export default Header