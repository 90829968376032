import { subtract } from "lodash";
import moment from "moment"

export const   getTime =(num:number)=>{
    let startDate, endDate, now;
    now = moment()

    console.log("nummm", num)
    console.log(now.clone().utc().subtract(1, "week").startOf("week").toJSON())
    console.log(now.clone().utc().subtract(1, "week").endOf("week").toJSON())
    console.log(now.utc().toJSON())
    console.log(now.isoWeekday())
    if(num === 1){
        startDate = now.clone().utc().toJSON();
        endDate = now.clone().utc().toJSON();

        return startDate;
    }

    if(num === -1){
        startDate =  now.clone().utc().subtract(1, "day").toJSON();
        endDate = now.clone().utc().toJSON();

    }

    if(num=== -2){
        startDate = now.clone().utc().subtract(2, "day").toJSON();
        endDate = now.clone().utc().toJSON();
    }

    if(num=== -3){
        startDate = now.clone().utc().subtract(3, "day").toJSON();
        endDate = now.clone().utc().toJSON();
    }


    if(num === 7){
        
        startDate = now.clone().utc().startOf("week").toJSON();
        endDate = now.utc().toJSON();
    } 

    if(num === -7){
        
        startDate = now.clone().utc().subtract(1, "week").startOf("week").toJSON();
        endDate = now.clone().utc().subtract(1, "week").endOf("week").toJSON();
    } 

    if(num === 29){
        
        startDate = now.clone().utc().startOf("month").toJSON();
        endDate =  endDate = now.utc().toJSON();
    } 

    if(num === -29){
        
        startDate = now.clone().utc().subtract(1, "month").startOf("month").toJSON();
        endDate = now.clone().utc().subtract(1, "month").endOf("month").toJSON();
    } 

    return {startDate, endDate}
}

const formatDate =  ()=>{
    
}

export default  moment