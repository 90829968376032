import {MdOutlineSpeed} from 'react-icons/md'
import {TbTemperature} from 'react-icons/tb'
import {GiEnergyTank} from "react-icons/gi"
import {WiHumidity} from 'react-icons/wi'
import { BiGasPump } from 'react-icons/bi'
import { GiSpeedometer } from 'react-icons/gi'



export const SENSORVALUE:any = {
    "speed": {
        unit: "Km/h",
        icon: MdOutlineSpeed
    },
    "temperature": {
        unit: "°C",
        icon:TbTemperature
    },
    "energyConsumed": {
        unit:"KWh",
        icon: GiEnergyTank
    },
    "humidity": {
        unit:"%RH",
        icon: WiHumidity
    },
    "mileage": {
        unit:"KV",
        icon: BiGasPump
    },
    "fuelLevel": {
        unit:"L",
        icon: GiSpeedometer
    }
}