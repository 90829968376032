import React, { useState} from 'react'
import {AiOutlineArrowDown} from 'react-icons/ai'
import SelectItems, { IconProp } from './SelectItems'
import { uniqueId } from '../../features/generateId'
import { SelectContainer } from './select.styled'
import { usePOIcon} from '../selectRef/hook/useGeofenceContext'


const icons = {
    icn: null
}
const Select = () => {
  const [open, setOpen] = useState(false)
  //const [icon, setIcon] = useState<IconProp>(icons);
  const {icn, setIcon}= usePOIcon()
  const IconSelect = icn?.icon
  //console.log()
  return (
    <SelectContainer>
        <div className='selected-item' onClick={()=> setOpen(prev=> !prev)}  id='2'>
            <div className='select-input'>
                Select an item
            </div>
            {
                IconSelect
                ? <div className="selected_icon"><IconSelect/></div>
                : <div className="selected_icon"><AiOutlineArrowDown/></div>
            }
           
        </div>
        {
            open && <SelectItems setIcon={setIcon} toggleSelect={setOpen}/>
        }
        
    </SelectContainer>
  )
}

export default Select