import {useState} from 'react'
import { AiOutlineArrowDown } from 'react-icons/ai'
import { usePOIcon } from '../../selectRef/hook/useGeofenceContext'
import { DeviceSelectContainer} from '../select.styled'
import DeviceIconItem from './DeviceIconItem'

const DeviceIcon = () => {
    const [open, setOpen] = useState(false)
    //const [icon, setIcon] = useState<IconProp>(icons);
    const {icn, setIcon}= usePOIcon()
    const IconSelect = icn?.icon
    //console.log()
    return (
      <DeviceSelectContainer>
          <div className='selected-item' onClick={()=> setOpen(prev=> !prev)}  id='2'>
              <div className='select-input'>
                  Icon
              </div>
              {
                  IconSelect
                  ? <div className="selected_icon"><IconSelect/></div>
                  : <div className="selected_icon"><AiOutlineArrowDown/></div>
              }
             
          </div>
          {
            open && <DeviceIconItem setIcon={setIcon} toggleSelect={setOpen}/>
          }
          
      </DeviceSelectContainer>
    )
}

export default DeviceIcon