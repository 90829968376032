import React, {useState} from 'react'
import MultiSelect from './MultiSelect'
import { MultiselectProps } from './typed';
import { SelectProvider } from './useSelectContext';


const SelectPage = ({options, setOption, label}: MultiselectProps) => {
    
    return (
        <SelectProvider>
             <MultiSelect options={options} setOption={setOption} label={label}/>
        </SelectProvider>
    )
}

export default SelectPage