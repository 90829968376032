import React, { useEffect } from 'react'
import JSXMarker from './CustomMarker'
import {MdBluetoothDrive} from 'react-icons/md'
import { useMapEvent, useMapEvents } from 'react-leaflet'
import { useDeviceTracking } from '../selectRef/hook/useGeofenceContext'
import { icons } from '../select/iconData'
//import { duration } from 'moment'

type MarkProps = {
    item: any
}
const NewDeviceMarker = ({item}:MarkProps) => {
    const pos = item?.locationGPS
    const icn = icons.find(i=> i?.title.toLowerCase() === item?.icon?.toLowerCase());
    const IconSelect = icn ? icn.icon  : MdBluetoothDrive
    const map = useMapEvents({}) 

    useEffect(()=>{
       if(pos){
            map.flyTo([pos?.lat, pos?.lon], map.getZoom(),{
                animate:true,
                duration:1
            })
       }

       return;
    }, [map, pos])

  return (
    <>
        {
            pos &&(
                <JSXMarker
                    key={item?._id}
                    position={[pos.lat, pos.lon]}
                    toolTip={false}
                    showPop={false}
                    iconOptions={{
                    className: "device__marker",
                    iconSize: [60, 60],
                    iconAnchor: [50, 50]
                    }} 
                    >  
                    <div className="pin_icon">
                        <div className="pin_inner">
                            <IconSelect/>
                        </div>
                    </div>
                </JSXMarker>
            )
        }
    </>
    
  )
}

export default NewDeviceMarker