import {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import { client } from '../utils/axios-instance';
import  { getToken } from './getToken';

type CallData = {
    data: {}[]
}
//Use the client hooks to get the initial data from the server
//Position the url according to the return data from the client
// Usage: const {firstCall, secondCall} = useClient(firstUrl, secondUrl) to get the values
const useClient = (endpoint: string) => {

  const [resp, setFirstCall] = useState<CallData>();
  const [err, setErr] = useState('')
 
  const [data, setData] = useState('loading');
  
  console.log(endpoint)
  console.log("first", resp)

   //Get the devices, and plan addons from the server
  useEffect(()=>{
    let token = getToken()
    let request = client(token)
    const fetchData = async ()=>{
      try{
        const res = await request.get(process.env.REACT_APP_ENTERPRISE_URL + endpoint)
        setFirstCall(res?.data);
        setData("success");
       
      }catch(e: any){
        setErr(e.message);
        setData("failed");
      }
     
    }

    fetchData();
  }, [endpoint]);


  return {resp,data, err}

}

export default useClient