import {  LeafletEvent } from 'leaflet';
import React, {useState, useRef, } from 'react'

import { LayerGroupProps } from 'react-leaflet';
import {FeatureGroup} from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw';
//import { useGeoUser } from '../../components/selectRef/hook/useGeofenceContext';

type LatLng = {
  lat: number,
  lng: number
}
  
type Layers = {
  label: string,
  id: number,
  latlng: LatLng | LatLng[],
  radius?: number
}

type ShapeProps = {
  shapeType: string
}
const Shape = ({shapeType}: ShapeProps) => {
    //const {...state} = useGeoUser()
    const [mapLayer, setMapLayer] = useState<Layers | {}>({});
    const featureGroupRef = useRef<any>(null);
    
    let polygon = shapeType === "polygon" ? true : false;
    let circle = shapeType === "circle" ? true: false;
    
    const handleEdit = (e:any)=>{
      console.log(e)
    }

    const handleCreate = (e:any)=>{
      const {layer, layerType} = e
      const {_leaflet_id} = layer
      if(layerType === "polygon"){
        setMapLayer(
          {
            label: layerType, 
            id: _leaflet_id, 
            latlng: layer.getLatLngs()[0]
          }
        )

      }

      if(layerType === 'circle') {
        const { lat, lng } = e.layer.getBounds().getCenter();
        setMapLayer(
          {
            label: layerType, 
            id: _leaflet_id, 
            latlng: {lat, lng}, 
            radius:e.layer.getRadius()
          }
        )
        
      }
    }

    const handleDelete = (e:any)=>{
      console.log(e)
    }

    const handleLayers = (e:any)=>{
      const layers = featureGroupRef.current._layers;
      if (Object.keys(layers).length >= 1) {
        Object.keys(layers).forEach((layerid, index) => {
            if (index > 0) return;
            const layer = layers[layerid];
            featureGroupRef.current.removeLayer(layer);
        });
        console.log(layers);
      }
     
    }

    if (mapLayer){
      return null;
    }

    console.log(mapLayer)
    return (
      <FeatureGroup ref={featureGroupRef}>
          <EditControl 
              position='topright'
              onEdited={handleEdit}
              onCreated={handleCreate}
              onDeleted={handleDelete}
              onDrawStart={handleLayers}
              draw={{
                  rectangle: false,
                  polyline:false,
                  circlemarker: false,
                  marker: false,
                  circle: circle,
                  polygon: polygon
              }}
          />
      </FeatureGroup>
        
    )
}

export default Shape