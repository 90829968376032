import styled from "styled-components/macro";

export const GridLinkContainer = styled.div`
    position: absolute;
    top: 3.75rem;
    right: 1rem;
    width: 200px;
    height: 120px;
    background: #fff;
    box-shadow: var(--shadow);
    border-radius: 6px;

    .link__wrapper{
        padding: 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        flex-basis: max-content;
        gap: 4px;
    }

    .link_item{
        width: 80px;
        height: 35px;
        display: grid;
        place-items: center;
    }

    .link_item:hover{
        background:#E9EDF5;
        cursor: pointer;
    }

    .link_item img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`