import React from 'react'
import TextInput from '../../floatInput/FloatInput'
import InputField from '../../input/input'

const SetGPS = () => {
  return (
   <div className="form-content gps">
        <div className="form-item">
            <TextInput label="APN"/>
        </div>
        <div className="form-item">
            <TextInput label="User Name"/>
        </div>
        <div className="form-item">
            <InputField 
                text='text' 
                name='password' 
                placeText='Password' 
                id='password' 
                icon
            />
        </div>
   </div>
  )
}

export default SetGPS