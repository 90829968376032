import React from 'react'

const Chart = () => {
  return (
    <div style={{textAlign: 'center', paddingTop: '5rem'}}>
        <h1>Chart</h1>
        <h1>Chart</h1>
        <h1>Chart</h1>
        <h1>Chart</h1>
        <h1>Chart</h1>
    </div>
  )
}

export default Chart