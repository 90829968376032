import React from 'react'
import { Form } from '../login/loginStyled'
import InputField from '../../components/input/input'
import { LangProps } from '../login/LoginForm'
import { Buttons, ResetForm} from './forgetpassword.styled'
import Button from '../../components/button/Button'
import useForgotPassword from './useForgotPassword'

const url = process.env.REACT_APP_ENTERPRISE_URL + "users/forgot-password"
const Reset = ({t}: LangProps) => {
   
  const {item, handleChange, handleSubmit, navigate, state} = useForgotPassword(url, "/reset-password")
  console.log("itm", item)
  return (
    <ResetForm action=''>
        <h4>{t('forget password')}</h4>
        <div className='input'>
            <label htmlFor='email'>Please enter your email address</label>
            <InputField 
              text="email" 
              placeText="Email" 
              name="email" 
              id="email"
              styles={{width: "481px", height: "56px"}}
              handleChange={handleChange}
            />
            {
              state === "failed" &&  <p className='err'>Incorrect email or email does not exist</p>
            }
        </div>
        <Buttons>
            <Button className='button cancel' style={{backgroundColor: "#fff", color: "#000"}}onClick={()=> navigate("/sign-in")}>Cancel</Button>
            <Button className='button' onClick={handleSubmit} disabled={state === "submitting"}>send</Button>
        </Buttons>
    </ResetForm>
  )
}

export default Reset