import InputField from '../../components/input/input'
import { Buttons, NewPassForm} from '../forgetPassword/forgetpassword.styled'
import Button from '../../components/button/Button'
import Spinner from '../../components/spinner/Spinner'
import useForgotPassword from '../forgetPassword/useForgotPassword'

const url = process.env.REACT_APP_ENTERPRISE_URL + "users/reset-password"
const NewForm = () => {

    const  {item, state, serverMsg, handleChange, handleSubmit} = useForgotPassword(url, "/sign-in");

    console.log(state)
    console.log("rese",item)
  return (
    <NewPassForm action=''>
        <h4>New Password</h4>
        <div className='input'>
            <label>Please enter the code and your new password</label>
            {
                state === "failed" &&  <p className="err_msg">{serverMsg}</p>
            }
           
            <InputField 
                text="number" 
                placeText="Enter OTP sent to your email" 
                name="code" 
                id="code"
                styles={{width: "481px", height: "56px"}}
                handleChange={handleChange}
            />
            <InputField 
                text="newPassword" 
                placeText="New Password" 
                name="newPassword" 
                id="newPassword"
                styles={{width: "481px", height: "56px"}}
                handleChange={handleChange}
            />
            <InputField 
                text="password" 
                placeText="Confirm Password" 
                name="confirmNewPassword" 
                id="confirmPassword"
                styles={{width: "481px", height: "56px"}}
                handleChange={handleChange}
            />
        </div>
        <Buttons>
            
            <Button className='new-form' onClick={handleSubmit} disabled={state === "submitting"}>
                {
                    state  === "submitting"  && "Redirecting to login..."
                }
                {
                    (state === "idle"||state === "failed") && "send"
                }
            </Button>
        </Buttons>
    </NewPassForm>
  )
}

export default NewForm