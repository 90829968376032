import {useState} from 'react'

import {MapContainer, TileLayer, FeatureGroup, LayersControl} from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw';
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import DraggableMarker from '../../components/maps/Marker';
import { LeafletMouseEvent } from 'leaflet';




const pos: [number, number] = [6.5244, 3.3792];

const MapContent = () => {
    const [zoomLevel, setZoomLevel] = useState<number>(13)

    
  return (
    <>
        <MapContainer center={pos} zoom={zoomLevel} scrollWheelZoom={false} zoomControl={false}>
          
          
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
             <DraggableMarker/> 
        
        </MapContainer>
    </>
  )
}

export default MapContent