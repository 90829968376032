import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

const useLang = () => {
  const {t, i18n} = useTranslation(["common"])

  const handleLanguage = (value: string)=>{
    i18n.changeLanguage(value)
  }
  useEffect(()=>{
    //! tells typescript to ignoore  the undefined given 
    if(localStorage.getItem("i18nextLng")?.length! > 2){
        i18next.changeLanguage("en")
    }
  }, [])

  return {t, handleLanguage}
}

export default useLang