import React from 'react'
import Select from 'react-select'

const SensorSelect = () => {
  return <Select 
            placeholder="Select sensors"
            styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "none",
                }),
              }}
        />
}

export default SensorSelect