import styled from 'styled-components'

export const LayoutContainer = styled.div`
  width: 100%;
  /* height: calc(100vh / var(--size-divisor)); */
  background: #E5E5E5;
  /* padding: 20px; */
  display: grid;
  //overflow: hidden;
  @media (min-width: 768px) {
    /* padding: 10px 20px; */
    margin: 0;
  }

`