import { title } from 'process';
import {useState, useCallback, useEffect} from 'react'
import { client } from '../../../utils/axios-instance';
import useMutateData from '../editDevice/useMutateData';
import { devicesData } from './deviceData';
import { DevicesData } from './deviceTyped';
import { NewDeviceProp } from './NewDevice';
import validateSetDevice from './validateSetDeviceFields';

type Submit = (data: DevicesData)=> Promise<unknown>
const useDeviceForm = <T>(data:T | any , url: string, toggleModel:()=>void, simType:string, simState:string, imeiState:string) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [fieldErr, setFieldErr] = useState({})
 
  const [state, setState] = useState("idle");

  console.log("dev", data)
  
  console.log("is submit", isSubmitting)
  const handleSubmit = (e: React.FormEvent)=>{
    e.preventDefault();                                     
    if(simType === "gentro"){
      setFieldErr(validateSetDevice(data, simState, imeiState))
    }
    setIsSubmitting(true)
  }

  const submitData = async (val: typeof data)=>{
    let token = JSON.parse(localStorage.getItem("access_token")!)
    let request = client(token)

    try{
      console.log("inside request")
      const res = await request.post(url, val)
      //console.log(res.data)
      console.log("data sumitted", res.data)
    
      if(res.data !== null) {
        console.log(res.data);
        //setMsg(res.data.message)
        setState("success")
        setTimeout(()=>{
          toggleModel()
        },1000)
       
        return res?.data
      }
    }catch(err){
      console.log("inside    server", err);
      setState("failed");
      //toggleModel()
    }
  }

  const {mutate} = useMutateData<Submit, DevicesData>(submitData)
  useEffect(()=>{
    if((Object.keys(fieldErr).length === 0) && isSubmitting){
      setIsSubmitting(false)
      setState("loading")
      setTimeout(()=>{
        mutate(data,{
          onError: (error)=>{
            console.log("insied new", error)
          }
        })
      }, 500)
        
    }else{
        console.log("err",fieldErr)
        console.log("useEffect  error")
        setIsSubmitting(false)
    }
      
  },[isSubmitting, data, mutate, fieldErr]);


  return {handleSubmit, state, isSubmitting, fieldErr}
}

export default useDeviceForm